import { Block, Flex, Grid, Modal, PrimaryButton } from 'components';
import { CheckBox, FileInput, Input } from 'components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
    createAnnouncement,
    updateAnnouncement,
    uploadAnnouncementPicture,
} from 'src/api/mutations';
import { getAnnouncement } from 'src/api/queries';
import { IFormik } from 'src/model/types/Formik';

// import * as Yup from 'yup';
import { queryClient } from '../../main';
import { StatusPanel } from './styled';

// utils
function getFormikPicturePathFromBannerType(bannerType: string): string {
    switch (bannerType) {
        case 'ruDesktop':
            return 'translations.ru.pictureWeb';
        case 'ruMobile':
            return 'translations.ru.pictureMobile';
        case 'enDesktop':
            return 'translations.en.pictureWeb';
        case 'enMobile':
            return 'translations.en.pictureMobile';
        default:
            return '';
    }
}
//

type BannerFormProps = {
    id?: string | 'new';
    onClose: () => void;
};

const BannerForm = (props: BannerFormProps) => {
    const { id: bannerId, onClose } = props;
    const isNew = bannerId === 'new';

    // banners data
    const { data: banner } = useQuery(
        ['banners', bannerId],
        () => getAnnouncement(bannerId!),
        { enabled: !!bannerId },
    );
    const bannerEnTranslation = banner?.translations.find(
        (_translation) => _translation.language === 'en',
    );
    const bannerRuTranslation = banner?.translations.find(
        (_translation) => _translation.language === 'ru',
    );
    //

    // files
    const [pictureFilesLoading, setPictureFilesLoading] = useState({
        ruDesktop: false,
        ruMobile: false,
        enDesktop: false,
        enMobile: false,
    });
    const [pictureFilesProgress, setPictureFilesProgress] = useState({
        ruDesktop: 0,
        ruMobile: 0,
        enDesktop: 0,
        enMobile: 0,
    });

    function handleFileChange(file, bannerType) {
        setPictureFilesProgress({ ...pictureFilesProgress, [bannerType]: 100 });
        setPictureFilesLoading({ ...pictureFilesLoading, [bannerType]: true });

        bannerPictureMutation.mutate(file, {
            onSuccess: (value) => {
                formik.setFieldValue(
                    getFormikPicturePathFromBannerType(bannerType),
                    value,
                );
                setPictureFilesProgress({
                    ...pictureFilesProgress,
                    [bannerType]: 0,
                });
                setPictureFilesLoading({
                    ...pictureFilesLoading,
                    [bannerType]: false,
                });
            },
        });
    }
    function handleFileCancel(bannerType) {
        setPictureFilesProgress({ ...pictureFilesProgress, [bannerType]: 0 });
        setPictureFilesLoading({ ...pictureFilesLoading, [bannerType]: false });
    }

    //

    // close form from formik
    function handleClose() {
        formik.resetForm();
        onClose();
    }
    //

    // mutations
    const updateBannerMutation = useMutation(
        (banner: any) => updateAnnouncement(banner),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('banners');
            },
        },
    );
    const createBannerMutation = useMutation(
        (banner: any) => createAnnouncement(banner),
        {
            onSuccess: () => {
                handleClose();
                formik.resetForm();
                queryClient.invalidateQueries('banners');
            },
        },
    );
    const bannerPictureMutation = useMutation((fileInputData: FileList) =>
        uploadAnnouncementPicture(fileInputData),
    );
    //

    const formik: IFormik = useFormik({
        initialValues: {
            id: banner?.id,
            status: banner?.status,
            translations: {
                en: {
                    language: 'en',
                    title: isNew ? undefined : bannerEnTranslation?.title,
                    description: isNew
                        ? undefined
                        : bannerEnTranslation?.description,
                    link: isNew ? undefined : bannerEnTranslation?.link,
                    pictureWeb: isNew
                        ? undefined
                        : bannerEnTranslation?.pictureWeb,
                    pictureMobile: isNew
                        ? undefined
                        : bannerEnTranslation?.pictureMobile,
                    number: isNew ? undefined : bannerEnTranslation?.number,
                    status: isNew ? undefined : bannerEnTranslation?.status,
                    dateStart: isNew
                        ? undefined
                        : bannerEnTranslation &&
                          bannerEnTranslation.dateStart &&
                          new Date(bannerEnTranslation.dateStart),
                    dateEnd: isNew
                        ? undefined
                        : bannerEnTranslation &&
                          bannerEnTranslation.dateEnd &&
                          new Date(bannerEnTranslation.dateEnd),
                    isActive: isNew ? false : bannerEnTranslation?.isActive,
                },
                ru: {
                    language: 'ru',
                    title: isNew ? undefined : bannerRuTranslation?.title,
                    description: isNew
                        ? undefined
                        : bannerRuTranslation?.description,
                    link: isNew ? undefined : bannerRuTranslation?.link,
                    pictureWeb: isNew
                        ? undefined
                        : bannerRuTranslation?.pictureWeb,
                    pictureMobile: isNew
                        ? undefined
                        : bannerRuTranslation?.pictureMobile,
                    number: isNew ? undefined : bannerRuTranslation?.number,
                    status: isNew ? undefined : bannerRuTranslation?.status,
                    dateStart: isNew
                        ? undefined
                        : bannerRuTranslation &&
                          bannerRuTranslation.dateStart &&
                          new Date(bannerRuTranslation.dateStart),
                    dateEnd: isNew
                        ? undefined
                        : bannerRuTranslation &&
                          bannerRuTranslation.dateEnd &&
                          new Date(bannerRuTranslation.dateEnd),
                    isActive: isNew ? false : bannerRuTranslation?.isActive,
                },
            },
        },
        enableReinitialize: true,
        validateOnMount: false,
        // validationSchema: Yup.object().shape({
        //     translations: Yup.object().shape({
        //         ru: Yup.object().shape({
        //             language: Yup.string(),
        //             title: Yup.string().required(),
        //             description: Yup.string().required(),
        //             link: Yup.string().url().required(),
        //             pictureWeb: Yup.string().required(),
        //             pictureMobile: Yup.string(),
        //             number: Yup.number().integer().required(),
        //             status: Yup.string(),
        //             dateStart: Yup.string().required(),
        //             dateEnd: Yup.string(),
        //             isActive: Yup.boolean(),
        //         }),
        //         en: Yup.object().shape({
        //             language: Yup.string(),
        //             title: Yup.string().required(),
        //             description: Yup.string().required(),
        //             link: Yup.string().url().required(),
        //             pictureWeb: Yup.string().required(),
        //             pictureMobile: Yup.string(),
        //             number: Yup.number().integer().required(),
        //             status: Yup.string(),
        //             dateStart: Yup.string().required(),
        //             dateEnd: Yup.string(),
        //             isActive: Yup.boolean(),
        //         }),
        //     }),
        // }),
        onSubmit: (data) => {
            const banner: any = {
                ...(bannerId !== 'new' && {
                    id: bannerId!,
                }),
                translations: Object.keys(data.translations).map((locale) => ({
                    ...data.translations[locale],
                })),
            };

            banner.translations = banner.translations.filter(
                (translation) =>
                    !!translation.title &&
                    !!translation.description &&
                    !!translation.link &&
                    !!translation.pictureWeb &&
                    !!translation.number &&
                    !!translation.dateStart,
            );

            if (bannerId === 'new') {
                createBannerMutation.mutate(banner);
            } else {
                updateBannerMutation.mutate(banner);
            }
        },
    });

    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        values,
        // errors,
    } = formik;

    return (
        <Modal
            width="100%"
            onClose={onClose}
            isOpened
            label={props.id === 'new' ? 'Create banner' : 'Edit banner'}
            backgroundColor="authorsSuggestionsBackgroundColor"
        >
            <form onSubmit={handleSubmit}>
                <Grid gridTemplateColumns="1fr 1fr" gridColumnGap="40px">
                    <Block label="russian">
                        <Input
                            width="100%"
                            label="Title"
                            name="translations.ru.title"
                            id="translations.ru.title"
                            placeholder="Title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.ru.title}
                            error={values?.translations.ru.title === ''}
                        />
                        <Input
                            width="100%"
                            label="Description"
                            name="translations.ru.description"
                            id="translations.ru.description"
                            placeholder="Description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.ru.description}
                            error={values?.translations.ru.description === ''}
                        />
                        <Input
                            width="100%"
                            label="Number"
                            name="translations.ru.number"
                            id="translations.ru.number"
                            placeholder="Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.ru.number}
                            error={values?.translations.ru.number === ''}
                        />
                        <FileInput
                            id="bannerDesktopRu"
                            title="Banner desktop image"
                            progress={pictureFilesProgress.ruDesktop}
                            isLoading={pictureFilesLoading.ruDesktop}
                            onChange={(file) =>
                                handleFileChange(file, 'ruDesktop')
                            }
                            onCancel={() => handleFileCancel('ruDesktop')}
                            preview={values?.translations.ru.pictureWeb}
                        />
                        <FileInput
                            id="bannerMobileRu"
                            title="Banner mobile image"
                            progress={pictureFilesProgress.ruMobile}
                            isLoading={pictureFilesLoading.ruMobile}
                            onChange={(file) =>
                                handleFileChange(file, 'ruMobile')
                            }
                            onCancel={() => handleFileCancel('ruMobile')}
                            preview={values?.translations.ru.pictureMobile}
                        />
                        <Input
                            type="datetime-local"
                            label="Start"
                            name="translations.ru.dateStart"
                            id="translations.ru.dateStart"
                            width="100%"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                                values?.translations.ru.dateStart
                                    ? dayjs(
                                          values?.translations.ru.dateStart,
                                      ).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                        />
                        <Input
                            type="datetime-local"
                            label="End"
                            name="translations.ru.dateEnd"
                            id="translations.ru.dateEnd"
                            width="100%"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                                values?.translations.ru.dateEnd
                                    ? dayjs(
                                          values?.translations.ru.dateEnd,
                                      ).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                        />
                        <Input
                            width="100%"
                            label="Link"
                            name="translations.ru.link"
                            id="translations.ru.link"
                            placeholder="https://"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.ru.link}
                            error={values?.translations.ru.link === ''}
                        />
                        <StatusPanel value={values?.translations.ru.status} />
                        <CheckBox
                            value={values?.translations.ru.isActive}
                            checked={values?.translations.ru.isActive}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="translations.ru.isActive"
                            name="translations.ru.isActive"
                            label="Show"
                        />
                    </Block>
                    <Block label="english">
                        <Input
                            width="100%"
                            label="Title"
                            name="translations.en.title"
                            id="translations.en.title"
                            placeholder="Title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.en.title}
                            error={values?.translations.en.title === ''}
                        />
                        <Input
                            width="100%"
                            label="Description"
                            name="translations.en.description"
                            id="translations.en.description"
                            placeholder="Description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.en.description}
                            error={values?.translations.en.description === ''}
                        />
                        <Input
                            width="100%"
                            label="Number"
                            name="translations.en.number"
                            id="translations.en.number"
                            placeholder="Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.en.number}
                            error={values?.translations.en.number === ''}
                        />
                        <FileInput
                            id="bannerDesktopEn"
                            title="Banner desktop image"
                            progress={pictureFilesProgress.enDesktop}
                            isLoading={pictureFilesLoading.enDesktop}
                            onChange={(file) =>
                                handleFileChange(file, 'enDesktop')
                            }
                            onCancel={() => handleFileCancel('enDesktop')}
                            preview={values?.translations.en.pictureWeb}
                        />
                        <FileInput
                            id="bannerMobileEn"
                            title="Banner mobile image"
                            progress={pictureFilesProgress.enMobile}
                            isLoading={pictureFilesLoading.enMobile}
                            onChange={(file) =>
                                handleFileChange(file, 'enMobile')
                            }
                            onCancel={() => handleFileCancel('enMobile')}
                            preview={values?.translations.en.pictureMobile}
                        />
                        <Input
                            type="datetime-local"
                            label="Start"
                            name="translations.en.dateStart"
                            id="translations.en.dateStart"
                            width="100%"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                                values?.translations.en.dateStart
                                    ? dayjs(
                                          values?.translations.en.dateStart,
                                      ).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                        />
                        <Input
                            type="datetime-local"
                            label="End"
                            name="translations.en.dateEnd"
                            id="translations.en.dateEnd"
                            width="100%"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                                values?.translations.en.dateEnd
                                    ? dayjs(
                                          values?.translations.en.dateEnd,
                                      ).format('YYYY-MM-DDTHH:mm')
                                    : ''
                            }
                        />
                        <Input
                            width="100%"
                            label="Link"
                            name="translations.en.link"
                            id="translations.en.link"
                            placeholder="https://"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.translations.en.link}
                            error={values?.translations.en.link === ''}
                        />
                        <StatusPanel value={values?.translations.en.status} />
                        <CheckBox
                            value={values?.translations.en.isActive}
                            checked={values?.translations.en.isActive}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="translations.en.isActive"
                            name="translations.en.isActive"
                            label="Show"
                        />
                    </Block>
                </Grid>
                <Flex mt="40px" mb="40px">
                    <PrimaryButton mr="40px" type="submit" disabled={!isValid}>
                        Save
                    </PrimaryButton>
                    <PrimaryButton>Delete</PrimaryButton>
                </Flex>
            </form>
        </Modal>
    );
};

export default BannerForm;
