import styled from '@emotion/styled';
import { Div, Flex, IconCheck, Text } from 'components';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

const HiddenCheckBox = styled.input({
    border: 0,
    clip: 'rect(0 0 0 0)',
    clippath: 'inset(50%)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
});

const CheckBox: React.FC<
    DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > & { label?: string }
> = ({ checked, onChange, value, label, ...rest }) => {
    return (
        <label style={{ cursor: 'pointer' }}>
            <Flex alignItems="flex-end">
                <Text variant="body" mr="10px" mt="30px">
                    {label}
                </Text>
                <Div
                    cursor="pointer"
                    display="inline-flex"
                    borderRadius="2px"
                    border={
                        checked ? 'none' : '2px solid rgba(182, 182, 182, 0.4)'
                    }
                    backgroundColor={checked ? 'solidBlue' : 'inherit'}
                    alignItems="center"
                    p={checked ? '2px 2px 4px 2px' : '6px'}
                >
                    {checked && <IconCheck />}
                </Div>
                <HiddenCheckBox
                    type="checkbox"
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    {...rest}
                />
            </Flex>
        </label>
    );
};

export default CheckBox;
