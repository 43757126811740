/* eslint-disable filenames/match-exported */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { Td, Tr } from './PlainTableComponents';

interface DragItem {
    index: number;
    id: string;
    type: string;
    isVideoArchive: boolean;
}

const DNDTableTr = ({ row, moveRow, id, index }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(row: DragItem, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = row.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current!.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY =
                clientOffset && clientOffset.y - hoverBoundingRect.top;

            if (hoverClientY) {
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }

                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }
            }
            moveRow(dragIndex, hoverIndex);
            row.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (
        <Tr
            ref={ref}
            style={{ opacity, cursor: 'move' }}
            data-handler-id={handlerId}
            borderTop="1px solid lightGrey"
            borderRadius="2px"
            {...row.getRowProps()}
        >
            {row.cells.map((cell: any, ind: number) => {
                return (
                    <Td py="12px" {...cell.getCellProps()} key={`cell${ind}`}>
                        {cell.render('Cell')}
                    </Td>
                );
            })}
        </Tr>
    );
};

export default DNDTableTr;
