import { Div, Flex, RouterLink } from 'components';
import React from 'react';
import { matchPath } from 'react-router';
import { ISideBar } from 'src/model/types/SideBar';

import Text from '../Text';
import BreadCrumbs from './BreadCrumbs';

const SideBar: React.FC<ISideBar> = ({ items, children }) => {
    const checkRoute = (link: string) => {
        return matchPath(location.pathname, link)?.isExact;
    };

    return (
        <Flex size="100%">
            <Flex
                width="160px"
                flexDirection="column"
                backgroundColor="graphit"
            >
                {items.map((item) => {
                    const isCurrentRoute = checkRoute(item.link);

                    return (
                        <RouterLink key={item.name} to={item.link}>
                            <Div
                                backgroundColor={
                                    isCurrentRoute
                                        ? 'solidBlue'
                                        : 'lightGraphit'
                                }
                                p="10px"
                                cursor="pointer"
                                borderBottom={
                                    (!isCurrentRoute && '1px solid') || ''
                                }
                                borderColor={(!isCurrentRoute && 'grey') || ''}
                            >
                                <Text variant="h4" color="white">
                                    {item.label}
                                </Text>
                            </Div>
                        </RouterLink>
                    );
                })}
            </Flex>
            <Flex flex="1" backgroundColor="lightGrey" flexDirection="column">
                <BreadCrumbs />
                <Div size="100%" overflowY="auto">
                    {children}
                </Div>
            </Flex>
        </Flex>
    );
};

export default SideBar;
