import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const ArrowDown: React.VoidFunctionComponent<CustomSvgProps> = (props) => (
    <Svg
        {...props}
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11 1L6 6L1 1" stroke="#BCC3CA" strokeLinejoin="round" />
    </Svg>
);

export default ArrowDown;
