import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { SpaceProps } from 'styled-system';

import Text from '../Text';
import PlainButton from './PlainButton';

const DefaultButton: React.FC<
    DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > &
        SpaceProps & { isBig?: boolean }
> = ({ isBig, children, ...rest }) => {
    return (
        <PlainButton
            width="fit-content"
            variant="default"
            cursor="pointer"
            p={isBig ? '17px 72px' : '9px 54px'}
            borderWidth="1px"
            borderStyle="solid"
            {...rest}
        >
            <Text variant={isBig ? 'title' : 'body'} color="solidBlue">
                {children}
            </Text>
        </PlainButton>
    );
};

export default DefaultButton;
