/* eslint-disable filenames/match-exported */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { PrimaryButton, Select } from 'components';
import update from 'immutability-helper';
import React, {
    ButtonHTMLAttributes,
    DetailedHTMLProps,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column, TableHeaderProps, usePagination, useTable } from 'react-table';

import { ICoursesOrderItem } from '../../model/types/api/Courses';
import { PlainButton } from '../Buttons';
import Div from '../Div';
import Flex from '../Flex';
import ArrowLeft from '../Icons/ArrowLeft';
import ArrowRight from '../Icons/ArrowRight';
import { Input } from '../Inputs';
import Text from '../Text';
import DNDTableTr from './DNDTableTr';
import { Table, Tbody, Th, Thead, Tr } from './PlainTableComponents';

const PageButton: React.FC<
    DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >
> = ({ children, onClick }) => (
    <PlainButton
        onClick={onClick}
        variant="default"
        p="10px 15px"
        border="1px solid"
        borderRadius="2px"
        borderColor="lightGrey"
    >
        {children}
    </PlainButton>
);

const DNDTable = ({
    columns,
    data,
    onMove,
    success,
    error,
    setPage,
    setSize,
    controlledPageCount,
}: {
    columns: Column[];
    data: any[];
    onMove: (order: ICoursesOrderItem[]) => void;
    success: boolean;
    error: boolean;
    setPage: (pageIndex: number) => void;
    setSize: (size: number) => void;
    controlledPageCount: number | undefined;
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageOptions,
        nextPage,
        previousPage,
        gotoPage,
        state: { pageIndex, pageSize },
        prepareRow,
        setPageSize,
    }: any = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
    );

    const [dndPage, setDndPage] = useState([...page]);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    useEffect(() => {
        setDndPage(page);
    }, [page]);

    useEffect(() => {
        setPage(pageIndex + 1);
    }, [pageIndex]);

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = dndPage[dragIndex];

            setDndPage(
                update(dndPage, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            );

            if (isSaveButtonDisabled) {
                setIsSaveButtonDisabled(false);
            }
        },
        [dndPage],
    );

    const handleSaveMove = () => {
        const order = dndPage.map((row, index) => ({
            id: row.original.id,
            number: 1 + index + pageIndex * pageSize,
        }));

        onMove(order);
    };

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Div fontFamily="Universe, Arial" overflow="auto">
                    <Flex flexDirection="column" flex="1" m="20px">
                        <PrimaryButton
                            mb="20px"
                            onClick={handleSaveMove}
                            disabled={success || error || isSaveButtonDisabled}
                        >
                            {error
                                ? 'Something went wrong. Try again'
                                : success
                                ? 'Saved'
                                : 'Save moving'}
                        </PrimaryButton>
                        <Div p="0px 20px 20px 20px" backgroundColor="white">
                            <Table
                                textAlign="left"
                                size="100%"
                                {...getTableProps()}
                            >
                                <Thead>
                                    {headerGroups.map((headerGroup, index) => (
                                        <Tr
                                            key={`head-tr-${index}`}
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column, _index) => (
                                                    <Th
                                                        key={`head-tr-${index}-th-${_index}`}
                                                        {...column.getHeaderProps(
                                                            [
                                                                {
                                                                    width:
                                                                        column.width,
                                                                } as Partial<TableHeaderProps>,
                                                            ],
                                                        )}
                                                    >
                                                        <Text
                                                            my="20px"
                                                            variant="body"
                                                            textTransform="uppercase"
                                                        >
                                                            {column.render(
                                                                'Header',
                                                            )}
                                                        </Text>
                                                    </Th>
                                                ),
                                            )}
                                        </Tr>
                                    ))}
                                </Thead>
                                <Tbody {...getTableBodyProps()}>
                                    {dndPage.length !== 0 && (
                                        <>
                                            {dndPage.map(
                                                (row: any, index: number) => {
                                                    prepareRow(row);

                                                    return (
                                                        <DNDTableTr
                                                            key={row.id}
                                                            row={row}
                                                            index={index}
                                                            id={row.id}
                                                            moveRow={moveRow}
                                                        />
                                                    );
                                                },
                                            )}
                                        </>
                                    )}
                                </Tbody>
                            </Table>
                            {page.length === 0 && (
                                <Text
                                    width="100%"
                                    backgroundColor="white"
                                    textAlign="center"
                                    variant="body"
                                >
                                    Data is empty...
                                </Text>
                            )}
                            <Flex
                                pt="20px"
                                mt="100px"
                                borderTop="1px solid"
                                borderColor="lightGrey"
                                justifyContent="end"
                                alignItems="center"
                            >
                                <Input
                                    height="13px"
                                    width="10px"
                                    value={pageIndex + 1}
                                    onChange={(e) => {
                                        const dndPage = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;

                                        gotoPage(dndPage);
                                    }}
                                />
                                <Text
                                    ml="10px"
                                    variant="body"
                                >{`of ${pageOptions.length}`}</Text>
                                <Div mx="10px">
                                    <PageButton onClick={previousPage}>
                                        <ArrowLeft width="10px" height="20px" />
                                    </PageButton>
                                </Div>
                                <Div ml="10px">
                                    <PageButton onClick={nextPage}>
                                        <ArrowRight
                                            width="12px"
                                            height="22px"
                                        />
                                    </PageButton>
                                </Div>
                                <Div
                                    ml="20px"
                                    border="1px solid rgb(230, 232, 235)"
                                >
                                    <Select
                                        value={pageSize}
                                        placeholder="Select value"
                                        onChange={(e) => {
                                            setSize(
                                                Number(
                                                    (e.target as HTMLTextAreaElement)
                                                        .value,
                                                ),
                                            );
                                            setPageSize(
                                                Number(
                                                    (e.target as HTMLTextAreaElement)
                                                        .value,
                                                ),
                                            );
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map(
                                            (pageSize) => (
                                                <Select.Option
                                                    key={pageSize}
                                                    value={pageSize}
                                                >
                                                    Show {pageSize}
                                                </Select.Option>
                                            ),
                                        )}
                                    </Select>
                                </Div>
                                <PrimaryButton
                                    ml="auto"
                                    onClick={handleSaveMove}
                                    disabled={
                                        success || error || isSaveButtonDisabled
                                    }
                                >
                                    {error
                                        ? 'Something went wrong. Try again'
                                        : success
                                        ? 'Saved'
                                        : 'Save moving'}
                                </PrimaryButton>
                            </Flex>
                        </Div>
                        {dndPage.length === 0 && (
                            <Text
                                width="100%"
                                backgroundColor="white"
                                textAlign="center"
                                variant="body"
                            >
                                Data is empty...
                            </Text>
                        )}
                    </Flex>
                </Div>
            </DndProvider>
        </>
    );
};

export default DNDTable;
