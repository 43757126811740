import { PlainInput, Text } from 'components';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { LayoutProps, PositionProps, SpaceProps } from 'styled-system';

export type InputProps = SpaceProps &
    LayoutProps &
    PositionProps & { label?: string; error?: boolean; isBlack?: boolean };

const Input: React.FC<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
        InputProps
> = ({ label, isBlack, error, ...props }) => {
    return label ? (
        <label
            style={{ display: 'inline-block', width: '100%' }}
            htmlFor={props.id}
        >
            <Text
                color={isBlack ? 'white' : 'black'}
                mt="30px"
                opacity={isBlack ? '0.5' : 1}
                mb="10px"
                variant="body"
            >
                {label}
            </Text>
            <PlainInput
                width={'fit-content'}
                minWidth={'230px'}
                fontSize={['14px', '18px']}
                border="1px solid"
                boxSizing="border-box"
                p="16px 20px"
                variant={error ? 'error' : isBlack ? 'black' : 'default'}
                {...props}
            />
        </label>
    ) : (
        <PlainInput
            width="fit-content"
            fontSize={['14px', '18px']}
            border="1px solid"
            p="16px 20px"
            variant={error ? 'error' : isBlack ? 'black' : 'default'}
            {...props}
        />
    );
};

export default Input;
