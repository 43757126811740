import styled from '@emotion/styled';
import React /* useState*/ from 'react';

import { Div, Flex, Img, PlainInput, Span, Text } from '../';

const Label = styled.label({
    backgroundColor: '#F7F9FA',
    width: '100%',
    height: '88px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
const Form = styled.div({
    width: '100%',
    height: '100%',
});

export type FileInputProps = {
    value?: FileList;
    progress?: number;
    isLoading: boolean;
    onCancel?: () => void;
    onChange: (file: FileList) => void;
    preview?: string;
};

const FileInput: React.FC<Partial<HTMLInputElement> & FileInputProps> = ({
    title,
    ...props
}) => {
    // const [value, setValue] = useState<FileList | string | string[]>();

    function handleAnyDrag(e: any) {
        e.stopPropagation();
        e.preventDefault();
    }
    function handleDrop(e: { dataTransfer: { files: FileList } }) {
        props.onChange(e.dataTransfer.files);
    }

    return (
        <Div>
            <Text color="black" m="30px auto 10px" variant="body">
                {title}
            </Text>
            <Flex
                position="relative"
                justifyContent={'center'}
                alignItems={'center'}
                boxSizing={'border-box'}
                border={props.isLoading ? 'none' : '1px dashed'}
                borderColor={props.isLoading ? 'lightGrey' : 'lightGrey'}
                background="#F7F9FA"
                padding={'10px'}
                borderRadius={'2px'}
                {...(props.isLoading && {
                    borderTop: '1px solid #E6E8EB',
                    borderBottom: '1px solid #E6E8EB',
                    background: 'white',
                    borderColor: 'lightGrey',
                    height: '110px',
                    position: 'relative',
                })}
            >
                {props.isLoading ? (
                    <>
                        <Div
                            position={'absolute'}
                            top={'0'}
                            width={'100%'}
                            height={'2px'}
                            background={`linear-gradient(90deg, #006aff 0%, #006aff ${props.progress}%, white ${props.progress}%)`}
                        />
                        <Text variant="body">
                            {props.progress || 0}% or{' '}
                            <Span
                                color={'solidBlue'}
                                cursor="pointer"
                                onClick={props.onCancel}
                            >
                                cancel
                            </Span>
                        </Text>
                    </>
                ) : (
                    <>
                        <Form
                            onDrag={handleAnyDrag}
                            onDragStart={handleAnyDrag}
                            onDragEnd={handleAnyDrag}
                            onDragOver={handleAnyDrag}
                            onDragEnter={handleAnyDrag}
                            onDragLeave={handleAnyDrag}
                            onDrop={handleAnyDrag}
                        >
                            <Label htmlFor={props.id} onDrop={handleDrop}>
                                <PlainInput
                                    variant="body"
                                    type="file"
                                    value={props.value}
                                    onChange={(e) =>
                                        e.target.files &&
                                        props.onChange(e.target.files)
                                    }
                                    id={props.id}
                                    opacity={'0'}
                                    overflow={'hidden'}
                                    position={'absolute'}
                                    zIndex={-1}
                                />
                                <Text textAlign="center" variant="body">
                                    Drop files here or{' '}
                                    <Span color={'solidBlue'} cursor="pointer">
                                        select
                                    </Span>
                                </Text>
                            </Label>
                        </Form>
                    </>
                )}
                {props.preview && (
                    <Img height="100%" width="30%" src={props.preview} />
                )}
            </Flex>
        </Div>
    );
};

export default FileInput;
