import React, { useEffect, useState } from 'react';
import { getTranslation } from 'src/utils';
import { LayoutProps, PositionProps, SpaceProps } from 'styled-system';

import {
    ArrowDownIcon,
    ArrowUpIcon,
    Div,
    Flex,
    IconClose,
    PlainInput,
    Text,
} from '../';

type SponsorsInputProps = {
    value?: any[];
    name?: string;
    id?: string;
    lang?: string;
    suggestions: any[];
    placeholder: string;
    onChange: (value: any[] | undefined) => void;
} & SpaceProps &
    LayoutProps &
    PositionProps;

const SponsorsInput: React.FC<SponsorsInputProps> = ({
    value,
    name,
    lang,
    suggestions,
    onChange,
}) => {
    const [values, setValue] = useState<any[] | undefined>(value);
    const [isOpen, setOpened] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [language, setLanguage] = useState('ru');
    const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);

    function handleSuggestionClick(suggestion: any) {
        if (
            values
                ?.map((value) => getTranslation(value, language)?.title)
                .includes(getTranslation(suggestion, language)?.title)
        ) {
            setValue(
                values.filter(
                    (_value) =>
                        getTranslation(_value, language)?.title !==
                        getTranslation(suggestion, language)?.title,
                ),
            );
        } else {
            setValue(values ? [...values, suggestion] : [suggestion]);
        }
    }

    useEffect(() => {
        if (lang !== undefined) {
            setLanguage(lang);
        }
    }, [lang]);

    useEffect(() => {
        if (value !== undefined && values === undefined && suggestions.length) {
            setValue(
                value?.map((_value) =>
                    suggestions.find((suggestion) => suggestion.id === _value),
                ),
            );
        }
    }, [value, suggestions]);

    useEffect(() => {
        if (values !== undefined) {
            const event: any = {
                target: { name: undefined, value: undefined },
            };

            event.target.name = name;
            event.target.value = values.map((value) => value.id);
            onChange(event);
        }
    }, [values]);

    useEffect(() => {
        setFilteredSuggestions(
            suggestions.filter((suggestion) =>
                getTranslation(suggestion, language)
                    ?.title.toLowerCase()
                    .includes(searchValue.toLowerCase()),
            ),
        );
    }, [suggestions, searchValue]);

    return (
        <Div
            position={'relative'}
            padding={'9px 10px'}
            backgroundColor={'transparent'}
            width={'fit-content'}
            minWidth={'420px'}
            boxSizing={'border-box'}
            border={'#DCDDE2 1px solid'}
            {...(isOpen && {
                zIndex: 999,
                backgroundColor: 'menuGrey',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
            })}
        >
            <Flex
                minHeight={'46px'}
                boxSizing={'border-box'}
                padding={'9px 10px'}
                backgroundColor={'white'}
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex flexDirection="column">
                    <PlainInput
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        variant="search"
                        placeholder="Select sponsors"
                    ></PlainInput>
                    {values && values.length > 0 && (
                        <Flex flexWrap="wrap" style={{ gap: '10px' }}>
                            {values.map((_value, _index) => (
                                <Flex
                                    key={_index}
                                    alignItems="center"
                                    padding="6px 10px"
                                    backgroundColor="authorsSuggestionsBackgroundColor"
                                    cursor="pointer"
                                    borderRadius="100px"
                                    onClick={() =>
                                        setValue(
                                            values.filter(
                                                (__value) =>
                                                    getTranslation(
                                                        __value,
                                                        language,
                                                    )?.title !==
                                                    getTranslation(
                                                        _value,
                                                        language,
                                                    )?.title,
                                            ),
                                        )
                                    }
                                >
                                    <Flex
                                        width={'16px'}
                                        height={'16px'}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        borderRadius={'10px'}
                                        boxSizing={'border-box'}
                                        marginRight={'10px'}
                                        background={`url(${
                                            getTranslation(_value, language)
                                                ?.logoUrl
                                        })`}
                                        backgroundSize="contain"
                                    />
                                    <Text
                                        variant="body"
                                        color="inputGraphit"
                                        marginBottom="-2px"
                                    >
                                        {
                                            getTranslation(_value, language)
                                                ?.title
                                        }
                                    </Text>
                                    <Flex alignItems="center" marginLeft="10px">
                                        <IconClose width="12px" height="12px" />
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    )}
                </Flex>
                {isOpen ? (
                    <Div cursor="pointer" onClick={() => setOpened(!isOpen)}>
                        <ArrowUpIcon width="15px" height="10px" />
                    </Div>
                ) : (
                    <Div cursor="pointer" onClick={() => setOpened(!isOpen)}>
                        <ArrowDownIcon width="15px" height="10px" />
                    </Div>
                )}
            </Flex>
            {isOpen && (
                <Div
                    position={'absolute'}
                    width={'100%'}
                    marginLeft={'-10px'}
                    padding={'10px'}
                    zIndex={999}
                    backgroundColor={'menuGrey'}
                    boxSizing={'border-box'}
                    borderBottomLeftRadius={'4px'}
                    borderBottomRightRadius={'4px'}
                >
                    {filteredSuggestions.map((_suggestion, index) => (
                        <Flex
                            paddingTop="10px"
                            paddingBottom="10px"
                            key={index}
                            cursor="pointer"
                            alignItems="center"
                            onClick={() => handleSuggestionClick(_suggestion)}
                        >
                            <Flex
                                width={'16px'}
                                height={'16px'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                borderRadius={'10px'}
                                marginTop="-3px"
                                boxSizing={'border-box'}
                                marginRight={'10px'}
                                background={`url(${
                                    getTranslation(_suggestion, language)
                                        ?.logoUrl
                                })`}
                                backgroundSize="contain"
                                {...(!!values
                                    ?.map(
                                        (_value) =>
                                            getTranslation(_value, language)
                                                ?.title,
                                    )
                                    .includes(
                                        getTranslation(_suggestion, language)
                                            ?.title,
                                    ) && {
                                    border: 'none',
                                    backgroundColor: 'solidBlue',
                                })}
                            ></Flex>
                            <Text
                                whiteSpace="nowrap"
                                variant="body"
                                color={
                                    values
                                        ?.map(
                                            (_value) =>
                                                getTranslation(_value, language)
                                                    ?.title,
                                        )
                                        .includes(
                                            getTranslation(
                                                _suggestion,
                                                language,
                                            )?.title,
                                        )
                                        ? 'black'
                                        : 'authorsSuggestionsColor'
                                }
                            >
                                {getTranslation(_suggestion, language)?.title}
                            </Text>
                            <Text
                                margin="0 5px"
                                whiteSpace="nowrap"
                                variant="body"
                                color={
                                    values
                                        ?.map(
                                            (_value) =>
                                                getTranslation(_value, language)
                                                    ?.title,
                                        )
                                        .includes(
                                            getTranslation(
                                                _suggestion,
                                                language,
                                            )?.title,
                                        )
                                        ? 'black'
                                        : 'authorsSuggestionsColor'
                                }
                            >
                                —
                            </Text>
                            <Text
                                whiteSpace="nowrap"
                                variant="body"
                                color={
                                    values
                                        ?.map(
                                            (_value) =>
                                                getTranslation(_value, language)
                                                    ?.title,
                                        )
                                        .includes(
                                            getTranslation(
                                                _suggestion,
                                                language,
                                            )?.title,
                                        )
                                        ? 'black'
                                        : 'authorsSuggestionsColor'
                                }
                            >
                                {_suggestion.position}
                            </Text>
                        </Flex>
                    ))}
                </Div>
            )}
        </Div>
    );
};

export default SponsorsInput;
