import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Item } from './Item';

const DndContainer = (props) => {
    const [cards, setCards] = useState(props.items);

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = cards[dragIndex];

            setCards(
                update(cards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            );
        },
        [cards],
    );

    useEffect(() => {
        setCards(props.items);
    }, [props.items]);

    useEffect(() => {
        // console.log(cards.map(card => card.announcementId))
    }, [cards]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                {cards?.map((item, i) => (
                    <Item
                        key={item.announcementId}
                        index={i}
                        id={item.announcementId}
                        item={item}
                        moveCard={moveCard}
                        Component={props.component}
                        componentProps={props.componentProps}
                    />
                ))}
            </div>
        </DndProvider>
    );
};

export { DndContainer };
