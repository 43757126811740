import { ArrowDownIcon, ArrowUpIcon, Div, Flex, Text } from 'components';
import React, { useState } from 'react';

export const Block = (props: any) => {
    const [isOpened, setOpened] = useState(true);

    return (
        <Div mb="20px">
            <Flex
                p="11px 20px"
                background="#E7ECEE"
                onClick={() => setOpened(!isOpened)}
                justifyContent="space-between"
                alignItems="center"
            >
                <Text variant="body" textTransform="uppercase">
                    {props.title}
                </Text>
                {isOpened ? (
                    <ArrowUpIcon width="15px" height="10px" />
                ) : (
                    <ArrowDownIcon width="15px" height="10px" />
                )}
            </Flex>
            {isOpened && <Div p="10px 20px">{props.children}</Div>}
        </Div>
    );
};
