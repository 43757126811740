import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import * as CSS from 'csstype';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import {
    border,
    BorderProps,
    color,
    ColorProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    ResponsiveValue,
    size,
    SizeProps,
    space,
    SpaceProps,
    system,
    typography,
    TypographyProps,
    variant,
} from 'styled-system';

const commonStyles = {
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
    fontWeight: 'normal',
};

type VariantsList =
    | 'body'
    | 'title'
    | 'service'
    | 'bodyFugue'
    | 'titleFugue'
    | 'h1Fugue'
    | 'h2'
    | 'h3'
    | 'h4';

const textVariants = variant({
    variants: {
        body: {
            ...commonStyles,
            fontFamily: 'Universe, Arial',
            fontSize: '14px',
        },
        title: {
            ...commonStyles,
            fontFamily: 'Universe, Arial',
            fontSize: '18px',
        },
        service: {
            ...commonStyles,
            fontFamily: 'Universe, Arial',
            fontSize: '12px',
        },
        bodyFugue: {
            ...commonStyles,
            fontFamily: 'Fugue, Arial',
            fontSize: '14px',
        },
        titleFugue: {
            ...commonStyles,
            fontFamily: 'Fugue, Arial',
            fontSize: '18px',
        },

        h2: {
            ...commonStyles,
            fontFamily: 'Fugue, Arial',
            fontSize: '36px',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
        },
        h3: {
            ...commonStyles,
            fontFamily: 'Fugue, Arial',
            fontSize: '32px',
            textTransform: 'uppercase',
            textSizeAdjust: 'none',
        },
        h4: {
            ...commonStyles,
            fontFamily: 'Fugue, Arial',
            fontSize: '16px',
            textTransform: 'uppercase',
            textSizeAdjust: 'none',
        },
    },
});

export type TextProps = TypographyProps &
    LayoutProps &
    BorderProps &
    SizeProps &
    SpaceProps &
    PositionProps &
    DetailedHTMLProps<
        HTMLAttributes<HTMLParagraphElement>,
        HTMLParagraphElement
    > &
    ColorProps & {
        as?: React.ComponentType | string;
        variant?: ResponsiveValue<VariantsList>;
        textTransform?: ResponsiveValue<CSS.Property.TextDecoration>;
        cursor?: ResponsiveValue<CSS.Property.Cursor>;
        whiteSpace?: ResponsiveValue<CSS.Property.WhiteSpace>;
        boxSizing?: ResponsiveValue<CSS.Property.BoxSizing>;
    };

const Text: React.FC<TextProps> = styled('p', { shouldForwardProp })(
    typography,
    size,
    space,
    border,
    position,
    layout,
    color,
    textVariants,
    system({
        cursor: {
            property: 'cursor',
        },
        textTransform: {
            property: 'textTransform',
        },
        whiteSpace: {
            property: 'whiteSpace',
        },
        boxSizing: {
            property: 'boxSizing',
        },
    }),
);

export default Text;
