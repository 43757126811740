import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const Download: React.VoidFunctionComponent<CustomSvgProps> = (props) => {
    return (
        <Svg
            {...props}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5.46094 0V10.0128" stroke="white" />
            <path
                d="M9.92308 6.29492L5.46154 10.7565L1 6.29492"
                stroke="white"
            />
        </Svg>
    );
};

Svg.defaultProps = {
    width: '16px',
    height: '16px',
};

export default Download;
