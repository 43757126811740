import styled from '@emotion/styled';

import { Text } from '../../components';

export const Form = styled.form({
    width: '560px',
    label: {
        width: '100%',
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
    },
});

export const FileInputBase = styled.input({
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    'z-index': '-1',
    width: '100%',
    height: '100%',
});

export const Label = styled.label({
    position: 'relative',
});

export const Description = styled(Text)({
    position: 'relative',
    cursor: 'pointer',
    p: {
        display: 'none',
        position: 'absolute',
        top: '150%',
        left: 0,
        padding: '10px',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 2,
    },
    '&:hover': {
        p: {
            display: 'inline',
        },
    },
});
