import 'react-sortable-tree/style.css';

import styled from '@emotion/styled';
import {
    Alert,
    DefaultButton,
    Div,
    Flex,
    IconClose,
    PlainInput,
    PrimaryButton,
    Text,
} from 'components';
import { updateItemOrder } from 'mutations';
import { arrayToTree } from 'performant-array-to-tree';
import { getCourseItems, getCourseItemsSlugs } from 'queries';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';

import { queryClient } from '../../main';
import ChapterForm from './ChapterForm';
import LessonForm from './LessonForm';
import MaterialsForm from './MaterialsForm';
import TestForm from './TestForm';

const TreeWrapper = styled.div({
    marginLeft: '-45px',
    padding: '20px',
    '.rstcustom__rowContents': {
        width: '100%',
    },
    '.rstcustom__rowSubtitle': {
        fontSize: '100%',
        lineHeight: 1,
        whiteSpace: 'normal',
    },
    '.rstcustom__nodeContent': {
        padding: 0,
        width: 'fill-available',
    },
});

const CourseContent = (): JSX.Element => {
    const history = useHistory();
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [contentItemId, setContentItemId] = useState<number | undefined>(
        undefined,
    );
    const [parentId, setParentId] = useState<number | undefined>(undefined);
    const [contentItemType, setContentItemType] = useState<string | undefined>(
        undefined,
    );

    const { courseId }: { courseId: any } = useParams();

    const { data: contentByCourseId } = useQuery(
        ['course-items', courseId],
        () => getCourseItems(courseId!, 1, 100),
        { enabled: !!courseId },
    );

    const { data: slugs } = useQuery(['slugs'], () => getCourseItemsSlugs());
    // TODO: test slugs foe each form

    const itemOrder = useMutation((item: any) => updateItemOrder(item), {
        onSuccess: () => {
            queryClient.invalidateQueries('courses');
        },
        onError: (error: any) => {
            setError(true);
            setErrorMessage(Object.values(error.response.data).join(' - '));
            setTimeout(() => {
                setError(false);
                setErrorMessage('');
            }, 5000);
        },
    });

    const handleEditContentItem = (type, id?, parentId?) => {
        !parentId && setContentItemId(id);
        setContentItemType(type);
        setParentId(parentId);
    };

    const chapters =
        contentByCourseId?.items.filter((item) => item.type === 'chapter') ||
        [];

    const parentsItems = [{ id: undefined, title: 'курс' }, ...chapters];

    const handleClose = () => {
        setContentItemType(undefined);
    };

    const [treeData, setTreeData] = useState<any>([]);

    useEffect(() => {
        const contentWithKeys =
            contentByCourseId?.items.map((item: any) => ({
                ...item,
                title: `${item.id} - ${item.type}`,
                subtitle: item.title,
            })) || [];

        const tree = arrayToTree(contentWithKeys, { dataField: null });

        setTreeData(tree);
    }, [contentByCourseId]);

    return contentByCourseId ? (
        <Div>
            {isError && <Alert message={errorMessage} />}
            <Flex
                p="20px"
                backgroundColor="white"
                justifyContent="space-between"
            >
                <PlainInput variant="plain" placeholder="Search" />
                <PrimaryButton onClick={() => handleEditContentItem('chapter')}>
                    Add Chapter
                </PrimaryButton>
                <PrimaryButton onClick={() => handleEditContentItem('lesson')}>
                    Add Lesson
                </PrimaryButton>
                <PrimaryButton
                    onClick={() => handleEditContentItem('material')}
                >
                    Add Materials
                </PrimaryButton>
                <PrimaryButton onClick={() => handleEditContentItem('test')}>
                    Add Test
                </PrimaryButton>
            </Flex>
            <Flex width="100%" flex="1" justifyContent="center">
                <Text
                    variant="h4"
                    fontSize="16px"
                    textTransform="uppercase"
                    mt="20px"
                    ml="auto"
                >
                    {courseId} course content items
                </Text>
                <Flex
                    ml="auto"
                    mr="10px"
                    onClick={() => history.push('/courses')}
                    p="20px"
                    cursor="pointer"
                >
                    <IconClose stroke={'black'} />
                </Flex>
            </Flex>
            <TreeWrapper>
                <SortableTree
                    treeData={treeData}
                    isVirtualized={false}
                    theme={FileExplorerTheme}
                    onChange={(treeData) => {
                        setTreeData(treeData);
                    }}
                    generateNodeProps={(rowInfo) => ({
                        buttons: [
                            rowInfo.node.type === 'test' && (
                                <DefaultButton
                                    px="10px"
                                    mr="10px"
                                    key={rowInfo.node.id}
                                    onClick={() =>
                                        history.push(
                                            `content/${rowInfo.node.id}`,
                                        )
                                    }
                                >
                                    Questions
                                </DefaultButton>
                            ),
                            <DefaultButton
                                px="10px"
                                key={rowInfo.node.id}
                                onClick={() =>
                                    handleEditContentItem(
                                        rowInfo.node.type,
                                        rowInfo.node.id,
                                    )
                                }
                            >
                                Edit
                            </DefaultButton>,
                        ],
                    })}
                    onMoveNode={(data) => {
                        const itemsOrder: any[] = [];

                        const getFlatData = (items, parentId?) =>
                            items.map((node: any, index: number) => {
                                itemsOrder.push({
                                    id: node.id,
                                    number: index,
                                    parentId: parentId || undefined,
                                });

                                if (node.children.length > 0) {
                                    getFlatData(node.children, node.id);
                                }
                            });

                        getFlatData(data.treeData);

                        itemOrder.mutate({
                            order: itemsOrder,
                        });
                    }}
                />
            </TreeWrapper>
            {contentItemType === 'lesson' && (
                <LessonForm
                    courseId={courseId}
                    lessonId={contentItemId}
                    parentsItems={parentsItems}
                    parentId={parentId}
                    onClose={handleClose}
                    slugs={slugs}
                    isOpened
                />
            )}
            {contentItemType === 'material' && courseId && (
                <MaterialsForm
                    courseId={courseId}
                    materialId={contentItemId}
                    onClose={handleClose}
                    isOpened
                />
            )}
            {contentItemType === 'chapter' && (
                <ChapterForm
                    courseId={courseId}
                    chapterId={contentItemId}
                    parentsItems={parentsItems}
                    parentId={parentId}
                    onClose={handleClose}
                    slugs={slugs}
                    isOpened
                />
            )}
            {contentItemType === 'test' && (
                <TestForm
                    courseId={courseId}
                    testId={contentItemId}
                    parentsItems={parentsItems}
                    parentId={parentId}
                    onClose={handleClose}
                    slugs={slugs}
                    isOpened
                />
            )}
        </Div>
    ) : (
        <></>
    );
};

export default CourseContent;
