import {
    Div,
    DotsMenu,
    Flex,
    Grid,
    Img,
    LanguageToggle,
    PlainInput,
    PrimaryButton,
    Text,
} from 'components';
import { DndContainer } from 'components';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getAnnouncements, getAnnouncementsQueried } from 'src/api/queries';

import BannerForm from './BannerForm';
import { StatusIcon } from './styled';

export function getStatusColor(status: string) {
    switch (status) {
        case 'active':
            return 'saladGreen';
        case 'waiting':
            return 'bannerStatusGrey';
        case 'finished':
            return 'red';
        case 'onHold':
            return 'red';
        default:
            return 'black';
    }
}
export function getStatusName(status: string) {
    switch (status) {
        case 'active':
            return 'On general';
        case 'waiting':
            return 'Waiting';
        case 'finished':
            return 'Not active';
        case 'onHold':
            return 'On hold';
        default:
            return '';
    }
}

type BannerItemProps = {
    lang: string;
    banner: any;
    onEdit: () => void;
};

const BannerItem = (props: BannerItemProps) => {
    const { lang, banner } = props;

    const translatedData = (_lang: string) =>
        banner.translations.find(
            (_translation) => _translation.language === _lang,
        );

    return (
        <Grid
            p="20px"
            mb="10px"
            backgroundColor="white"
            gridTemplateColumns={
                lang === 'all' ? '1fr 1fr 1fr 30px' : '1fr 1fr 1fr 1fr 30px'
            }
        >
            {lang !== 'all' && (
                <Text variant="body" color={getStatusColor(banner.status)}>
                    {getStatusName(banner.status)}
                </Text>
            )}
            {lang === 'all' && (
                <Flex>
                    {translatedData('en') && (
                        <StatusIcon
                            backgroundColor={getStatusColor(
                                translatedData('en')?.status,
                            )}
                            lang="en"
                            status={getStatusName(translatedData('en')?.status)}
                        />
                    )}
                    {translatedData('ru') && (
                        <StatusIcon
                            backgroundColor={getStatusColor(
                                translatedData('ru')?.status,
                            )}
                            lang="ru"
                            status={getStatusName(translatedData('ru')?.status)}
                        />
                    )}
                </Flex>
            )}
            <Flex flexDirection="column">
                {lang === 'all' ? (
                    <Flex>
                        <Flex flexDirection="column" mr="20px">
                            <Text
                                variant="body"
                                color="textCaptionGrey"
                                mb="10px"
                            >
                                DESKTOP
                            </Text>
                            {translatedData('en')?.pictureWeb ||
                            translatedData('ru')?.pictureWeb ? (
                                <>
                                    {translatedData('en')?.pictureWeb && (
                                        <Img
                                            mb="10px"
                                            imgWidth="115px"
                                            imgHeight="66px"
                                            src={
                                                translatedData('en')?.pictureWeb
                                            }
                                        />
                                    )}
                                    {translatedData('ru')?.pictureWeb && (
                                        <Img
                                            mb="10px"
                                            imgWidth="115px"
                                            imgHeight="66px"
                                            src={
                                                translatedData('ru')?.pictureWeb
                                            }
                                        />
                                    )}
                                </>
                            ) : (
                                <Text variant="body" color="lightGrey">
                                    Image doesnt set
                                </Text>
                            )}
                        </Flex>
                        <Flex flexDirection="column">
                            <Text
                                variant="body"
                                color="textCaptionGrey"
                                mb="10px"
                            >
                                MOBILE
                            </Text>
                            {translatedData('en')?.pictureMobile ||
                            translatedData('ru')?.pictureMobile ? (
                                <>
                                    {translatedData('en')?.pictureMobile && (
                                        <Img
                                            mb="10px"
                                            imgWidth="115px"
                                            imgHeight="66px"
                                            src={
                                                translatedData('en')
                                                    ?.pictureMobile
                                            }
                                        />
                                    )}
                                    {translatedData('ru')?.pictureMobile && (
                                        <Img
                                            mb="10px"
                                            imgWidth="115px"
                                            imgHeight="66px"
                                            src={
                                                translatedData('ru')
                                                    ?.pictureMobile
                                            }
                                        />
                                    )}
                                </>
                            ) : (
                                <Text variant="body" color="lightGrey">
                                    Image doesnt set
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                ) : (
                    banner?.pictureWeb && (
                        <Img
                            imgWidth="115px"
                            imgHeight="66px"
                            src={banner?.pictureWeb}
                        />
                    )
                )}
            </Flex>
            <Div>
                {lang === 'all' ? (
                    <>
                        <Text
                            variant="body"
                            mb="10px"
                            color={
                                translatedData('en')?.title
                                    ? 'black'
                                    : 'lightGrey'
                            }
                        >
                            <Text
                                variant="body"
                                color="textCaptionGrey"
                                lineHeight="14px"
                            >
                                EN
                            </Text>
                            {translatedData('en')?.title || 'Title doesnt set'}
                        </Text>
                        <Text
                            variant="body"
                            mb="10px"
                            color={
                                translatedData('ru')?.title
                                    ? 'black'
                                    : 'lightGrey'
                            }
                        >
                            <Text
                                variant="body"
                                color="textCaptionGrey"
                                lineHeight="14px"
                            >
                                RU
                            </Text>
                            {translatedData('ru')?.title || 'Title doesnt set'}
                        </Text>
                    </>
                ) : (
                    <Text
                        variant="body"
                        color={banner?.title ? 'black' : 'lightGrey'}
                    >
                        {banner?.title || 'Title doenst set'}
                    </Text>
                )}
            </Div>
            {lang !== 'all' && (
                <Div>
                    <Text variant="body">
                        {`${
                            banner?.dateStart
                                ? new Date(
                                      banner?.dateStart,
                                  ).toLocaleDateString('ru-RU')
                                : 'start date doenst set'
                        } - ${
                            banner?.dateEnd
                                ? new Date(banner?.dateEnd).toLocaleDateString(
                                      'ru-RU',
                                  )
                                : 'end date doenst set'
                        }`}
                    </Text>
                </Div>
            )}
            <Div>
                <DotsMenu
                    actions={[
                        { title: 'Edit', onClick: props.onEdit },
                        {
                            title: 'Delete',
                            onClick: () => null,
                        },
                    ]}
                />
            </Div>
        </Grid>
    );
};
const Banners = () => {
    const match: {
        path: string;
        params: { bannerId?: string };
    } = useRouteMatch();
    const history = useHistory();
    const [lang, setLang] = useState<string>('all');
    const isFormOpen = match?.path === '/banners/:bannerId';

    const banners = useQuery('banners', getAnnouncements);
    const bannersLocalized = useQuery('bannersQueried', async () =>
        getAnnouncementsQueried(lang),
    );

    useEffect(() => {
        if (lang !== 'all') {
            bannersLocalized.refetch();
        }
    }, [lang]);

    return (
        <Div>
            <Flex
                p="20px"
                backgroundColor="white"
                justifyContent="space-between"
            >
                <PlainInput variant="plain" placeholder="Search" />
                <PrimaryButton onClick={() => history.push('/banners/new')}>
                    Add banner
                </PrimaryButton>
            </Flex>
            <Div padding="20px">
                <Flex
                    ml="auto"
                    mb="20px"
                    width="fit-content"
                    alignItems="center"
                >
                    <Text variant="body" color="textCaptionGrey" mr="10px">
                        Language
                    </Text>
                    <LanguageToggle
                        chosenLang={lang}
                        toggleChosenLang={setLang}
                        customLangs={['all', 'ru', 'en']}
                    />
                </Flex>
                {lang === 'all' ? (
                    banners?.data?.map((banner, index: number) => (
                        <BannerItem
                            onEdit={() => history.push(`/banners/${banner.id}`)}
                            banner={banner}
                            key={index}
                            lang={'all'}
                        />
                    ))
                ) : (
                    <DndContainer
                        items={bannersLocalized?.data}
                        component={BannerItem}
                        componentProps={{
                            onEdit: (id: string) =>
                                history.push(`/banners/${id}`),
                            lang,
                        }}
                    />
                )}
            </Div>
            {isFormOpen && (
                <BannerForm
                    id={match?.params?.bannerId}
                    onClose={() => history.push('/banners')}
                />
            )}
        </Div>
    );
};

export default Banners;
