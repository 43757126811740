/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-mde/lib/styles/css/react-mde-all.css';

import { ICourseChapter } from 'api-model';
import {
    Alert,
    DefaultButton,
    Div,
    Flex,
    Input,
    Modal,
    PrimaryButton,
    Select,
    Text,
} from 'components';
import { useFormik } from 'formik';
import { IFormik } from 'formik-model';
import { createChapter, updateChapter } from 'mutations';
import { getItem } from 'queries';
import React, { useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useMutation, useQuery } from 'react-query';
import * as Showdown from 'showdown';
import slugify from 'slugify';
import { getTranslation } from 'utils';
import * as Yup from 'yup';

import { queryClient } from '../../main';

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

type ChapterFormProps = {
    onClose: () => void;
    courseId: number;
    chapterId: number | undefined;
    parentId: number | undefined;
    isOpened: boolean;
    slugs: string[] | undefined;
    parentsItems: any[] | undefined;
};

const ChapterForm = ({
    onClose,
    courseId,
    chapterId,
    parentId,
    isOpened,
    slugs,
    parentsItems,
}: ChapterFormProps): JSX.Element => {
    const { data: chapter } = useQuery(
        ['chapter', chapterId],
        () => getItem(chapterId!),
        { enabled: !!chapterId },
    );

    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
        'write',
    );

    const [isSaved, setSaved] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => {
        formik.resetForm();
        onClose();
        setSaved(false);
    };

    const createChapterMutation = useMutation(
        (chapter: ICourseChapter) => createChapter(chapter),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );
    const chapterMutation = useMutation(
        (chapter: ICourseChapter) => updateChapter(chapter),
        {
            onSuccess: () => {
                setSaved(true);
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );

    const formik: IFormik = useFormik({
        initialValues: {
            id: chapterId!,
            parentId: parentId!,
            courseId: courseId!,
            title: chapter && getTranslation(chapter, 'ru')?.title,
            slug: chapter?.slug,
            description: chapter
                ? converter.makeMarkdown(
                      getTranslation(chapter, 'ru')?.description || '',
                  )
                : '',
            number: chapter?.number,
            weight: chapter?.weight,
            duration: chapter?.duration,
        },

        enableReinitialize: true,
        validateOnMount: !courseId,
        validationSchema: Yup.object().shape({
            slug: Yup.string()
                .test('slug', 'Slug already exist', (value) =>
                    value
                        ? !slugs
                              ?.filter(
                                  (slug) => slug !== formik.initialValues.slug,
                              )
                              .includes(value)
                        : true,
                )
                .min(1)
                .max(255)
                .required(),
            persons: Yup.array(),
            number: Yup.number().required('must be a number'),
            weight: Yup.number(),
            duration: Yup.number(),
        }),
        onSubmit: (data) => {
            const chapter: ICourseChapter = {
                id: chapterId!,
                courseId: courseId!,
                parentId: data.parentId!,
                slug: data.slug!,
                number: data.number!,
                weight: data.weight!,
                duration: data.duration!,
                type: 'chapter',
                translations: [
                    {
                        language: 'ru',
                        title: data.title,
                        description: converter.makeHtml(data.description!),
                    },
                ],
            };

            submitLesson(chapter);
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const submitLesson = (chapter) => {
        if (chapter.id) {
            chapterMutation.mutate(chapter);
        } else {
            createChapterMutation.mutate(chapter);
        }
    };

    const handleChangeWithSlug = (chapter) => {
        setFieldValue('slug', slugify(chapter.target.value.toLowerCase()));
        handleChange(chapter);
    };

    return (
        <Modal
            label={chapterId ? `Глава ${chapterId}` : 'Создать новую Главу'}
            onClose={handleClose}
            isOpened={isOpened}
            backgroundColor="#EFF2F3"
        >
            <form onSubmit={handleSubmit}>
                {isError && <Alert message={errorMessage} />}
                <Div
                    backgroundColor="white"
                    borderRadius="2px"
                    boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                    gridColumn="1/3"
                    pb="20px"
                >
                    <Div px="20px">
                        <Div pt="20px">
                            <Text variant="body" mb="10px">
                                Вложенность
                            </Text>
                            <Select
                                id="parentId"
                                name="parentId"
                                value={values?.parentId}
                                valueTitle={
                                    parentsItems?.find(
                                        (parent) =>
                                            values?.parentId === parent.id,
                                    )?.title
                                }
                                placeholder="Select projects"
                                onChange={handleChange}
                            >
                                {parentsItems
                                    ?.filter(
                                        (parent) => parent.id !== chapterId,
                                    )
                                    ?.map((parent) => (
                                        <Select.Option
                                            key={parent.id}
                                            value={parent.id}
                                        >
                                            {parent.title}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Div>
                        <Input
                            label={'Заголовок'}
                            name={`title`}
                            id={`title`}
                            placeholder={'Заголовок'}
                            onChange={handleChangeWithSlug}
                            onBlur={handleBlur}
                            value={values.title || ''}
                            error={!!errors.title && !!touched.title}
                        />
                        {touched.title && errors.title && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.title}
                            </Text>
                        )}
                    </Div>
                    <Div width="50%" px="20px">
                        <Input
                            label="Slug"
                            name="slug"
                            id="slug"
                            placeholder="Slug"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.slug}
                            error={!!errors.slug}
                        />
                        {errors.slug && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.slug}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px">
                        <Text variant="body" mt="20px" mb="10px">
                            {'Описание'}
                        </Text>
                        <ReactMde
                            value={values.description || ''}
                            onChange={handleChange(`description`)}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            toolbarCommands={[['bold', 'italic', 'link']]}
                            generateMarkdownPreview={(markdown) =>
                                Promise.resolve(converter.makeHtml(markdown))
                            }
                        />
                        {touched.description && errors.description && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.description}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Длительность в минутах'}
                            name={`duration`}
                            id={`duration`}
                            placeholder={'Длительность в минутах'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.duration || ''}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Вес'}
                            name={`weight`}
                            id={`weight`}
                            placeholder={'Вес'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.weight || ''}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Номер'}
                            name={`number`}
                            id={`number`}
                            placeholder={'Номер'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number || ''}
                            error={!!errors.number}
                        />
                        {touched?.number && errors?.number && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.number}
                            </Text>
                        )}
                    </Div>
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid || isSaved}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        {isSaved ? 'Saved' : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </form>
        </Modal>
    );
};

export default ChapterForm;
