import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import * as CSS from 'csstype';
import {
    background,
    BackgroundProps,
    border,
    BorderProps,
    color,
    ColorProps,
    compose,
    layout,
    LayoutProps,
    position,
    PositionProps,
    ResponsiveValue,
    ShadowProps,
    space,
    SpaceProps,
    system,
    typography,
    TypographyProps,
} from 'styled-system';

type CustomProps = {
    listStyle?: ResponsiveValue<CSS.Property.ListStyle>;
    boxSizing?: ResponsiveValue<CSS.Property.BoxSizing>;
};

export type UlProps = SpaceProps &
    ColorProps &
    LayoutProps &
    BackgroundProps &
    BorderProps &
    PositionProps &
    ShadowProps &
    TypographyProps &
    CustomProps;

const Ul = styled('ul', { shouldForwardProp })<UlProps>(
    compose(typography, layout, space, color, position, background, border),
    system({
        boxSizing: {
            property: 'boxSizing',
        },
        listStyle: {
            property: 'listStyle',
        },
    }),
);

Ul.defaultProps = {
    listStyle: 'none',
};

export default Ul;
