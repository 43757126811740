import { useFormik } from 'formik';
import { IFormik } from 'formik-model';
import React from 'react';
import { useHistory } from 'react-router';
import { signIn } from 'src/api/queries';
import { Alert, Flex, Input, PrimaryButton, Text } from 'src/components';
import * as Yup from 'yup';

const Auth = () => {
    const history = useHistory();
    const formik: IFormik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validateOnMount: true,
        validationSchema: Yup.object().shape({
            username: Yup.string().email().required(''),
            password: Yup.string().required(''),
        }),
        onSubmit: (values, { setStatus, validateForm }) => {
            signIn(values)
                .then(() => {
                    history.push('/');
                })
                .catch(() => {
                    formik.resetForm({});
                    setStatus('error');
                    validateForm();
                    setTimeout(() => setStatus(undefined), 5000);
                });
        },
    });

    return (
        <Flex
            size="100%"
            alignItems="center"
            justifyContent="center"
            backgroundColor="black"
        >
            {formik.status === 'error' && <Alert message="Login Failed" />}
            <Flex flex="0.8">
                <form onSubmit={formik.handleSubmit}>
                    <Flex flexDirection="column">
                        <Text color="white" variant="h2">
                            STRELKA DASHBOARD
                        </Text>
                        <Text color="white" opacity="0.5" variant="h2">
                            login
                        </Text>

                        <Input
                            label="Login"
                            id="username"
                            isBlack
                            name="username"
                            placeholder="Login"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            error={
                                formik.values.username !== '' &&
                                formik.touched.username &&
                                !!formik.errors.username
                            }
                        />
                        <br />
                        <Input
                            isBlack
                            my="10px"
                            label="Password"
                            id="password"
                            onBlur={formik.handleBlur}
                            placeholder="Password"
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            type="password"
                            error={
                                formik.values.password !== '' &&
                                formik.touched.password &&
                                !!formik.errors.password
                            }
                        />
                    </Flex>
                    <PrimaryButton
                        disabled={!formik.isValid}
                        isBig
                        mt="40px"
                        type="submit"
                    >
                        Login
                    </PrimaryButton>
                </form>
            </Flex>
        </Flex>
    );
};

export default Auth;
