import { Div, Text } from 'components';
import React from 'react';

type BlockProps = {
    label: string;
};

const Block: React.FC<BlockProps> = ({ label, children }) => {
    return (
        <Div
            overflow="hidden"
            borderRadius="2px"
            boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
            backgroundColor="white"
        >
            <Text
                boxSizing="border-box"
                p="10px 20px"
                textTransform="uppercase"
                width="100%"
                variant="bodyFugue"
                backgroundColor="lightGrey"
                color="inputGraphit"
            >
                {label}
            </Text>
            <Div padding="30px 20px">{children}</Div>
        </Div>
    );
};

export default Block;
