/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { createFormat, updateFormat } from 'src/api/mutations';
import { getFormat } from 'src/api/queries';
import {
    DefaultButton,
    Div,
    Flex,
    Input,
    Modal,
    PrimaryButton,
} from 'src/components';
import { IEditFormat } from 'src/model/types/api';
import { IFormik } from 'src/model/types/Formik';
import * as Yup from 'yup';

import { queryClient } from '../../main';

interface IEditFormatModal {
    onClose: () => void;
    isOpened: boolean;
    formatId: number | undefined;
}

const EditFormatModal: React.FC<IEditFormatModal> = ({
    onClose,
    isOpened,
    formatId,
}) => {
    const handleClose = () => {
        formik.resetForm();
        onClose();
    };
    const { data: format } = useQuery(
        ['formats', formatId],
        () => getFormat(formatId!),
        { enabled: !!formatId },
    );
    const createFormatMutation = useMutation(
        (format: IEditFormat) => createFormat(format),
        {
            onSuccess: () => {
                handleClose();
                formik.resetForm();
                queryClient.invalidateQueries('formats');
            },
        },
    );
    const formatMutation = useMutation(
        (format: IEditFormat) => updateFormat(format),
        {
            onSuccess: () => queryClient.invalidateQueries('formats'),
        },
    );
    const formik: IFormik = useFormik({
        initialValues: {
            translations: {
                en: {
                    title:
                        format?.translations.find(
                            (translation) => translation.language === 'en',
                        )?.title || null,
                },
                ru: {
                    title:
                        format?.translations.find(
                            (translation) => translation.language === 'ru',
                        )?.title || null,
                },
            },
        },
        enableReinitialize: true,
        validateOnMount: !formatId,
        validationSchema: Yup.object().shape({
            translations: Yup.object().shape({
                en: Yup.object().shape({
                    title: Yup.string().required(),
                }),
                ru: Yup.object().shape({
                    title: Yup.string().required(),
                }),
            }),
        }),
        onSubmit: (data) => {
            const format: IEditFormat = {
                id: formatId,
                isActive: !!formatId,
                translations: [
                    {
                        language: 'en',
                        title: data.translations.en.title!,
                    },
                    {
                        language: 'ru',
                        title: data.translations.ru.title!,
                    },
                ],
            };

            if (format.id) {
                formatMutation.mutate(format);
            } else {
                createFormatMutation.mutate(format);
            }
        },
    });

    const { isValid, handleBlur, handleSubmit, handleChange, values } = formik;

    return (
        <Modal
            label={formatId ? `Формат ${formatId}` : 'Создать новый формат'}
            onClose={handleClose}
            isOpened={isOpened}
        >
            <form onSubmit={handleSubmit}>
                <Div mb="20px">
                    <Input
                        label="EN Title"
                        name="translations.en.title"
                        id="translations.en.title"
                        placeholder="EN title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.translations?.en?.title || ''}
                        error={values?.translations?.en?.title === ''}
                    />
                </Div>
                <Div>
                    <Input
                        label="RU title"
                        id="translations.ru.title"
                        name="translations.ru.title"
                        placeholder="RU title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.translations?.ru?.title || ''}
                        error={values?.translations?.ru?.title === ''}
                    />
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        Save
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </form>
        </Modal>
    );
};

export default EditFormatModal;
