import { ICoursesOrderItem } from 'api-model';
import {
    DefaultButton,
    Div,
    DNDTable,
    Flex,
    PlainInput,
    PrimaryButton,
} from 'components';
import { updateCourseOrder } from 'mutations';
import { getCourses } from 'queries';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getTranslation } from 'utils';

import { queryClient } from '../../main';
import CourseForm from './CourseForm';
import DeleteCourseModal from './DeleteCourseModal';

const Courses = () => {
    const match: {
        path: string;
        params: { courseId?: string };
    } = useRouteMatch();
    const history = useHistory();
    const isFormOpen = match?.path === '/courses/:courseId';

    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);

    const [courseId, setCourseId] = useState<number | undefined>(
        match.params.courseId !== 'new'
            ? Number(match.params.courseId)
            : undefined,
    );

    useEffect(() => {
        if (courseId) {
            history.push(`/courses/${courseId}`);
        }
    }, [courseId]);

    const [isDeleteModalOpened, toggleDeleteModal] = useState(false);

    const columns = useMemo(() => {
        return [
            {
                Header: 'id',
                id: 'id',
                Cell: (data: any) => data.row.original.id || '',
                width: 30,
            },
            {
                Header: 'is active',
                id: 'isActive',
                Cell: (data: any) =>
                    data.row.original.isActive.toString() || '',
                width: 50,
            },
            {
                Header: 'name',
                id: 'name',
                Cell: (data: any) =>
                    getTranslation(data.row.original, 'ru')?.title || '',
                width: 235,
            },
            {
                Header: 'slug',
                id: 'slug',
                Cell: (data: any) => data.row.original.slug || '',
                width: 235,
            },
            {
                Header: () => null,
                id: 'contentButton',
                Cell: (data: any) => {
                    return (
                        <DefaultButton
                            px="10px"
                            onClick={() =>
                                history.push(
                                    `courses/${data.row.original.id}/content`,
                                )
                            }
                        >
                            Content
                        </DefaultButton>
                    );
                },
            },
            {
                Header: () => null,
                id: 'editButton',
                Cell: (data: any) => {
                    return (
                        <DefaultButton
                            px="10px"
                            onClick={() => handleEdit(data.row.original.id)}
                        >
                            Landing
                        </DefaultButton>
                    );
                },
            },
        ];
    }, []);

    const orderMutation = useMutation(
        (body: { order: ICoursesOrderItem[] }) => updateCourseOrder(body),
        {
            onSuccess: () => {
                setSuccess(true);
                queryClient.invalidateQueries('courses');
                setTimeout(() => {
                    setSuccess(false);
                }, 3000);
            },
            onError: () => {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3000);
            },
        },
    );

    const [page, setPage] = useState(1);
    // TODO: use previous page after reload
    const [size, setSize] = useState(10);

    const { data: courses } = useQuery(
        ['courses', page, size],
        () => getCourses(page, size),
        {
            keepPreviousData: true,
        },
    );

    const pageCount =
        courses?.pagination &&
        Math.ceil(courses.pagination.total / courses.pagination.size);

    const handleRowsMove = (order) => {
        orderMutation.mutate({ order: order });
    };

    const handleEdit = (courseId) => {
        setCourseId(courseId);
    };

    const handleToggleDeleteModal = () => {
        setCourseId(undefined);
        toggleDeleteModal(!isDeleteModalOpened);
    };

    const coursesSlugs = courses?.items?.map((course) => course.slug);

    return courses ? (
        <Div>
            {isFormOpen ? (
                <CourseForm
                    courseId={courseId}
                    slugs={coursesSlugs}
                    onClose={() => history.push('/courses')}
                />
            ) : (
                <>
                    <Flex
                        p="20px"
                        backgroundColor="white"
                        justifyContent="space-between"
                    >
                        <PlainInput variant="plain" placeholder="Search" />
                        <PrimaryButton
                            onClick={() => history.push('/courses/new')}
                        >
                            Add Course
                        </PrimaryButton>
                    </Flex>
                    <Div p="20px">
                        <DNDTable
                            columns={columns}
                            data={courses?.items as any[]}
                            onMove={handleRowsMove}
                            success={isSuccess}
                            error={isError}
                            setPage={setPage}
                            setSize={setSize}
                            controlledPageCount={pageCount}
                        />
                    </Div>
                    <DeleteCourseModal
                        courseId={courseId}
                        isOpened={isDeleteModalOpened}
                        onClose={handleToggleDeleteModal}
                    />
                </>
            )}
        </Div>
    ) : (
        <></>
    );
};

export default Courses;
