/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import { Flex, Text } from '.';
import { PlainButton } from './Buttons';
import Div from './Div';

const Button: React.FC<
    DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > & { active?: boolean }
> = ({ active, onClick, children, ...rest }) => (
    <PlainButton
        onClick={onClick}
        p="0"
        backgroundColor={active ? 'white' : 'inputGraphit'}
        color="white"
        width="50px"
        outline="none"
        border="none"
        cursor={active ? 'default' : 'pointer'}
        variant=""
        {...(active && {
            background: 'white',
            color: 'black',
        })}
        {...rest}
    >
        <Flex justifyContent="space-between" p="4px 8px" alignItems="center">
            <Div
                size="10px"
                borderRadius="100%"
                backgroundColor={active ? 'saladGreen' : 'white'}
            />
            <Text
                mt="3px"
                textTransform="uppercase"
                variant="service"
                color={active ? 'black' : 'white'}
            >
                {children}
            </Text>
        </Flex>
    </PlainButton>
);

const LanguageToggle = (props: {
    chosenLang: string;
    toggleChosenLang: (lang: string) => void;
    customLangs?: string[];
}) => {
    const { chosenLang, toggleChosenLang } = props;

    const toggleLang = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        toggleChosenLang(e.currentTarget.dataset.lang!);
    };
    const langs: string[] = props.customLangs || ['ru', 'en'];

    return (
        <Flex
            width={`${50 * langs.length}px`}
            borderRadius="150px"
            overflow="hidden"
        >
            {langs.map((lang) => (
                <Button
                    key={lang}
                    onClick={toggleLang}
                    data-lang={lang}
                    active={chosenLang === lang}
                >
                    {lang}
                </Button>
            ))}
        </Flex>
    );
};

export default LanguageToggle;
