import { Flex } from 'components';
import { SearchIcon } from 'components';
import debounce from 'lodash/debounce';
import React, {
    ChangeEvent,
    DetailedHTMLProps,
    InputHTMLAttributes,
    useRef,
    useState,
} from 'react';

import { IconClose } from '../Icons';
import PlainInput from './PlainInput';

interface ISearchInputProps {
    onClear?: (e: React.MouseEvent) => void;
    debounceTime?: number;
}

const SearchInput: React.FC<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
        ISearchInputProps
> = ({ onChange, value, debounceTime, onClear, ...props }) => {
    const [isInFocus, toggleInFocus] = useState(false);
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleFocusChange = () => {
        toggleInFocus(!isInFocus);
    };

    const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e);
    }, debounceTime);

    const isGotValue = value || (ref.current && ref.current.value) || isInFocus;

    return (
        <Flex
            width="fit-content"
            backgroundColor="white"
            pr={isGotValue ? 0 : '50px'}
        >
            <Flex
                p="20px 15px"
                color={isInFocus ? 'inputGraphitActive' : 'inputGraphit'}
            >
                <SearchIcon />
            </Flex>
            <PlainInput
                ref={ref}
                width="fit-content"
                fontSize={'14px'}
                p="16px 0px"
                placeholder="Search Input"
                variant="search"
                onFocus={handleFocusChange}
                onBlur={handleFocusChange}
                onChange={handleChange}
                defaultValue={value}
                {...props}
            />
            {isGotValue && (
                <Flex
                    alignItems="center"
                    onClick={onClear}
                    cursor="pointer"
                    p="17px 20px"
                >
                    <IconClose width="10px" height="10px" />
                </Flex>
            )}
        </Flex>
    );
};

SearchInput.defaultProps = {
    debounceTime: 300,
};

export default SearchInput;
