import { ITokens } from 'auth-model';
import pickBy from 'lodash/pickBy'
import assign from 'lodash/assign'

export function setTokens(tokens: ITokens) {

    if (tokens) {
        if (tokens.accessToken && tokens.refreshToken && tokens.expiresIn) {
            localStorage.setItem('access', tokens.accessToken);
            localStorage.setItem('refresh', tokens.refreshToken);
            localStorage.setItem(
                'expires',
                String(Math.ceil(Date.now() / 1000) + tokens.expiresIn),
            );
        }
    }
}

export function isTokenExpiring(timestamp: number) {
    const expirationDate = +new Date(+new Date() - 60000);

    return timestamp > expirationDate ? true : false;
}

export function clearTokens() {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('expires');
}
export function getTokens(): ITokens {
    return {
        accessToken: localStorage.getItem('access') ,
        refreshToken: localStorage.getItem('refresh') ,
        expiresIn: Number(localStorage.getItem('expires')),
        tokenType: 'bearer',
    };
}

export const getLocale = (_location?: any): string => {
    const l = _location || location;

    return `/${l.pathname.split('/')[1]}` || '';
};



export const filterByKeys = <T extends object, K extends keyof T, K2 extends keyof T[K]>(array: T[], keys:(K|Partial<Record<K, keyof K2>>)[],withActionCol?: boolean) => {
 return array.map((element) => {
     const item = pickBy(element, (_value, key)=>{
        return keys.find((objKey) => objKey.toString() === key)
    })
    return withActionCol ? assign({},item) : item
    })
}

export const getTranslation = (
    data: { translations: { [key: string]: any }[] },
    lang: string,
) => {
    return data?.translations?.find(
        (translation) => translation.language === lang,
    );
};

export const paginationsPageCount = (pagination: {size: number, total: number}): number => {
    return Math.ceil(
        pagination.total /
            pagination.size,
    );
}
