import { DenseTable, Div, Flex, PrimaryButton, TableActions } from 'components';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getLocations } from 'src/api/queries';
import { filterByKeys } from 'src/utils';

import DeleteLocationModal from './DeleteLocationModal';
import EditLocationModal from './EditLocationModal';

const Locations = () => {
    const [idForModal, setIdForModal] = useState<number | undefined>(undefined);
    const [isModalOpened, toggleModal] = useState(false);
    const [isDeleteModalOpened, toggleDeleteModal] = useState(false);
    const columns = useMemo(() => {
        return [
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: 'name',
                accessor: 'name',
            },
            {
                Header: () => null,
                id: 'actions',
                Cell: (data: any) => {
                    return (
                        <TableActions
                            actions={[
                                {
                                    callBack: () => {
                                        handleEdit(data.row.values.id);
                                    },
                                    label: 'Edit',
                                },
                                {
                                    callBack: () =>
                                        handleDelete(data.row.values.id),
                                    label: 'Delete',
                                },
                            ]}
                        />
                    );
                },
            },
        ];
    }, []);
    const [page, setPage] = useState(1);
    const { data: locations } = useQuery(
        ['locations', page],
        () => getLocations(page),
        {
            keepPreviousData: true,
        },
    );
    const pageCount =
        locations?.pagination &&
        Math.ceil(locations.pagination.total / locations.pagination.size);
    const filteredLocations = locations?.items
        ? filterByKeys(locations.items, ['id', 'name'], true)
        : [];
    const handleEdit = (id: number) => {
        setIdForModal(id);
        toggleModal(!isModalOpened);
    };
    const handleDelete = (id: number | undefined) => {
        setIdForModal(id);
        toggleDeleteModal(!isModalOpened);
    };
    const handleToggleEditModal = () => {
        setIdForModal(undefined);
        toggleModal(!isModalOpened);
    };
    const handleToggleDeleteModal = () => {
        setIdForModal(undefined);
        toggleDeleteModal(!isDeleteModalOpened);
    };

    return (
        <Div>
            <Flex
                boxSizing="border-box"
                size="100%"
                backgroundColor="white"
                p="20px"
                justifyContent="center"
                flexDirection="column"
                alignItems="end"
            >
                <PrimaryButton onClick={handleToggleEditModal}>
                    Add Locations
                </PrimaryButton>
            </Flex>
            <DenseTable
                setPage={setPage}
                controlledPageCount={pageCount}
                columns={columns}
                data={filteredLocations}
            />
            <EditLocationModal
                locationId={idForModal}
                isOpened={isModalOpened}
                onClose={handleToggleEditModal}
            />
            <DeleteLocationModal
                locationId={idForModal}
                isOpened={isDeleteModalOpened}
                onClose={handleToggleDeleteModal}
            />
        </Div>
    );
};

export default Locations;
