import React from 'react';
import { useMutation } from 'react-query';
import { deleteCourse } from 'src/api/mutations';
import {
    DefaultButton,
    Flex,
    Modal,
    PrimaryButton,
    Text,
} from 'src/components';

import { queryClient } from '../../main';

const DeleteCourseModal: React.FC<{
    courseId: number | undefined;
    onClose: () => void;
    isOpened: boolean;
}> = ({ courseId, onClose, isOpened }) => {
    const deleteCourseMutation = useMutation(
        (courseId: number) => deleteCourse(courseId),
        {
            onSuccess: () => {
                onClose();
                queryClient.invalidateQueries('courses');
            },
        },
    );
    const handleDelete = () => {
        courseId && deleteCourseMutation.mutate(courseId);
    };

    return (
        <Modal
            label={`Удаление курса ${courseId}`}
            onClose={onClose}
            isOpened={isOpened}
        >
            <Text variant="body">
                Вы уверены, что хотите удалить курс {courseId} ?
            </Text>
            <Flex mt="40px">
                <PrimaryButton onClick={handleDelete} mr="20px" isBig>
                    Delete
                </PrimaryButton>
                <DefaultButton onClick={onClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </Modal>
    );
};

export default DeleteCourseModal;
