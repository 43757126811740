/* eslint-disable prettier/prettier */
import {
    CheckBox,
    DatePicker,
    DefaultButton,
    Div,
    DotsMenu,
    FileInput,
    FilePreview,
    Flex,
    Input,
    Modal,
    PrimaryButton,
    SearchInput,
    Select,
    Text,
} from 'components';
import dayjs from 'dayjs'
import React, { useState } from 'react';

const ComponentsExample = () => {
    const [isCheckbox, checkBox] = useState(false);
    const [isModalOpened, toggleModal] = useState(false);

    // file input mocks
    const [isFileInputLoading, setFileInputLoading] = useState(false);
    const [fileInputProgress, setFileInputProgress] = useState(0);
    const [fileInputData, setFileInputData] = useState<any>(); 

    function handleFileInputChange(files: FileList) {
        setFileInputLoading(true)
        setFileInputData(files)
    }
    //

    // dropdown props
    const [dropdownValue, setDropdownValue] = useState<string | undefined>();

    return (
        <Flex flexDirection="column" flex="1">
            <Text variant="body">body1</Text>
            <Text variant="bodyFugue">bodyFugue</Text>
            <DefaultButton>Default Button</DefaultButton>
            <DatePicker/>
            <br />
            <PrimaryButton>Primary Button</PrimaryButton>
            <br />
            <DefaultButton isBig>Default Button Big</DefaultButton>
            <br />
            <PrimaryButton isBig>Primary Button Big</PrimaryButton>
            <br />
            <CheckBox
                label="Checkbox"
                checked={isCheckbox}
                onChange={() => checkBox(!isCheckbox)}
            />
            <br />
            <label>
                <Text variant="body" mb="5px">Label</Text>
                <Input placeholder="Input" />
            </label>
            <Flex alignItems='center'>
                <Select 
                    value={dropdownValue}
                    placeholder="Select value"
                    onChange={(event)=>setDropdownValue((event.target as HTMLInputElement).value)}
                    >
                     <Select.Option value={1}>sss</Select.Option>   
                     <Select.Option value={12}>wwwsss</Select.Option>   
                    </Select>
                <Text variant="body">{`  VALUE: ${dropdownValue}`}</Text>
            </Flex>
            <Div padding='20px' marginBottom='30px' backgroundColor='white'>
                <DotsMenu 
                    actions={[
                        {
                            title: 'Edit',
                            onClick: () => alert('Edit')
                        }, {
                            title: 'Delete',
                            onClick: () => alert('Delete')
                        }, {
                            title: 'Another',
                            onClick: () => alert('Another')
                        }, {
                            title: 'Another one',
                            onClick: () => alert('Another one')
                        }, {
                            title: 'Last',
                            onClick: () => alert('Last')
                        }, 
                        ]}
                    />
            </Div>
            <Div padding='20px' marginBottom='30px' backgroundColor='white'>
                <FilePreview
                    title='Test title'
                    files={{
                        title: '001_test_title_file.pdf',
                        uploadedAt: dayjs().toDate(),
                        url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                    }} 
                    actions={[
                        {
                            title: 'Edit',
                            onClick: () => alert('Edit')
                        }, {
                            title: 'Delete',
                            onClick: () => alert('Delete')
                        } 
                        ]}
                    />
                <FilePreview
                    title='Test title'
                    actions={[
                        {
                            title: 'Edit',
                            onClick: () => alert('Edit')
                        }, {
                            title: 'Delete',
                            onClick: () => alert('Delete')
                        } 
                        ]}
                    files={[
                        {
                            title: '001_test_title_file.pdf',
                            uploadedAt: dayjs().toDate(),
                            url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                        }, {
                            title: '002_test_title_file.pdf',
                            uploadedAt: dayjs().toDate(),
                            url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                        }, {
                            title: 'forest.jpeg',
                            uploadedAt: dayjs().toDate(),
                            url: 'https://static.themoscowtimes.com/image/1360/67/mountains-3741382_1920.jpg',
                            preview: 'https://static.themoscowtimes.com/image/1360/67/mountains-3741382_1920.jpg'
                        }, 
                        ]} 
                    />
            </Div>
            <Div p='20px' backgroundColor='white'>
                <button onClick={() => setFileInputLoading(!isFileInputLoading)}>change input loading state</button>
                <button onClick={() => setFileInputProgress(Math.round(Math.random() * 100))}>change input progress state</button>
                <FileInput 
                    id='testFileInput'
                    value={fileInputData}
                    title='Upload example'
                    progress={fileInputProgress}
                    isLoading={isFileInputLoading}
                    onChange={handleFileInputChange}
                    onCancel={() => setFileInputLoading(false)}
                    />
            </Div>
            <Input isBlack placeholder="Input Black" />
            <PrimaryButton onClick={() => toggleModal(!isModalOpened)} isBig>
                Open Modal
            </PrimaryButton>
            <br/>
            <SearchInput placeholder="Search Input" />
          
            <Modal
                label="Test modal"
                onClose={() => toggleModal(!isModalOpened)}
                isOpened={isModalOpened}
            >
                <Div mb="20px">
                    <label>
                        <Text variant="body">Some</Text>
                    </label>
                    <Input placeholder="Some" />
                </Div>
                <Div mb="20px">
                    <label>
                        <Text variant="body">Test</Text>
                    </label>
                    <Input placeholder="Test" />
                </Div>
                <Div mb="20px">
                    <label>
                        <Text variant="body">Inputs</Text>
                    </label>
                    <Input placeholder="Inputs" />
                </Div>
            </Modal>
        </Flex>
    );
}

export default ComponentsExample
