import styled from '@emotion/styled';
import { VariantProperty } from '@styled-system/css';
import {
    createShouldForwardProp,
    props,
} from '@styled-system/should-forward-prop';
import * as CSS from 'csstype';
import {
    background,
    BackgroundProps,
    border,
    BorderProps,
    buttonStyle,
    color,
    ColorProps,
    compose,
    layout,
    LayoutProps,
    position,
    PositionProps,
    ResponsiveValue,
    ShadowProps,
    space,
    SpaceProps,
    system,
} from 'styled-system';

type CustomProps = {
    boxSizing?: ResponsiveValue<CSS.Property.BoxSizing>;
    boxShadow?: ResponsiveValue<CSS.Property.BoxShadow>;
    cursor?: ResponsiveValue<CSS.Property.Cursor>;
    outline?: ResponsiveValue<CSS.Property.Outline>;
    transform?: ResponsiveValue<CSS.Property.Transform>;
    hoverBackgroundColor?: CSS.Property.BackgroundColor;
};

export type ButtonProps = SpaceProps &
    ColorProps &
    LayoutProps &
    BackgroundProps &
    BorderProps &
    PositionProps &
    ShadowProps &
    VariantProperty &
    CustomProps;

const shouldForwardProp = createShouldForwardProp([...props, 'cursor']);

const PlainButton = styled('button', { shouldForwardProp })<ButtonProps>(
    buttonStyle,
    compose(layout, space, color, position, background, border),
    system({
        boxSizing: {
            property: 'boxSizing',
        },
        boxShadow: {
            property: 'boxShadow',
        },
        cursor: {
            property: 'cursor',
        },
        transform: {
            property: 'transform',
        },
        outline: {
            property: 'outline',
        },
    }),
    ({ hoverBackgroundColor }) => ({
        '&:hover': {
            backgroundColor: hoverBackgroundColor,
        },
    }),
);

export default PlainButton;
