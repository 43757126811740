import styled from '@emotion/styled';
import { VariantProperty } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import * as CSS from 'csstype';
import {
    background,
    BackgroundProps,
    border,
    BorderProps,
    color,
    ColorProps,
    compose,
    layout,
    LayoutProps,
    position,
    PositionProps,
    ResponsiveValue,
    ShadowProps,
    space,
    SpaceProps,
    system,
    typography,
    TypographyProps,
    variant,
} from 'styled-system';

type CustomProps = {
    outline?: ResponsiveValue<CSS.Property.Outline>;
    boxSizing?: ResponsiveValue<CSS.Property.BoxSizing>;
};

export type InputProps = SpaceProps &
    ColorProps &
    LayoutProps &
    BackgroundProps &
    BorderProps &
    PositionProps &
    ShadowProps &
    VariantProperty &
    TypographyProps &
    CustomProps;

const PlainInput = styled('input', { shouldForwardProp })<InputProps>(
    compose(typography, layout, space, color, position, background, border),
    {
        fontFamily: 'Universe',
        '&:focus': { outline: 'none' },
        '&::-moz-focus-inner': { border: 0 },
    },
    system({
        boxSizing: {
            property: 'boxSizing',
        },
    }),
    variant({
        variants: {
            plain: {},
            error: {
                borderColor: 'rgba(255, 0, 0, 0.4)',
                '&:focus': {
                    borderColor: 'rgba(255, 0, 0, 1)',
                },
                outline: 'none',
            },
            default: {
                backgroundColor: 'white',
                color: 'black',
                borderColor: '#DCDDE2',
                outline: 'none',
            },
            black: {
                backgroundColor: 'black',
                color: 'white',
                borderColor: 'rgba(255, 255, 255, 0.4)',
                '&:focus': {
                    borderColor: 'rgba(255, 255, 255, 1)',
                },
                outline: 'none',
            },
            search: {
                outline: 'none',
                border: 0,
                color: 'inputGraphit',
            },
        },
    }),
);

export default PlainInput;
