import {
    Div,
    Flex,
    Img,
    LanguageToggle,
    PrimaryButton,
    TableActions,
    WideTable,
} from 'components';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getEvents } from 'src/api/queries';
// import { IEvent } from 'src/model/types/api';
import { filterByKeys } from 'src/utils';

import DeleteEventModal from './DeleteEventModal';
import EditEventModal from './EditEventModal';

const Events = () => {
    const [idForModal, setIdForModal] = useState<number | undefined>(undefined);
    const [isModalOpened, toggleModal] = useState(false);
    const [isDeleteModalOpened, toggleDeleteModal] = useState(false);
    const [chosenLang, setChosenLang] = useState('en');

    const toggleChosenLang = (lang: string) => {
        setChosenLang(lang);
    };

    const getTranslation = (data: any, lang: string) => {
        return data?.translations?.find(
            (translation: any) => translation.language === lang,
        );
    };

    const columns = useMemo(() => {
        return [
            {
                Header: 'cover',
                accessor: 'customProgrammeImage',
                Cell: (data: any) => {
                    return (
                        <Img
                            maxWidth="100px"
                            src={
                                data.row.values.customProgrammeImage ||
                                data.row.values.programme.cover
                            }
                        />
                    );
                },
            },
            {
                Header: 'title',
                accessor: 'slug',
                Cell: (data: any) => {
                    return (
                        getTranslation(data.row.values, chosenLang)?.title ||
                        'no data'
                    );
                },
            },
            {
                Header: 'chosenLanguage',
                accessor: 'translations',
                Cell: (data: any) => {
                    return (
                        getTranslation(data.row.values, chosenLang)
                            ?.eventLanguage || 'no data'
                    );
                },
            },
            {
                Header: 'location',
                accessor: 'location',
                Cell: (data: any) => {
                    return data.row.original.isOnline
                        ? 'online'
                        : getTranslation(data.row.values.location, chosenLang)
                              ?.title || 'no data';
                },
            },
            {
                Header: 'price',
                accessor: 'price',
            },
            {
                Header: 'program',
                accessor: 'programme',
                Cell: (data: any) => {
                    return (
                        getTranslation(data.row.values.programme, chosenLang)
                            ?.title || 'no data'
                    );
                },
            },
            {
                Header: 'date start',
                accessor: 'dateStart',
                Cell: (data: any) => {
                    return new Date(
                        data.row.values.dateStart,
                    ).toLocaleDateString('ru-RU', {
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        timeZone: 'UTC',
                    });
                },
            },
            {
                Header: 'format',
                accessor: 'format',
                Cell: (data: any) => {
                    return (
                        getTranslation(data.row.values.format, chosenLang)
                            ?.title || 'no data'
                    );
                },
            },
            {
                Header: () => null,
                id: 'actions',
                Cell: (data: any) => {
                    return (
                        <TableActions
                            actions={[
                                {
                                    callBack: () => {
                                        handleEdit(data.row.original.id);
                                    },
                                    label: 'Edit',
                                },
                                {
                                    callBack: () =>
                                        handleDelete(data.row.original.id),
                                    label: 'Delete',
                                },
                            ]}
                        />
                    );
                },
            },
        ];
    }, [chosenLang]);
    const { data: events } = useQuery('events', getEvents);

    const slugs = events?.map((event) => event.slug);

    const filteredEvents = events
        ? filterByKeys(
              events,
              [
                  'id',
                  'programme',
                  'customProgrammeImage',
                  'price',
                  'location',
                  'slug',
                  'dateStart',
                  'format',
                  'isOnline',
                  'translations',
              ],
              true,
          )
        : [];

    const handleEdit = (id: number) => {
        setIdForModal(id);
        toggleModal(!isModalOpened);
    };
    const handleDelete = (id: number | undefined) => {
        setIdForModal(id);
        toggleDeleteModal(!isModalOpened);
    };
    const handleToggleEditModal = () => {
        setIdForModal(undefined);
        toggleModal(!isModalOpened);
    };
    const handleToggleDeleteModal = () => {
        setIdForModal(undefined);
        toggleDeleteModal(!isDeleteModalOpened);
    };

    return (
        <Div>
            <Flex
                boxSizing="border-box"
                size="100%"
                backgroundColor="white"
                p="20px"
                justifyContent="center"
                flexDirection="column"
                alignItems="end"
            >
                <PrimaryButton onClick={handleToggleEditModal}>
                    Add Event
                </PrimaryButton>
            </Flex>
            {toggleChosenLang && (
                <Flex justifyContent="flex-end" p="20px 20px 0 0">
                    <LanguageToggle
                        chosenLang={chosenLang}
                        toggleChosenLang={toggleChosenLang}
                    />
                </Flex>
            )}
            <WideTable columns={columns} data={filteredEvents} />
            <EditEventModal
                eventId={idForModal}
                isOpened={isModalOpened}
                onClose={handleToggleEditModal}
                slugs={slugs}
                lang={chosenLang}
            />
            <DeleteEventModal
                eventId={idForModal}
                isOpened={isDeleteModalOpened}
                onClose={handleToggleDeleteModal}
            />
        </Div>
    );
};

export default Events;
