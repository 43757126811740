import styled from '@emotion/styled';
import { Div, Text } from 'components';
import React from 'react';

const StatusIconWrapper = styled(Div)({
    position: 'relative',
    '&:hover': {
        'p:last-child': {
            display: 'block',
        },
    },
});

type StatusIconProps = {
    status: string;
    backgroundColor: string;
    lang: string;
};

export const StatusIcon = (props: StatusIconProps) => {
    return (
        <StatusIconWrapper>
            <Text
                variant="body"
                mr="10px"
                height="fit-content"
                backgroundColor={props.backgroundColor}
                color="white"
                p="3px 7px 1px"
                borderRadius="2px"
                textTransform="uppercase"
                cursor="pointer"
            >
                {props.lang}
            </Text>
            <Text
                position="absolute"
                opacity="0.5"
                display="none"
                variant="body"
                color="white"
                mt="5px"
                backgroundColor={props.backgroundColor}
                p="5px 8px"
                whiteSpace="nowrap"
                borderRadius="3px"
            >
                {props.status}
            </Text>
        </StatusIconWrapper>
    );
};

type StatusButtonsProps = {
    value: string;
};

export const StatusPanel = (props: StatusButtonsProps) => {
    return (
        <Div>
            <Text variant="body" m="30px 0 10px">
                Status
            </Text>
            <Text
                variant="body"
                color="white"
                backgroundColor={
                    props.value === 'active' ? 'saladGreen' : 'statusButtonGrey'
                }
                width="100%"
                padding="10px 0"
                textAlign="center"
                mb="5px"
            >
                On index
            </Text>
            <Text
                variant="body"
                color="white"
                backgroundColor={
                    props.value === 'onHold' ? 'grey' : 'statusButtonGrey'
                }
                width="100%"
                padding="10px 0"
                textAlign="center"
                mb="5px"
            >
                Waiting
            </Text>
            <Text
                variant="body"
                color="white"
                backgroundColor={
                    props.value === 'finished' ? 'red' : 'statusButtonGrey'
                }
                width="100%"
                padding="10px 0"
                textAlign="center"
            >
                Not active
            </Text>
        </Div>
    );
};
