const breakpoints: any = ['768px', '1280px', '1600px'];


const colors = {
    white: '#ffffff',
    solidBlue: '#006AFF',
    solidBlueHover: '#2F85FF',
    lightBlue: '#CCE1FF',
    graphit: '#22252C',
    lightGraphit: '#272B32',
    inputGraphit: '#647282',
    inputGraphitActive: '#C1C7CD',
    graphitBlue: 'rgba(44, 60, 79, 0.4)',
    grey: '#4C515A',
    lightGrey: '#E6E8EB',
    darkGrey: '#808080',
    textCaptionGrey: '#7F8A98',
    menuGrey: '#EEF2F3',
    filePreviewIconColor: '#F7F9FA',
    dotsGrey: '#D7DBDF',
    authorsSuggestionsColor: '#9FA8B2',
    authorsSuggestionsBackgroundColor: '#EFF2F3',
    saladGreen: '#31EB7B',
    black: '#262626',
    red: '#FC5151',
    bannerStatusGrey: '#BCC3CA',
    statusButtonGrey: '#DCDDE2'
}

export default {
    breakpoints,
    colors,
    buttons: {
        default: {
            outline:'none',
            borderColor: colors.solidBlue,
            cursor:'pointer',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: colors.lightBlue
            }
        },
        primary: {
            outline:'none',
            cursor:'pointer',
            borderColor: colors.solidBlue,
            backgroundColor: colors.solidBlue,
            '&:hover': {
              backgroundColor: colors.solidBlueHover
            }
          },
        primaryDisabled: {
            cursor: 'not-allowed',
            outline:'none',
            borderColor: colors.solidBlue,
            opacity:0.4,
            backgroundColor: colors.solidBlue,
        },
    },
    fontSizes: [12, 25],
    textStyles: {
        h3: {
            fontSize: ['12px', '33px'],
        },
    },
};