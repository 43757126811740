/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-mde/lib/styles/css/react-mde-all.css';

import styled from '@emotion/styled';
import { ICourseItem } from 'api-model';
import {
    Alert,
    AuthorsInput,
    CheckBox,
    DefaultButton,
    Div,
    FileInput,
    Flex,
    Grid,
    IconClose,
    Input,
    MultiSelectInput,
    PrimaryButton,
    Select,
    SponsorsInput,
    Text,
} from 'components';
import { useFormik } from 'formik';
import { IFormik } from 'formik-model';
import { createCourse, updateCourse, uploadCourseCover } from 'mutations';
import {
    getCourse,
    getPersons,
    getProgrammes,
    getRoles,
    getSponsors,
    getTopics,
} from 'queries';
import React, { Fragment, useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import * as Showdown from 'showdown';
import slugify from 'slugify';
import { getTranslation } from 'utils';
import * as Yup from 'yup';

import { queryClient } from '../../main';

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

const availableOnProjectsOptions = ['archrf', 'strelka'];
const bulletsInitalValues = ['', ''];

interface ICourseFormProps {
    courseId: number | undefined;
    slugs: any;
    onClose: any;
}

const Form = styled.form({
    padding: '20px',
});

const CourseForm = (props: ICourseFormProps): JSX.Element => {
    const { courseId, slugs, onClose } = props;
    const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>(
        'write',
    );

    const { data: course } = useQuery(
        ['courses', courseId],
        () => getCourse(courseId!),
        { enabled: !!courseId },
    );

    const { data: programmes } = useQuery('programmes', getProgrammes);
    const { data: topics } = useQuery('topics', () =>
        getTopics('pagination[size]=1000'),
    );
    const { data: sponsors } = useQuery('sponsors', async () => {
        const data = await getSponsors();

        return data;
    });

    const { data: persons } = useQuery('persons', async () => {
        const data = await getPersons();

        return data;
    });
    const { data: roles } = useQuery('roles', getRoles);

    const [isSaved, setSaved] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isFileInputLoading, setFileInputLoading] = useState(false);
    const [fileInputProgress, setFileInputProgress] = useState(0);
    const [fileInputData, setFileInputData] = useState<
        (string & FileList) | undefined
    >();

    function handleFileInputChange(file: FileList) {
        setFileInputLoading(true);
        setFileInputProgress(100);
        setFileInputData(file as (string & FileList) | undefined);
        coverMutation.mutate(file, {
            onSuccess: (value) => {
                formik.setFieldValue('cover', value);
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        });
    }

    function handleFileInputCancel() {
        setFileInputLoading(false);
        setFileInputProgress(0);
        setFileInputData(undefined);
        formik.setFieldValue('cover', undefined);
    }

    const coverMutation = useMutation((fileInputData: FileList) =>
        uploadCourseCover(fileInputData),
    );

    const createCourseMutation = useMutation(
        (course: any) => createCourse(course),
        {
            onSuccess: () => {
                formik.resetForm();
                setSaved(false);
                onClose();
                queryClient.invalidateQueries('courses');
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );
    const courseMutation = useMutation((course: any) => updateCourse(course), {
        onSuccess: () => {
            setSaved(true);
            queryClient.invalidateQueries('courses');
        },
        onError: (error: any) => {
            setError(true);
            setErrorMessage(Object.values(error.response.data).join(' - '));
            setTimeout(() => {
                setError(false);
                setErrorMessage('');
            }, 5000);
        },
    });

    const formik: IFormik = useFormik({
        initialValues: {
            id: courseId!,
            slug: course?.slug,
            availableOnProjects: course?.availableOnProjects,
            isActive: Boolean(course?.isActive),
            programmeId: course?.programmeId,
            topics: course?.topics
                ? topics?.items
                      ?.filter((topic) =>
                          course?.topics
                              ?.map((selectedTopic) => selectedTopic.id)
                              .includes(topic.id),
                      )
                      .map((topic) => getTranslation(topic, 'ru')?.title)
                : undefined,
            sponsors: course?.sponsors?.map((sponsor) => sponsor.id),
            persons: course?.persons.map((person) => ({
                id: person.id || person.personId,
                roleId: person.roleId,
            })),
            cover: course?.cover,
            title: course && getTranslation(course, 'ru')?.title,
            subtitle: course && getTranslation(course, 'ru')?.subtitle,
            description: course
                ? converter.makeMarkdown(
                      getTranslation(course, 'ru')?.description || '',
                  )
                : '',
            blocks: {
                courseForWhom:
                    (course &&
                        getTranslation(course, 'ru')?.blocks?.find(
                            (block) => block.type === 'courseForWhom',
                        )?.bullets) ||
                    bulletsInitalValues,
                courseWhatYouGet:
                    (course &&
                        getTranslation(course, 'ru')?.blocks.find(
                            (block) => block.type === 'courseWhatYouGet',
                        )?.bullets) ||
                    bulletsInitalValues,
                videoUrl:
                    (course &&
                        getTranslation(course, 'ru')?.blocks.find(
                            (block) => block.type === 'video',
                        )?.videoUrl) ||
                    '',
            },
        },

        enableReinitialize: true,
        validateOnMount: !courseId,
        validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            description: Yup.string().required(),
            subtitle: Yup.string().required(),
            slug: Yup.string()
                .test('slug', 'Slug already exist', (value) =>
                    value
                        ? !slugs
                              ?.filter(
                                  (slug) => slug !== formik.initialValues.slug,
                              )
                              .includes(value)
                        : true,
                )
                .min(1)
                .max(255)
                .required(),
            programmeId: Yup.number().min(1).integer().required(),
            cover: Yup.string().required(),
            isActive: Yup.boolean().required(),
            availableOnProjects: Yup.array()
                .of(Yup.string().required())
                .required('Available on projects is a required field'),
            topics: Yup.array(),
            sponsors: Yup.array().of(Yup.number().integer()),
            persons: Yup.array(),
        }),
        onSubmit: (data) => {
            const course: ICourseItem = {
                id: courseId!,
                isActive: data.isActive!,
                slug: data.slug!,
                availableOnProjects: data.availableOnProjects!,
                cover: data.cover!,
                programmeId: data.programmeId!,
                topics: topics?.items
                    .filter((topic) =>
                        data?.topics?.includes(
                            getTranslation(topic, 'ru')?.title,
                        ),
                    )
                    .map((topic) => ({ id: topic.id })),
                sponsors:
                    sponsors?.items
                        .filter((sponsor) =>
                            data?.sponsors?.includes(sponsor.id),
                        )
                        .map((sponsor) => ({ id: sponsor.id })) || [],
                persons: data.persons! || [],
                translations: [
                    {
                        language: 'ru',
                        title: data.title,
                        subtitle: data.subtitle,
                        description: converter.makeHtml(data.description!),
                        blocks: [
                            {
                                type: 'courseForWhom',
                                bullets: data.blocks.courseForWhom!,
                            },
                            {
                                type: 'courseWhatYouGet',
                                bullets: data.blocks.courseWhatYouGet!,
                            },
                            {
                                ...(data.blocks.videoUrl && {
                                    type: 'video',
                                    videoUrl: data.blocks.videoUrl!,
                                }),
                            },
                        ].filter((obj) => Object.keys(obj).length !== 0),
                    },
                ],
            };

            if (fileInputData) {
                coverMutation.mutate(fileInputData, {
                    onSuccess: (value) => {
                        course.cover = value;
                        submitCourse(course);
                    },
                    onError: (error: any) => {
                        setError(true);
                        setErrorMessage(
                            Object.values(error.response.data).join(' - '),
                        );
                        setTimeout(() => {
                            setError(false);
                            setErrorMessage('');
                        }, 5000);
                    },
                });
            } else {
                submitCourse(course);
            }
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const submitCourse = (course: any) => {
        if (course.id) {
            courseMutation.mutate(course);
        } else {
            createCourseMutation.mutate(course);
        }
    };

    const handleChangeWithSlug = (course) => {
        setFieldValue('slug', slugify(course.target.value.toLowerCase()));
        handleChange(course);
    };
    const handleChangeBlock = (block, index, blockType) => {
        setFieldValue(`blocks.${blockType}[${index}]`, block.target.value);
    };
    const handleAddBlock = (blockType) => {
        setFieldValue(`blocks.${blockType}`, [...values.blocks[blockType], '']);
    };
    const handleDeleteBlock = (index, blockType) => {
        setFieldValue(`blocks.${blockType}`, [
            ...values.blocks[blockType].filter((_, _index) => index !== _index),
        ]);
    };

    const topicsTitles = topics?.items.map(
        (topic) => getTranslation(topic, 'ru')?.title,
    );

    return (
        <Form onSubmit={handleSubmit}>
            {isError && <Alert message={errorMessage} />}
            <Flex width="100%" flex="1" justifyContent="center">
                <Text
                    variant="h4"
                    fontSize="16px"
                    textTransform="uppercase"
                    mb="20px"
                    ml="auto"
                >
                    {courseId ? values?.title : 'new course'}
                </Text>
                <Flex ml="auto" onClick={onClose}>
                    <IconClose stroke="#22252C" />
                </Flex>
            </Flex>
            <Div
                backgroundColor="white"
                borderRadius="2px"
                boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                gridColumn="1/3"
                pb="20px"
            >
                <Div px="20px">
                    <CheckBox
                        label={'Активен?'}
                        name={`isActive`}
                        id={`isActive`}
                        checked={values.isActive}
                        onChange={handleChange}
                    />
                </Div>
                <Div px="20px">
                    <Input
                        label={'Заголовок'}
                        name={`title`}
                        id={`title`}
                        placeholder={'Заголовок'}
                        onChange={handleChangeWithSlug}
                        onBlur={handleBlur}
                        value={values.title || ''}
                        error={!!errors.title}
                        width="100%"
                    />
                    {errors.title && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.title}
                        </Text>
                    )}
                </Div>
                <Div px="20px">
                    <Input
                        label="Slug"
                        name="slug"
                        id="slug"
                        placeholder="Slug"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.slug}
                        error={!!errors.slug}
                        width="100%"
                    />
                    {errors.slug && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.slug}
                        </Text>
                    )}
                </Div>
                <Div px="20px">
                    <Text variant="body" mt="20px" mb="10px">
                        {'Лид'}
                    </Text>
                    <ReactMde
                        value={values.subtitle || ''}
                        onChange={handleChange(`subtitle`)}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        toolbarCommands={[]}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(markdown)
                        }
                    />
                    {errors.subtitle && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.subtitle}
                        </Text>
                    )}
                </Div>
                <Div px="20px">
                    <Text variant="body" mt="20px" mb="10px">
                        {'Описание'}
                    </Text>
                    <ReactMde
                        value={values.description || ''}
                        onChange={handleChange(`description`)}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        toolbarCommands={[['bold', 'italic', 'link']]}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(converter.makeHtml(markdown))
                        }
                    />
                    {errors.description && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.description}
                        </Text>
                    )}
                </Div>
                <Grid my="10px" gridGap="10px" px="20px">
                    <Text mt="10px" variant="body">
                        Для кого этот курс
                    </Text>
                    {values.blocks.courseForWhom.map((bullet, index) => (
                        <Fragment key={index}>
                            <Flex>
                                <Input
                                    name={`blocks.courseForWhom`}
                                    id={`blocks.courseForWhom`}
                                    placeholder={'Для кого этот курс'}
                                    onChange={(event) =>
                                        handleChangeBlock(
                                            event,
                                            index,
                                            'courseForWhom',
                                        )
                                    }
                                    onBlur={handleBlur}
                                    value={bullet || ''}
                                    error={bullet === ''}
                                    width="100%"
                                />
                                {index > 1 && (
                                    <Flex
                                        p="20px 0 20px 15px"
                                        cursor="pointer"
                                        onClick={() =>
                                            handleDeleteBlock(
                                                index,
                                                'courseForWhom',
                                            )
                                        }
                                    >
                                        <IconClose />
                                    </Flex>
                                )}
                            </Flex>
                            {bullet === '' && index <= 1 && (
                                <Text variant="body" color="red" mb="10px">
                                    Заполнить минимум два пункта
                                </Text>
                            )}
                        </Fragment>
                    ))}
                    <DefaultButton
                        onClick={() => handleAddBlock('courseForWhom')}
                    >
                        Добавить
                    </DefaultButton>
                </Grid>
                <Grid my="10px" gridGap="10px" px="20px">
                    <Text mt="10px" variant="body">
                        Что вы получите
                    </Text>
                    {values.blocks.courseWhatYouGet.map((bullet, index) => (
                        <Fragment key={index}>
                            <Flex>
                                <Input
                                    name={`blocks.courseWhatYouGet`}
                                    id={`blocks.courseWhatYouGet`}
                                    placeholder={'Что вы получите'}
                                    onChange={(event) =>
                                        handleChangeBlock(
                                            event,
                                            index,
                                            'courseWhatYouGet',
                                        )
                                    }
                                    onBlur={handleBlur}
                                    value={bullet || ''}
                                    error={bullet === ''}
                                    width="100%"
                                />
                                {index > 1 && (
                                    <Flex
                                        p="20px 0 20px 15px"
                                        cursor="pointer"
                                        onClick={() =>
                                            handleDeleteBlock(
                                                index,
                                                'courseWhatYouGet',
                                            )
                                        }
                                    >
                                        <IconClose />
                                    </Flex>
                                )}
                            </Flex>
                            {bullet === '' && index <= 1 && (
                                <Text variant="body" color="red" mb="10px">
                                    Заполнить минимум два пункта
                                </Text>
                            )}
                        </Fragment>
                    ))}
                    <DefaultButton
                        onClick={() => handleAddBlock('courseWhatYouGet')}
                    >
                        Добавить
                    </DefaultButton>
                </Grid>
                <Div px="20px" mb="20px">
                    <Input
                        label={'Ссылка на видео'}
                        name={`blocks.videoUrl`}
                        id={`blocks.videoUrl`}
                        placeholder={'Ссылка на видео'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.blocks.videoUrl || ''}
                        width="100%"
                    />
                </Div>
                <Div px="20px" mb="20px">
                    <Select
                        id="programmeId"
                        name="programmeId"
                        label="Programme"
                        placeholder="Select programme"
                        value={values?.programmeId}
                        valueTitle={
                            programmes?.items.find(
                                (programme) =>
                                    values?.programmeId === programme.id,
                            )?.title
                        }
                        onChange={handleChange}
                        minWidth="50%"
                    >
                        {programmes?.items.map((programme) => (
                            <Select.Option
                                key={programme.id}
                                value={programme.id}
                            >
                                {programme.title}
                            </Select.Option>
                        ))}
                    </Select>
                    {errors?.programmeId && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.programmeId}
                        </Text>
                    )}
                </Div>
                <Div px="20px" mb="20px">
                    <Text variant="body" mb="10px">
                        Topics
                    </Text>
                    <MultiSelectInput
                        id="topics"
                        name="topics"
                        value={values?.topics}
                        suggestions={topicsTitles}
                        placeholder="Select topics"
                        onChange={handleChange}
                    />
                </Div>
                <Div px="20px" mb="20px">
                    <Text variant="body" mb="10px">
                        Доступно на проекте
                    </Text>
                    <MultiSelectInput
                        id="availableOnProjects"
                        name="availableOnProjects"
                        value={values?.availableOnProjects}
                        suggestions={availableOnProjectsOptions}
                        placeholder="Select projects"
                        onChange={handleChange}
                    />
                    {errors?.availableOnProjects && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.availableOnProjects}
                        </Text>
                    )}
                </Div>
                <Div px="20px" mb="20px">
                    <Text variant="body" mb="10px">
                        Sposnors
                    </Text>
                    <SponsorsInput
                        id="sponsors"
                        name="sponsors"
                        value={values?.sponsors}
                        suggestions={sponsors?.items || []}
                        placeholder="Select sponsors"
                        onChange={handleChange}
                    />
                </Div>
                <Div px="20px" mb="20px">
                    <Text variant="body" mb="10px">
                        Persons
                    </Text>
                    <AuthorsInput
                        id="persons"
                        name="persons"
                        value={values?.persons}
                        suggestions={persons?.items || []}
                        roles={roles || []}
                        placeholder="Select person"
                        onChange={handleChange}
                        lang={'ru'}
                    />
                </Div>
                <Div width="50%" px="20px" mb="20px">
                    <FileInput
                        id="cover"
                        title="Cover"
                        progress={fileInputProgress}
                        isLoading={isFileInputLoading}
                        onChange={handleFileInputChange}
                        onCancel={handleFileInputCancel}
                        preview={values?.cover}
                    />
                    {errors?.cover && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.cover}
                        </Text>
                    )}
                </Div>
            </Div>
            <Flex mt="40px">
                <PrimaryButton
                    disabled={!isValid || !formik.dirty || isSaved}
                    type="submit"
                    mr="20px"
                    isBig
                >
                    {isSaved ? 'Saved' : 'Save'}
                </PrimaryButton>
                <DefaultButton onClick={onClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </Form>
    );
};

export default CourseForm;
