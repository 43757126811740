import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { IModal } from 'src/model/types/Modal';
import { useOnClickOutside } from 'src/utils/hooks';

import Flex from './Flex';
import { IconClose } from './Icons';
import Text from './Text';

const Modal: React.FC<IModal> = ({
    children,
    label,
    onClose,
    isOpened,
    width,
    backgroundColor,
}) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useOnClickOutside(ref, onClose);

    return createPortal(
        <>
            {isOpened && (
                <Flex
                    top="0px"
                    left="0px"
                    backgroundColor="graphitBlue"
                    position="fixed"
                    size="100%"
                    alignItems="center"
                    justifyContent="center"
                    zIndex={1050}
                >
                    <Flex
                        ref={ref}
                        backgroundColor={backgroundColor || 'white'}
                        maxWidth="80%"
                        width={width || 'auto'}
                        maxHeight="95vh"
                        overflowY="auto"
                        flexDirection="column"
                        pt="20px"
                    >
                        <Flex
                            height="60px"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            position="relative"
                        >
                            <Flex width="100%" flex="1" justifyContent="center">
                                <Text
                                    variant="h4"
                                    fontSize="16px"
                                    textTransform="uppercase"
                                >
                                    {label}
                                </Text>
                            </Flex>
                            <Flex
                                position="absolute"
                                right="0"
                                onClick={onClose}
                                p="20px"
                                cursor="pointer"
                            >
                                <IconClose />
                            </Flex>
                        </Flex>
                        <Flex
                            p="20px"
                            flexDirection="column"
                            overflowY="scroll"
                        >
                            {children}
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>,
        document.body,
    );
};

export default Modal;
