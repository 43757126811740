/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { deletePerson } from 'src/api/mutations';
import { getPerson } from 'src/api/queries';
import {
    DefaultButton,
    Flex,
    Modal,
    PrimaryButton,
    Text,
} from 'src/components';

import { queryClient } from '../../main';

const DeleteModal: React.FC<{
    personId: number | undefined;
    onClose: () => void;
    isOpened: boolean;
}> = ({ personId, onClose, isOpened }) => {
    const { data: person } = useQuery(
        ['persons', personId],
        () => getPerson(personId!),
        { enabled: !!personId },
    );
    const deletePersonMutation = useMutation(
        (personId: number) => deletePerson(personId),
        {
            onSuccess: () => {
                onClose();
                queryClient.invalidateQueries('persons');
            },
        },
    );
    const handleDelete = () => {
        personId && deletePersonMutation.mutate(personId);
    };

    return (
        <Modal
            label={`Удаление ${person?.translations[0]?.title}`}
            onClose={onClose}
            isOpened={isOpened}
        >
            <Text variant="body">
                Вы уверены, что хотите удалить {person?.translations[0]?.title}{' '}
                ?
            </Text>
            <Flex mt="40px">
                <PrimaryButton onClick={handleDelete} mr="20px" isBig>
                    Delete
                </PrimaryButton>
                <DefaultButton onClick={onClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </Modal>
    );
};

export default DeleteModal;
