import { Div, Flex, Input, PrimaryButton } from 'components';
import { useFormik } from 'formik';
import { getCourseStatistics } from 'queries';
import React from 'react';
import { IFormik } from 'src/model/types/Formik';
import * as Yup from 'yup';

const Statistics = () => {
    const formik: IFormik = useFormik({
        initialValues: {
            courseId: undefined,
            from: undefined,
            to: undefined,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            courseId: Yup.number(),
            from: Yup.string(),
            to: Yup.string(),
        }),
        onSubmit: ({ courseId, from, to }) => {
            getCourseStatistics(
                courseId || undefined,
                from || undefined,
                to || undefined,
            );
        },
    });

    const { handleBlur, handleSubmit, handleChange, values, errors } = formik;

    return (
        <Div>
            <>
                <Flex
                    p="20px"
                    backgroundColor="white"
                    justifyContent="space-between"
                >
                    <PrimaryButton onClick={() => handleSubmit()}>
                        Get Statistics
                    </PrimaryButton>
                </Flex>
                <Div p="20px">
                    <Div backgroundColor="white">
                        <Div px="20px" pb="20px">
                            <Input
                                label="Course ID"
                                name="courseId"
                                id="courseId"
                                placeholder="Course ID"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.courseId}
                                error={!!errors.courseId}
                                width="100%"
                            />
                            <Input
                                type="datetime-local"
                                label="From"
                                name="from"
                                id="from"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!errors.from}
                                value={values.from}
                            />
                            <Input
                                type="datetime-local"
                                label="to"
                                name="to"
                                id="to"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!errors.to}
                                value={values.to}
                            />
                        </Div>
                    </Div>
                </Div>
            </>
        </Div>
    );
};

export default Statistics;
