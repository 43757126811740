import { Flex, PlainButton } from 'components';
import React from 'react';

interface ITabs {
    tabs: { name: string; isDisabled?: boolean }[];
    handleTabClick: (tab: string) => void;
    selectedTab: string;
}

const Tabs = ({ tabs, handleTabClick, selectedTab }: ITabs) => {
    return (
        <Flex alignItems="center" zIndex={10}>
            {tabs.map((tab, index) => (
                <PlainButton
                    backgroundColor={
                        selectedTab === tab.name ? 'white' : 'transparent'
                    }
                    boxShadow={
                        selectedTab === tab.name
                            ? `3px -3px 3px rgb(0 0 0 / 3%),
                                -3px -3px 3px rgb(0 0 0 / 3%)`
                            : 'none'
                    }
                    borderRadius={
                        selectedTab === tab.name ? '2px 2px 0 0 ' : 'none'
                    }
                    border="none"
                    variant="title"
                    onClick={() => handleTabClick(tab.name)}
                    key={index}
                    height="54px"
                    width="100%"
                    {...(selectedTab !== tab.name &&
                        !tab.isDisabled && {
                            cursor: 'pointer',
                        })}
                    disabled={tab.isDisabled}
                >
                    {tab.name}
                </PlainButton>
            ))}
        </Flex>
    );
};

export default Tabs;
