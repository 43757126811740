/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-mde/lib/styles/css/react-mde-all.css';

import styled from '@emotion/styled';
import { ICourseLesson } from 'api-model';
import {
    Alert,
    AuthorsInput,
    DefaultButton,
    Div,
    Flex,
    Input,
    Modal,
    PrimaryButton,
    Select,
    Text,
} from 'components';
import { useFormik } from 'formik';
import { IFormik } from 'formik-model';
import { createLesson, updateLesson, uploadLessonTextImage } from 'mutations';
import { getItem, getPersons, getRoles } from 'queries';
import React, { useEffect, useState } from 'react';
import ReactMde, { SaveImageHandler } from 'react-mde';
import { useMutation, useQuery } from 'react-query';
import * as Showdown from 'showdown';
import slugify from 'slugify';
import { getTranslation } from 'utils';
import * as Yup from 'yup';

import { queryClient } from '../../main';

const ReactMdeWrapper = styled.div({
    '.mde-preview-content ': {
        img: { width: '60%' },
    },
});

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

interface ILessonFormProps {
    onClose: () => void;
    courseId: number | undefined;
    lessonId: number | undefined;
    parentId: number | undefined;
    isOpened: boolean;
    slugs: string[] | undefined;
    parentsItems: any[] | undefined;
}

const LessonForm = ({
    onClose,
    courseId,
    lessonId,
    parentId,
    isOpened,
    slugs,
    parentsItems,
}: ILessonFormProps): JSX.Element => {
    const { data: lesson } = useQuery(
        ['lesson', lessonId],
        () => getItem(lessonId!),
        { enabled: !!lessonId },
    );

    const { data: persons } = useQuery('persons', async () => {
        const data = await getPersons();

        return data;
    });

    const { data: roles } = useQuery('roles', getRoles);

    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
        'write',
    );

    const [isSaved, setSaved] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => {
        formik.resetForm();
        setSaved(false);
        onClose();
    };

    const createLessonMutation = useMutation(
        (lesson: ICourseLesson) => createLesson(lesson),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );
    const lessonMutation = useMutation(
        (lesson: ICourseLesson) => updateLesson(lesson),
        {
            onSuccess: () => {
                setSaved(true);
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );

    const formik: IFormik = useFormik({
        initialValues: {
            id: lessonId!,
            parentId: parentId!,
            courseId: courseId!,
            persons: lesson?.persons.map((person: any) => ({
                id: person.id || person.personId,
                roleId: person.roleId,
            })),
            slug: lesson?.slug,
            number: lesson?.number,
            weight: lesson?.weight,
            duration: lesson?.duration,
            title: lesson && getTranslation(lesson, 'ru')?.title,
            lead: lesson
                ? converter.makeMarkdown(
                      getTranslation(lesson, 'ru')?.lead || '',
                  )
                : '',
            text: lesson
                ? converter.makeMarkdown(
                      getTranslation(lesson, 'ru')?.text || '',
                  )
                : '',
            video: (lesson && getTranslation(lesson, 'ru')?.video) || undefined,
        },

        enableReinitialize: true,
        validateOnMount: !courseId,
        validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            lead: Yup.string().required(),
            text: Yup.string().required(),
            slug: Yup.string()
                .test('slug', 'Slug already exist', (value) =>
                    value
                        ? !slugs
                              ?.filter(
                                  (slug) => slug !== formik.initialValues.slug,
                              )
                              .includes(value)
                        : true,
                )
                .min(1)
                .max(255)
                .required(),
            persons: Yup.array(),
            number: Yup.number().required('must be a number'),
            weight: Yup.number(),
            duration: Yup.number(),
            translations: Yup.object().shape({}),
        }),
        onSubmit: (data) => {
            const lesson: ICourseLesson = {
                id: lessonId!,
                courseId: courseId!,
                parentId: data.parentId!,
                slug: data.slug!,
                number: data.number!,
                weight: data.weight!,
                duration: data.duration!,
                type: 'lesson',
                persons: data.persons || [],
                translations: [
                    {
                        language: 'ru',
                        title: data.title,
                        lead: converter.makeHtml(data.lead),
                        text: converter.makeHtml(data.text!),
                        video: data.video,
                    },
                ],
            };

            submitLesson(lesson);
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const submitLesson = (lesson) => {
        if (lesson.id) {
            lessonMutation.mutate(lesson);
        } else {
            createLessonMutation.mutate(lesson);
        }
    };

    const handleChangeWithSlug = (lesson) => {
        setFieldValue('slug', slugify(lesson.target.value.toLowerCase()));
        handleChange(lesson);
    };

    const saveImage: SaveImageHandler = async function* (_, file: Blob) {
        const link = uploadLessonTextImage(file);

        yield link;

        return true;
    };

    return (
        <Modal
            label={lessonId ? `Урок ${lessonId}` : 'Создать новый урок'}
            onClose={handleClose}
            isOpened={isOpened}
            backgroundColor="#EFF2F3"
        >
            <form onSubmit={handleSubmit}>
                {isError && <Alert message={errorMessage} />}
                <Div
                    backgroundColor="white"
                    borderRadius="2px"
                    boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                    gridColumn="1/3"
                    pb="20px"
                >
                    <Div px="20px" pt="20px">
                        <Text variant="body" mb="10px">
                            Вложенность
                        </Text>
                        <Select
                            id="parentId"
                            name="parentId"
                            value={values?.parentId}
                            valueTitle={
                                parentsItems?.find(
                                    (parent) => values?.parentId === parent.id,
                                )?.title
                            }
                            placeholder="Select projects"
                            onChange={handleChange}
                        >
                            {parentsItems?.map((parent, index) => (
                                <Select.Option
                                    key={parent.id + index}
                                    value={parent.id}
                                >
                                    {parent.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Div>
                    <Div px="20px">
                        <Input
                            label={'Заголовок'}
                            name={`title`}
                            id={`title`}
                            placeholder={'Заголовок'}
                            onChange={handleChangeWithSlug}
                            onBlur={handleBlur}
                            value={values.title || ''}
                            error={!!errors.title}
                            width="100%"
                        />
                        {errors.title && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.title}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px">
                        <Input
                            label="Slug"
                            name="slug"
                            id="slug"
                            placeholder="Slug"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.slug}
                            error={!!errors.slug}
                            width="100%"
                        />
                        {errors.slug && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.slug}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px">
                        <Text variant="body" mt="20px" mb="10px">
                            {'Лид'}
                        </Text>
                        <ReactMde
                            value={values.lead || ''}
                            onChange={handleChange(`lead`)}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            toolbarCommands={[['bold', 'italic', 'link']]}
                            generateMarkdownPreview={(markdown) =>
                                Promise.resolve(converter.makeHtml(markdown))
                            }
                        />
                        {errors.lead && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.lead}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px">
                        <Text variant="body" mt="20px" mb="10px">
                            {'Текст урока'}
                        </Text>
                        <ReactMdeWrapper>
                            <ReactMde
                                value={values.text || ''}
                                onChange={handleChange(`text`)}
                                selectedTab={selectedTab}
                                onTabChange={setSelectedTab}
                                generateMarkdownPreview={(markdown) =>
                                    Promise.resolve(
                                        converter.makeHtml(markdown),
                                    )
                                }
                                toolbarCommands={[
                                    [
                                        'header',
                                        'bold',
                                        'italic',
                                        'strikethrough',
                                    ],
                                    ['link', 'quote', 'code'],
                                    [
                                        'unordered-list',
                                        'ordered-list',
                                        'checked-list',
                                    ],
                                ]}
                                paste={{
                                    saveImage: saveImage,
                                }}
                            />
                        </ReactMdeWrapper>
                        {errors.text && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.text}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Ссылка на видео'}
                            name={`video`}
                            id={`video`}
                            placeholder={'Ссылка на видео'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.video || ''}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Длительность в минутах'}
                            name={`duration`}
                            id={`duration`}
                            placeholder={'Длительность в минутах'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.duration || ''}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Вес'}
                            name={`weight`}
                            id={`weight`}
                            placeholder={'Вес'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.weight || ''}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Номер'}
                            name={`number`}
                            id={`number`}
                            placeholder={'Номер'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number || ''}
                            error={!!errors.number}
                        />
                        {errors?.number && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.number}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Text variant="body" mb="10px">
                            Persons
                        </Text>
                        <AuthorsInput
                            id="persons"
                            name="persons"
                            value={values?.persons || []}
                            suggestions={persons?.items || []}
                            roles={roles || []}
                            placeholder="Select person"
                            onChange={handleChange}
                            lang={'ru'}
                        />
                    </Div>
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        {isSaved ? 'Saved' : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </form>
        </Modal>
    );
};

export default LessonForm;
