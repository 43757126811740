import {
    IEditFormat,
    IEditLocation,
    IEditSponsor,
    IPersonItem,
    IEditEvent,
    ICourseContentItem,
    ICourseLesson,
    IMaterial,
    IQuestion,
    IAnswer,
    IOrder,
 } from "api-model";
import axiosInstance from "src/api/axios";
import { clearTokens, getTokens } from "utils";

export const updateFormat = async (format: IEditFormat): Promise<IEditFormat> => {
    const { data } = await axiosInstance.put(`/ru/formats/${format.id}`,{
        ...format
    });

    return data;
};

export const createFormat = async (format: IEditFormat): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/ru/formats/',{
        ...format
    });

    return data;
};

export const deleteFormat = async (formatId: number) => {
    const { data } = await axiosInstance.delete(`/ru/formats/${formatId}`);

    return data;
};

export const updatePerson = async (person: IPersonItem): Promise<IPersonItem> => {
    const { data } = await axiosInstance.put(`/persons/${person.id}`,{
        ...person
    });

    return data;
};

export const createPerson = async (person: IPersonItem): Promise<{id: number}> => {
    const { data } = await axiosInstance.post('/persons/',{
        ...person
    });

    return data;
};

export const uploadPersonPhoto = async (file: FileList): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/persons/photo`,formData);

    return data;
};

export const uploadLogoPhoto = async (file: FileList): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/sponsors/logo`,formData);

    return data;
};

export const deletePerson = async (personId: number) => {
    const { data } = await axiosInstance.delete(`/persons/${personId}`);

    return data;
};

export const signOut = async ()=>{
    try {
        const {refreshToken} = getTokens()
        await axiosInstance.post('auth/logout', {refreshToken})
        clearTokens()
    }
    catch (error) {
        console.error(error)
    }
}

export const createLocation = async (location: IEditLocation): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/ru/locations/',{
        ...location
    });

    return data;
};
export const updateLocation = async (location: IEditLocation): Promise<IEditLocation> => {
    const { data } = await axiosInstance.put(`/ru/locations/${location.id}`,{
        ...location
    });

    return data;
};
export const deleteLocation = async (locationId: number) => {
    const { data } = await axiosInstance.delete(`/ru/locations/${locationId}`);

    return data;
};

export const createSponsor = async (sponsor: IEditSponsor): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/sponsors',{
        ...sponsor
    });

    return data;
};
export const updateSponsor = async (sponsor: IEditSponsor): Promise<IEditSponsor> => {
    const { data } = await axiosInstance.put(`/sponsors/${sponsor.id}`,{
        ...sponsor
    });

    return data;
};
export const createAnnouncement = async (announcement: any): Promise<any> => {
    const { data } = await axiosInstance.post('/announcements', {
        ...announcement
    });

    return data;
};
export const updateAnnouncement = async (announcement: any): Promise<any> => {
    const { data } = await axiosInstance.put(`/announcements/${announcement.id}`,{
        ...announcement
    });

    return data;
};

export const uploadSponsorLogo = async (file: FileList): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/sponsors/logo`,formData);

    return data;
};

export const updateCourseOrder = async (body?: any):Promise<any> => { // TODO
    const { data } = await axiosInstance.put(`/courses/set-order`, {
        ...body
    });

    return data
};

export const uploadAnnouncementPicture = async (file: FileList): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/announcements/banner-cover`, formData);

    return data;
};

export const createEvent = async (event: IEditEvent): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/events/',{
        ...event
    });

    return data;
};
export const updateEvent = async (event: IEditEvent): Promise<IEditEvent> => {
    const { data } = await axiosInstance.put(`/events/${event.id}`,{
        ...event
    })

    return data;
};
export const deleteEvent = async (eventId: number) => {
    const { data } = await axiosInstance.delete(`/events/${eventId}`);

    return data;
};
export const uploadEventCover = async (file: FileList): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/events/programme-image`,formData);

    return data;
};
export const createCourse = async (course: any): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/courses/',{
        ...course
    });

    return data;
};
export const updateCourse = async (course: any): Promise<any> => {
    const { data } = await axiosInstance.put(`/courses/${course.id}`,{
        ...course
    })

    return data;
};
export const createLesson = async (lesson: ICourseLesson): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/course-items',{
        ...lesson
    });

    return data;
};
export const updateLesson = async (lesson: ICourseLesson): Promise<any> => {
    const { data } = await axiosInstance.put(`/course-items/${lesson.id}`,{
        ...lesson
    })

    return data;
};
export const createItem = async (item: ICourseContentItem): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/course-items',{
        ...item
    });

    return data;
};
export const updateItem = async (item: ICourseContentItem): Promise<any> => {
    const { data } = await axiosInstance.put(`/course-items/${item.id}`,{
        ...item
    })

    return data;
};
export const createChapter = async (chapter: ICourseContentItem): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/course-items',{
        ...chapter
    });

    return data;
};
export const updateChapter = async (chapter: ICourseContentItem): Promise<any> => {
    const { data } = await axiosInstance.put(`/course-items/${chapter.id}`,{
        ...chapter
    })

    return data;
};

export const createMaterial = async (material: IMaterial): Promise<{id:number}> => {
    const { data } = await axiosInstance.post('/materials',{
        ...material
    });

    return data;
};
export const updateMaterial = async (material: IMaterial, materialId: number): Promise<any> => {
    const { data } = await axiosInstance.put(`/materials/${materialId}`,{
        ...material
    })

    return data;
};

export const updateItemOrder = async (item: any): Promise<any> => {
    const { data } = await axiosInstance.put(`/course-items/set-order`,{
        ...item
    })

    return data;
};

export const uploadCourseCover = async (file: FileList): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/courses/cover`,formData);

    return data;
};

export const deleteCourse = async (courseId: number) => {
    const { data } = await axiosInstance.delete(`/courses/${courseId}`);

    return data;
};

export const uploadFile = async (file: FileList): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/files/upload`,formData);

    return data;
};
export const uploadLessonTextImage = async (file: Blob): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file);
    const { data } = await axiosInstance.post(`/files/upload`,formData);

    return data;
};
export const uploadQuestionImage = async (file: FileList, testId: number): Promise<any> => {
    var formData = new FormData();
    formData.append("file", file[0]);
    const { data } = await axiosInstance.post(`/course-tests/${testId}/questions/upload-image`,formData);

    return data;
};
export const createQuestion = async (question: IQuestion, testId: number): Promise<{id:number}> => {
    const { data } = await axiosInstance.post(`/course-tests/${testId}/questions`,{
        ...question
    });

    return data;
};
export const updateQuestion = async (question: IQuestion, testId: number): Promise<{id:number}> => {
    const { data } = await axiosInstance.put(`/course-tests/${testId}/questions/${question.id}`,{
        ...question
    });

    return data;
};
export const deleteQuestion = async (testId: number, questionId: number): Promise<{id:number}> => {
    const { data } = await axiosInstance.delete(
        `/course-tests/${testId}/questions/${questionId}`);

    return data;
};
export const updateQuestionsOrder = async (testId: number, order: IOrder[]): Promise<any> => { // TODO
    const { data } = await axiosInstance.put(
        `/course-tests/${testId}/questions/set-order`, {
        ...order
    });

    return data
};
export const updateAnswersOrder = async (testId: number, questionId: number, order: IOrder[]): Promise<any> => { // TODO
    const { data } = await axiosInstance.put(
        `/course-tests/${testId}/questions/${questionId}/answers/set-order`, {
        ...order
    });

    return data
};
export const createAnswer = async (answer: IAnswer, testId: number,  questionId: number): Promise<{id:number}> => {
    const { data } = await axiosInstance.post(`/course-tests/${testId}/questions/${questionId}/answers`,{
        ...answer
    });

    return data;
};
export const updateAnswer = async (answer: IAnswer, testId: number,  questionId: number, answerId: number): Promise<{id:number}> => {
    const { data } = await axiosInstance.put(
        `/course-tests/${testId}/questions/${questionId}/answers/${answerId}`,{
        ...answer
    });

    return data;
};
export const deleteAnswer = async (testId: number, questionId: number, answerId: number): Promise<{id:number}> => {
    const { data } = await axiosInstance.delete(
        `/course-tests/${testId}/questions/${questionId}/answers/${answerId}`);

    return data;
};
