/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-mde/lib/styles/css/react-mde-all.css';

import { ICourseTest } from 'api-model';
import {
    Alert,
    CheckBox,
    DefaultButton,
    Div,
    FileInput,
    Flex,
    Input,
    Modal,
    PrimaryButton,
    Select,
    Text,
} from 'components';
import { useFormik } from 'formik';
import { IFormik } from 'formik-model';
import { createItem, updateItem, uploadFile } from 'mutations';
import { getItem } from 'queries';
import React, { useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useMutation, useQuery } from 'react-query';
import * as Showdown from 'showdown';
import slugify from 'slugify';
import { getTranslation } from 'utils';
import * as Yup from 'yup';

import { queryClient } from '../../main';

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

type TestFormProps = {
    onClose: () => void;
    courseId: number;
    testId: number | undefined;
    parentId: number | undefined;
    isOpened: boolean;
    slugs: string[] | undefined;
    parentsItems: any[] | undefined;
};

const TestForm = ({
    onClose,
    courseId,
    testId,
    parentId,
    isOpened,
    slugs,
    parentsItems,
}: TestFormProps): JSX.Element => {
    const { data: test } = useQuery(['test', testId], () => getItem(testId!), {
        enabled: !!testId,
    });

    const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>(
        'write',
    );

    const [isSaved, setSaved] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => {
        onClose();
        setSaved(false);
    };

    const handleRequestError = (error) => {
        setError(true);
        setErrorMessage(Object.values(error.response.data).join(' - '));
        setTimeout(() => {
            setError(false);
            setErrorMessage('');
        }, 5000);
    };

    const fileMutation = useMutation((fileInputData: FileList) =>
        uploadFile(fileInputData),
    );

    const [isCertPreviewInputLoading, setCertPreviewInputLoading] = useState(
        false,
    );
    const [certPreviewInputProgress, setCertPreviewInputProgress] = useState(0);

    function handleCertPreviewInputCancel() {
        setCertPreviewInputLoading(false);
        setCertPreviewInputProgress(0);
        formik.setFieldValue('certPreview', undefined);
    }

    function handleCertPreviewInputChange(file: FileList) {
        setCertPreviewInputLoading(true);
        setCertPreviewInputProgress(100);

        fileMutation.mutate(file, {
            onSuccess: (value) => {
                formik.setFieldValue('certPreview', value);
            },
            onError: (error: any) => {
                handleRequestError(error);
            },
        });
    }

    const createTestMutation = useMutation(
        (test: ICourseTest) => createItem(test),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error: any) => {
                handleRequestError(error);
            },
        },
    );
    const testMutation = useMutation((test: ICourseTest) => updateItem(test), {
        onSuccess: () => {
            setSaved(true);
            handleClose();
            queryClient.invalidateQueries('course-items');
        },
        onError: (error: any) => {
            handleRequestError(error);
        },
    });

    const formik: IFormik = useFormik({
        initialValues: {
            id: testId!,
            parentId: parentId!,
            courseId: courseId!,
            title: test && getTranslation(test, 'ru')?.title,
            slug: test?.slug,
            description: test
                ? converter.makeMarkdown(
                      getTranslation(test, 'ru')?.description || '',
                  )
                : '',
            result: test && getTranslation(test, 'ru')?.result,
            negativeResult: test && getTranslation(test, 'ru')?.negativeResult,
            percentToPass: test?.percentToPass,
            isBlocking: test?.isBlocking,
            isShowCert: test?.isShowCert,
            certPreview: test?.certPreview,
            number: test?.number,
            weight: test?.weight,
            duration: test?.duration,
        },

        enableReinitialize: true,
        validateOnMount: !courseId,
        validationSchema: Yup.object().shape({
            slug: Yup.string()
                .test('slug', 'Slug already exist', (value) =>
                    value
                        ? !slugs
                              ?.filter(
                                  (slug) => slug !== formik.initialValues.slug,
                              )
                              .includes(value)
                        : true,
                )
                .min(1)
                .max(255)
                .required(),
            number: Yup.number().required('must be a number'),
            percentToPass: Yup.number().required('Percent to pass is required'),
            weight: Yup.number(),
            duration: Yup.number(),
        }),
        onSubmit: (data) => {
            const test: ICourseTest = {
                id: testId!,
                courseId: courseId!,
                parentId: data.parentId!,
                type: 'test',
                percentToPass: data.percentToPass!,
                isBlocking: data.isBlocking!,
                isShowCert: data.isShowCert!,
                certPreview: data.certPreview!,
                slug: data.slug!,
                number: data.number!,
                weight: data.weight!,
                duration: data.duration!,
                translations: [
                    {
                        language: 'ru',
                        title: data.title,
                        description: converter.makeHtml(data.description!),
                        result: converter.makeHtml(data.result!),
                        negativeResult: converter.makeHtml(
                            data.negativeResult!,
                        ),
                    },
                ],
            };

            submitTest(test);
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const submitTest = (test) => {
        if (test.id) {
            testMutation.mutate(test);
        } else {
            createTestMutation.mutate(test);
        }
    };

    const handleChangeWithSlug = (test) => {
        setFieldValue('slug', slugify(test.target.value.toLowerCase()));
        handleChange(test);
    };

    return (
        <Modal
            label={testId ? `Тест ${testId}` : 'Создать новый тест'}
            onClose={handleClose}
            isOpened={isOpened}
            backgroundColor="#EFF2F3"
        >
            <form onSubmit={handleSubmit}>
                {isError && <Alert message={errorMessage} />}
                <Div
                    backgroundColor="white"
                    borderRadius="2px"
                    boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                    gridColumn="1/3"
                    pb="20px"
                >
                    <Div px="20px">
                        <Div pt="20px">
                            <Text variant="body" mb="10px">
                                Вложенность
                            </Text>
                            <Select
                                id="parentId"
                                name="parentId"
                                value={values?.parentId}
                                valueTitle={
                                    parentsItems?.find(
                                        (parent) =>
                                            values?.parentId === parent.id,
                                    )?.title
                                }
                                placeholder="Select projects"
                                onChange={handleChange}
                            >
                                {parentsItems
                                    ?.filter((parent) => parent.id !== testId)
                                    ?.map((parent) => (
                                        <Select.Option
                                            key={parent.id}
                                            value={parent.id}
                                        >
                                            {parent.title}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Div>
                    </Div>
                    <Div px="20px">
                        <CheckBox
                            label={'Заблокирован?'}
                            name={`isBlocking`}
                            id={`isBlocking`}
                            checked={values.isBlocking}
                            onChange={handleChange}
                        />
                    </Div>
                    <Div px="20px">
                        <Input
                            label={'Заголовок'}
                            name={`title`}
                            id={`title`}
                            placeholder={'Заголовок'}
                            onChange={handleChangeWithSlug}
                            onBlur={handleBlur}
                            value={values.title || ''}
                            error={!!errors.title && !!touched.title}
                        />
                        {touched.title && errors.title && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.title}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px">
                        <Input
                            label="Slug"
                            name="slug"
                            id="slug"
                            placeholder="Slug"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.slug}
                            error={!!errors.slug}
                        />
                        {errors.slug && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.slug}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px">
                        <Text variant="body" mt="20px" mb="10px">
                            {'Описание'}
                        </Text>
                        <ReactMde
                            value={values.description || ''}
                            onChange={handleChange(`description`)}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            toolbarCommands={[['bold', 'italic', 'link']]}
                            generateMarkdownPreview={(markdown) =>
                                Promise.resolve(converter.makeHtml(markdown))
                            }
                        />
                        {errors.description && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.description}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Процент для прохождения'}
                            name={`percentToPass`}
                            id={`percentToPass`}
                            placeholder={'Процент для прохождения'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.percentToPass || ''}
                            error={!!errors.percentToPass}
                        />
                        {touched?.percentToPass && errors?.percentToPass && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.percentToPass}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Длительность в минутах'}
                            name={`duration`}
                            id={`duration`}
                            placeholder={'Длительность в минутах'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.duration || ''}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Вес'}
                            name={`weight`}
                            id={`weight`}
                            placeholder={'Вес'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.weight || ''}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Input
                            label={'Номер'}
                            name={`number`}
                            id={`number`}
                            placeholder={'Номер'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number || ''}
                            error={!!errors.number}
                        />
                        {touched?.number && errors?.number && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.number}
                            </Text>
                        )}
                    </Div>
                    <Div width="50%" px="20px" mb="20px">
                        <Div px="20px">
                            <CheckBox
                                label={'Показывать сертификат'}
                                name={`isShowCert`}
                                id={`isShowCert`}
                                checked={values.isShowCert}
                                onChange={handleChange}
                            />
                        </Div>
                        <FileInput
                            id="certPreview"
                            title="Cert preview"
                            progress={certPreviewInputProgress}
                            isLoading={isCertPreviewInputLoading}
                            onChange={handleCertPreviewInputChange}
                            onCancel={handleCertPreviewInputCancel}
                            preview={values?.certPreview}
                        />
                        {errors?.certPreview && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.certPreview}
                            </Text>
                        )}
                    </Div>
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid || isSaved}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        {isSaved ? 'Saved' : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </form>
        </Modal>
    );
};

export default TestForm;
