import React, { useEffect, useState } from 'react';
import { getTranslation } from 'src/utils';
import { LayoutProps, PositionProps, SpaceProps } from 'styled-system';

import { IPersonItem } from '../../model/types/api';
import {
    ArrowDownIcon,
    ArrowUpIcon,
    Div,
    Flex,
    IconClose,
    PlainInput,
    Select,
    Text,
} from '../';

type AuthorsInputProps = {
    value?: IPersonItem[];
    name?: string;
    roles?: any;
    lang: string;
    id?: string;
    suggestions: IPersonItem[];
    placeholder: string;
    onChange: (value: IPersonItem[] | undefined) => void;
} & SpaceProps &
    LayoutProps &
    PositionProps;

const AuthorsInput: React.FC<AuthorsInputProps> = (props) => {
    const [values, setValue] = useState<any | undefined>(
        props.value || undefined,
    );
    const [isOpen, setOpened] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);

    function handleSuggestionClick(suggestion: IPersonItem) {
        if (values?.map((value) => value.id).includes(suggestion.id)) {
            setValue(values.filter((_value) => _value.id !== suggestion.id));
        } else {
            setValue(
                values
                    ? [...values, { id: suggestion.id }]
                    : [{ id: suggestion.id }],
            );
        }
    }
    function handleRoleClick(event: any, suggestion) {
        const currentPerson = values.find(
            (_value) => _value.id === suggestion.id,
        );

        const withoutCurrentPerson = values
            .filter((_value) => _value.id !== suggestion.id)
            .map((person) => ({ ...person }));

        const target = event.target;

        currentPerson.roleId = target.value;

        setValue([currentPerson, ...withoutCurrentPerson]);
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (values !== undefined) {
            const event: any = {
                target: { name: undefined, value: undefined },
            };

            event.target.name = props.name;
            event.target.value = values;
            props.onChange(event);
        }
    }, [values]);

    useEffect(() => {
        setFilteredSuggestions(
            props.suggestions.filter((suggestion) =>
                getTranslation(suggestion, props.lang)
                    ?.title.toLowerCase()
                    .includes(searchValue.toLowerCase()),
            ),
        );
    }, [props.suggestions, searchValue]);

    return (
        <Div
            position={'relative'}
            padding={'9px 10px'}
            backgroundColor={'transparent'}
            width={'fit-content'}
            minWidth={'420px'}
            boxSizing={'border-box'}
            border={'#DCDDE2 1px solid'}
            {...(isOpen && {
                zIndex: 999,
                backgroundColor: 'menuGrey',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
            })}
        >
            <Flex
                minHeight={'46px'}
                boxSizing={'border-box'}
                padding={'9px 10px'}
                backgroundColor={'white'}
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex flexDirection="column">
                    <PlainInput
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        variant="search"
                        placeholder="Select persons"
                    ></PlainInput>
                    {values && values.length > 0 && (
                        <Flex flexWrap="wrap" style={{ gap: '10px' }}>
                            {values
                                ?.map((value) =>
                                    props.suggestions.find(
                                        (suggestion) =>
                                            suggestion.id === value.id,
                                    ),
                                )
                                .map((_value, _index) => (
                                    <Flex
                                        key={_index}
                                        alignItems="center"
                                        padding="6px 10px"
                                        backgroundColor="authorsSuggestionsBackgroundColor"
                                        cursor="pointer"
                                        borderRadius="100px"
                                        flexWrap="wrap"
                                        onClick={() =>
                                            setValue(
                                                values.filter(
                                                    (__value) =>
                                                        __value.id !==
                                                        _value.id,
                                                ),
                                            )
                                        }
                                    >
                                        <Flex
                                            width={'16px'}
                                            height={'16px'}
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            borderRadius={'10px'}
                                            boxSizing={'border-box'}
                                            marginRight={'10px'}
                                            background={`url(${encodeURI(
                                                _value?.photo,
                                            )})`}
                                            backgroundSize="contain"
                                        />
                                        <Text
                                            variant="body"
                                            color="inputGraphit"
                                            marginBottom="-2px"
                                        >
                                            {
                                                getTranslation(
                                                    _value,
                                                    props.lang,
                                                )?.title
                                            }
                                        </Text>
                                        <Flex
                                            alignItems="center"
                                            marginLeft="10px"
                                        >
                                            <IconClose
                                                width="12px"
                                                height="12px"
                                            />
                                        </Flex>
                                    </Flex>
                                ))}
                        </Flex>
                    )}
                </Flex>
                {isOpen ? (
                    <Div cursor="pointer" onClick={() => setOpened(!isOpen)}>
                        <ArrowUpIcon width="15px" height="10px" />
                    </Div>
                ) : (
                    <Div cursor="pointer" onClick={() => setOpened(!isOpen)}>
                        <ArrowDownIcon width="15px" height="10px" />
                    </Div>
                )}
            </Flex>
            {isOpen && (
                <Div
                    position={'absolute'}
                    width={'100%'}
                    marginLeft={'-10px'}
                    padding={'10px'}
                    zIndex={999}
                    backgroundColor={'menuGrey'}
                    boxSizing={'border-box'}
                    borderBottomLeftRadius={'4px'}
                    borderBottomRightRadius={'4px'}
                >
                    {filteredSuggestions.map((_suggestion, index) => (
                        <Flex
                            paddingTop="10px"
                            paddingBottom="10px"
                            key={index}
                            cursor="pointer"
                            alignItems="center"
                            onClick={() => handleSuggestionClick(_suggestion)}
                        >
                            <Flex
                                width={'16px'}
                                height={'16px'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                borderRadius={'10px'}
                                marginTop="-3px"
                                boxSizing={'border-box'}
                                marginRight={'10px'}
                                background={`url(${_suggestion.photo})`}
                                backgroundSize="contain"
                                {...(!!values
                                    ?.map((_value) => _value.id)
                                    .includes(_suggestion.id) && {
                                    border: 'none',
                                    backgroundColor: 'solidBlue',
                                })}
                            ></Flex>
                            <Text
                                whiteSpace="nowrap"
                                variant="body"
                                color={
                                    values
                                        ?.map((_value) => _value.id)
                                        .includes(_suggestion.id)
                                        ? 'black'
                                        : 'authorsSuggestionsColor'
                                }
                            >
                                {getTranslation(_suggestion, props.lang)
                                    ?.title ||
                                    `no data, mb it's in another lang`}
                            </Text>

                            {values
                                ?.map((_value) => _value.id)
                                .includes(_suggestion.id) && (
                                <Div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <Select
                                        id="roleId"
                                        name="roleId"
                                        placeholder="Select role"
                                        value={
                                            values
                                                .filter(
                                                    (_value) =>
                                                        _value.id ===
                                                        _suggestion.id,
                                                )
                                                .map(
                                                    (person) => person.roleId,
                                                )[0]
                                        }
                                        valueTitle={
                                            getTranslation(
                                                props.roles.filter(
                                                    (role) =>
                                                        values.filter(
                                                            (_value) =>
                                                                _value.id ===
                                                                _suggestion.id,
                                                        )[0]?.roleId ===
                                                        role.id,
                                                )[0],
                                                props.lang,
                                            )?.title
                                        }
                                        onChange={(event) => {
                                            handleRoleClick(event, _suggestion);
                                        }}
                                    >
                                        {props.roles?.map((role) => (
                                            <Select.Option
                                                key={role.id}
                                                value={role.id}
                                            >
                                                {role.translations.length
                                                    ? getTranslation(
                                                          role,
                                                          props.lang,
                                                      )?.title
                                                    : 'no data'}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Div>
                            )}
                        </Flex>
                    ))}
                </Div>
            )}
        </Div>
    );
};

export default AuthorsInput;
