import styled from '@emotion/styled';
import {
    createShouldForwardProp,
    props,
} from '@styled-system/should-forward-prop';
import {
    background,
    border,
    color,
    grid,
    layout,
    position,
    shadow,
    size,
    space,
    system,
} from 'styled-system';

import Div, { DivProps } from './Div';

const shouldForwardProp = createShouldForwardProp([...props, 'cursor']);

const Grid = styled(Div, { shouldForwardProp })<DivProps>(
    grid,
    layout,
    position,
    shadow,
    color,
    background,
    border,
    size,
    space,
    system({
        boxSizing: {
            property: 'boxSizing',
        },
    }),
);

Grid.defaultProps = {
    display: 'grid',
};

export default Grid;
