import React from 'react';
import { useMutation } from 'react-query';
import { deleteEvent } from 'src/api/mutations';
import {
    DefaultButton,
    Flex,
    Modal,
    PrimaryButton,
    Text,
} from 'src/components';

import { queryClient } from '../../main';

const DeleteEventModal: React.FC<{
    eventId: number | undefined;
    onClose: () => void;
    isOpened: boolean;
}> = ({ eventId, onClose, isOpened }) => {
    const deleteEventMutation = useMutation(
        (eventId: number) => deleteEvent(eventId),
        {
            onSuccess: () => {
                onClose();
                queryClient.invalidateQueries('events');
            },
        },
    );
    const handleDelete = () => {
        eventId && deleteEventMutation.mutate(eventId);
    };

    return (
        <Modal
            label={`Удаление события ${eventId}`}
            onClose={onClose}
            isOpened={isOpened}
        >
            <Text variant="body">
                Вы уверены, что хотите удалить событие {eventId} ?
            </Text>
            <Flex mt="40px">
                <PrimaryButton onClick={handleDelete} mr="20px" isBig>
                    Delete
                </PrimaryButton>
                <DefaultButton onClick={onClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </Modal>
    );
};

export default DeleteEventModal;
