/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
    createSponsor,
    updateSponsor,
    uploadLogoPhoto,
} from 'src/api/mutations';
import { getSponsor } from 'src/api/queries';
import {
    DefaultButton,
    Div,
    FileInput,
    Flex,
    Img,
    Input,
    Modal,
    PrimaryButton,
    Span,
    Text,
} from 'src/components';
import { IEditSponsor, ISponsorTranslation } from 'src/model/types/api';
import { IFormik } from 'src/model/types/Formik';
import * as Yup from 'yup';

import { queryClient } from '../../main';

interface IEditSponsorModal {
    onClose: () => void;
    isOpened: boolean;
    sponsorId: number | undefined;
}

const EditSponsorModal: React.FC<IEditSponsorModal> = ({
    onClose,
    isOpened,
    sponsorId,
}) => {
    const handleClose = () => {
        formik.resetForm();
        onClose();
    };
    const { data: sponsor } = useQuery(
        ['sponsors', sponsorId],
        () => getSponsor(sponsorId!),
        { enabled: !!sponsorId },
    );

    const [isFileInputLoadingRu, setFileInputLoadingRu] = useState(false);
    const [fileInputProgressRu, setFileInputProgressRu] = useState(0);
    const [fileInputDataRu, setFileInputDataRu] = useState<
        (string & FileList) | undefined
    >();

    const [isFileInputLoadingEn, setFileInputLoadingEn] = useState(false);
    const [fileInputProgressEn, setFileInputProgressEn] = useState(0);
    const [fileInputDataEn, setFileInputDataEn] = useState<
        (string & FileList) | undefined
    >();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    function handleFileInputChangeRu(
        file: FileList,
        setFieldValue,
        setFieldTouched,
    ) {
        setFileInputLoadingRu(true);
        setFileInputDataRu(file as (string & FileList) | undefined);
        photoMutation.mutate(file as FileList, {
            onSuccess: (value) => {
                setFileInputProgressRu(100);
                setFieldTouched('translations.ru.logoUrl');
                setFieldValue('translations.ru.logoUrl', value);
            },
        });
    }

    function handleFileInputCancelRu(setFieldValue) {
        setFileInputLoadingRu(false);
        setFileInputProgressRu(0);
        setFileInputDataRu(undefined);
        setFieldValue('translations.ru.logoUrl', '');
    }

    function handleFileInputChangeEn(
        file: FileList,
        setFieldValue,
        setFieldTouched,
    ) {
        setFileInputLoadingEn(true);
        setFileInputDataEn(file as (string & FileList) | undefined);
        photoMutation.mutate(file as FileList, {
            onSuccess: (value) => {
                setFileInputProgressEn(100);
                setFieldTouched('translations.en.logoUrl');
                setFieldValue('translations.en.logoUrl', value);
            },
        });
    }

    function handleFileInputCancelEn(setFieldValue) {
        setFileInputLoadingEn(false);
        setFileInputProgressEn(0);
        setFileInputDataEn(undefined);
        setFieldValue('translations.en.logoUrl', '');
    }

    const createSponsorMutation = useMutation(
        (sponsor: IEditSponsor) => createSponsor(sponsor),
        {
            onSuccess: () => {
                handleClose();
                formik.resetForm();
                queryClient.invalidateQueries('sponsors');
            },
        },
    );
    const sponsorMutation = useMutation(
        (sponsor: IEditSponsor) => updateSponsor(sponsor),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('sponsors');
            },
        },
    );

    const photoMutation = useMutation((fileInputData: FileList) =>
        uploadLogoPhoto(fileInputData),
    );
    const getTranslation = (data: IEditSponsor, lang: string) => {
        return data?.translations.find(
            (translation: ISponsorTranslation) => translation.language === lang,
        );
    };
    const formik: IFormik = useFormik({
        initialValues: {
            translations: {
                ru: {
                    title: sponsor
                        ? getTranslation(sponsor, 'ru')?.title
                        : null,
                    description: sponsor
                        ? getTranslation(sponsor, 'ru')?.description
                        : null,
                    logoUrl: sponsor
                        ? getTranslation(sponsor, 'ru')?.logoUrl
                        : null,
                    linkUrl: sponsor
                        ? getTranslation(sponsor, 'ru')?.linkUrl
                        : null,
                },
                en: {
                    title: sponsor
                        ? getTranslation(sponsor, 'en')?.title
                        : null,
                    description: sponsor
                        ? getTranslation(sponsor, 'en')?.description
                        : null,
                    logoUrl: sponsor
                        ? getTranslation(sponsor, 'en')?.logoUrl
                        : null,
                    linkUrl: sponsor
                        ? getTranslation(sponsor, 'en')?.linkUrl
                        : null,
                },
            },
        },
        enableReinitialize: true,
        validateOnMount: !sponsorId,
        validationSchema: Yup.object().shape({
            translations: Yup.object().shape({
                en: Yup.object().shape({
                    title: Yup.string().required(),
                    logoUrl: Yup.string().url().required(),
                    linkUrl: Yup.string().url().required(),
                    description: Yup.string().required(),
                }),
                ru: Yup.object().shape({
                    title: Yup.string().required(),
                    logoUrl: Yup.string().url().required(),
                    linkUrl: Yup.string().url().required(),
                    description: Yup.string().required(),
                }),
            }),
        }),
        onSubmit: (data) => {
            const sponsor: IEditSponsor = {
                id: sponsorId!,
                translations: [
                    {
                        language: 'en',
                        title: data.translations.en.title!,
                        logoUrl: data.translations.en.logoUrl!,
                        linkUrl: data.translations.en.linkUrl!,
                        description: data.translations.en.description!,
                    },
                    {
                        language: 'ru',
                        title: data.translations.ru.title!,
                        logoUrl: data.translations.ru.logoUrl!,
                        linkUrl: data.translations.ru.linkUrl!,
                        description: data.translations.ru.description!,
                    },
                ],
            };

            submitSponsor(sponsor);
        },
    });

    const submitSponsor = (sponsor: IEditSponsor) => {
        if (sponsor.id) {
            sponsorMutation.mutate(sponsor, {
                onSuccess: () => {
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                        handleClose();
                    }, 2500);
                },
                onError: () => {
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 2500);
                },
            });
        } else {
            createSponsorMutation.mutate(sponsor);
        }
    };

    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        setFieldTouched,
    } = formik;

    return (
        <Modal
            label={
                sponsorId ? `Спонсор ${sponsorId}` : 'Создать нового спонсора'
            }
            onClose={handleClose}
            isOpened={isOpened}
        >
            <form onSubmit={handleSubmit}>
                <Div>
                    <Flex mb="20px" justifyContent="space-between">
                        <Div width="calc(50% - 10px)" paddingRight="10px">
                            <Input
                                label="Title (RU)"
                                name="translations.ru.title"
                                id="translations.ru.title"
                                placeholder="Title (RU)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.translations?.ru?.title || ''}
                                error={values?.translations?.ru?.title === ''}
                            />
                        </Div>
                        <Div width="calc(50% - 10px)" paddingLeft="10px">
                            <Input
                                label="Title (EN)"
                                name="translations.en.title"
                                id="translations.en.title"
                                placeholder="Title (EN)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.translations?.en?.title || ''}
                                error={values?.translations?.en?.title === ''}
                            />
                        </Div>
                    </Flex>
                    <Flex mb="20px" justifyContent="space-between">
                        <Div width="calc(50% - 10px)" paddingRight="10px">
                            <Input
                                label="Description (RU)"
                                name="translations.ru.description"
                                id="translations.ru.description"
                                placeholder="Description (RU)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                    values?.translations?.ru?.description || ''
                                }
                                error={
                                    values?.translations?.ru?.description === ''
                                }
                            />
                        </Div>
                        <Div width="calc(50% - 10px)" paddingLeft="10px">
                            <Input
                                label="Description (EN)"
                                name="translations.en.description"
                                id="translations.en.description"
                                placeholder="Description (EN)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                    values?.translations?.en?.description || ''
                                }
                                error={
                                    values?.translations?.en?.description === ''
                                }
                            />
                        </Div>
                    </Flex>
                    <Flex mb="20px" justifyContent="space-between">
                        <Div width="calc(50% - 10px)" paddingRight="10px">
                            <Input
                                label="Link URL (RU)"
                                name="translations.ru.linkUrl"
                                id="translations.ru.linkUrl"
                                placeholder="Link URL (RU)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.translations?.ru?.linkUrl || ''}
                                error={values?.translations?.ru?.linkUrl === ''}
                            />
                        </Div>
                        <Div width="calc(50% - 10px)" paddingLeft="10px">
                            <Input
                                label="Link URL (EN)"
                                name="translations.en.linkUrl"
                                id="translations.en.linkUrl"
                                placeholder="Link URL (EN)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.translations?.en?.linkUrl || ''}
                                error={values?.translations?.en?.linkUrl === ''}
                            />
                        </Div>
                    </Flex>
                    <Flex mb="20px" justifyContent="space-between">
                        <Div width="calc(50% - 10px)">
                            {values?.translations?.ru?.logoUrl ? (
                                <Div>
                                    <Text
                                        color="textCaptionGrey"
                                        m="30px auto 10px"
                                        variant="body"
                                    >
                                        Logo (RU)
                                    </Text>
                                    <Img
                                        src={
                                            values?.translations?.ru?.logoUrl ||
                                            ''
                                        }
                                        objectFit="cover"
                                        my="20px"
                                        imgWidth="150px"
                                    />
                                    <Text variant="body" textAlign="center">
                                        <Span
                                            color={'solidBlue'}
                                            cursor="pointer"
                                            onClick={() =>
                                                handleFileInputCancelRu(
                                                    setFieldValue,
                                                )
                                            }
                                        >
                                            Cancel
                                        </Span>
                                    </Text>
                                </Div>
                            ) : (
                                <FileInput
                                    id="translations.ru.logo"
                                    value={fileInputDataRu}
                                    title="Logo (RU)"
                                    progress={fileInputProgressRu}
                                    isLoading={isFileInputLoadingRu}
                                    onChange={(file) =>
                                        handleFileInputChangeRu(
                                            file,
                                            setFieldValue,
                                            setFieldTouched,
                                        )
                                    }
                                />
                            )}
                        </Div>
                        <Div width="calc(50% - 10px)">
                            {values?.translations?.en?.logoUrl ? (
                                <Div>
                                    <Text
                                        color="textCaptionGrey"
                                        m="30px auto 10px"
                                        variant="body"
                                    >
                                        Logo (EN)
                                    </Text>
                                    <Img
                                        src={
                                            values?.translations?.en?.logoUrl ||
                                            ''
                                        }
                                        objectFit="cover"
                                        my="20px"
                                        imgWidth="150px"
                                    />
                                    <Text variant="body" textAlign="center">
                                        <Span
                                            color={'solidBlue'}
                                            cursor="pointer"
                                            onClick={() =>
                                                handleFileInputCancelEn(
                                                    setFieldValue,
                                                )
                                            }
                                        >
                                            Cancel
                                        </Span>
                                    </Text>
                                </Div>
                            ) : (
                                <FileInput
                                    id="translations.en.logo"
                                    value={fileInputDataEn}
                                    title="Logo (EN)"
                                    progress={fileInputProgressEn}
                                    isLoading={isFileInputLoadingEn}
                                    onChange={(file) =>
                                        handleFileInputChangeEn(
                                            file,
                                            setFieldValue,
                                            setFieldTouched,
                                        )
                                    }
                                />
                            )}
                        </Div>
                    </Flex>
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        {isError
                            ? 'Error. Try again'
                            : isSuccess
                            ? 'Saved'
                            : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </form>
        </Modal>
    );
};

export default EditSponsorModal;
