/* eslint-disable react/jsx-key */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, {
    ButtonHTMLAttributes,
    DetailedHTMLProps,
    useEffect,
} from 'react';
import { Column, TableHeaderProps, usePagination, useTable } from 'react-table';

import { PlainButton } from '../Buttons';
import Div from '../Div';
import Flex from '../Flex';
import ArrowLeft from '../Icons/ArrowLeft';
import ArrowRight from '../Icons/ArrowRight';
import { Input } from '../Inputs';
import Text from '../Text';
import { Table, Tbody, Td, Th, Thead, Tr } from './PlainTableComponents';

const PageButton: React.FC<
    DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >
> = ({ children, onClick }) => (
    <PlainButton
        onClick={onClick}
        variant="default"
        p="10px 15px"
        border="1px solid"
        borderRadius="2px"
        borderColor="lightGrey"
    >
        {children}
    </PlainButton>
);

const DenseTable = ({
    columns,
    data,
    controlledPageCount,
    setPage,
}: {
    columns: Column[];
    data: any[];
    controlledPageCount: number | undefined;
    setPage: (pageIndex: number) => void;
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        gotoPage,
        pageCount,
        state: { pageIndex },
        prepareRow,
    }: any = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        usePagination,
    );

    useEffect(() => {
        setPage(pageIndex + 1);
    }, [pageIndex]);

    return (
        <Div fontFamily="Universe, Arial" overflow="auto">
            <Flex flexDirection="column" flex="1" m="20px">
                <Div p="0px 20px 20px 20px" backgroundColor="white">
                    <Table textAlign="left" size="100%" {...getTableProps()}>
                        <Thead>
                            {headerGroups.map((headerGroup) => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <Th
                                            {...column.getHeaderProps([
                                                {
                                                    width: column.width,
                                                } as Partial<TableHeaderProps>,
                                            ])}
                                        >
                                            <Text
                                                my="20px"
                                                variant="body"
                                                textTransform="uppercase"
                                            >
                                                {column.render('Header')}
                                            </Text>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {page.length !== 0 && (
                                <>
                                    {page.map((row: any) => {
                                        prepareRow(row);

                                        return (
                                            <Tr
                                                borderTop="1px solid lightGrey"
                                                borderRadius="2px"
                                                {...row.getRowProps()}
                                            >
                                                {row.cells.map((cell: any) => {
                                                    return (
                                                        <Td
                                                            py="12px"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                'Cell',
                                                            )}
                                                        </Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </>
                            )}
                        </Tbody>
                    </Table>
                    <Flex
                        pt="20px"
                        mt="100px"
                        borderTop="1px solid"
                        borderColor="lightGrey"
                        justifyContent="end"
                        alignItems="center"
                    >
                        <Input
                            height="13px"
                            width="20px"
                            value={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;

                                gotoPage(page);
                            }}
                        />
                        <Text
                            ml="10px"
                            variant="body"
                        >{`of ${pageCount}`}</Text>
                        <Div mx="10px">
                            <PageButton onClick={previousPage}>
                                <ArrowLeft width="10px" height="20px" />
                            </PageButton>
                        </Div>
                        <Div ml="10px">
                            <PageButton onClick={nextPage}>
                                <ArrowRight width="12px" height="22px" />
                            </PageButton>
                        </Div>
                    </Flex>
                </Div>
                {page.length === 0 && (
                    <Text
                        width="100%"
                        backgroundColor="white"
                        textAlign="center"
                        variant="body"
                    >
                        Data is empty...
                    </Text>
                )}
            </Flex>
        </Div>
    );
};

export default DenseTable;
