import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { SpaceProps } from 'styled-system';

import Text from '../Text';
import PlainButton from './PlainButton';

const PrimaryButton: React.FC<
    DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > &
        SpaceProps & { isBig?: boolean; width?: string }
> = ({ isBig, disabled, children, width, ...rest }) => {
    return (
        <PlainButton
            {...rest}
            disabled={disabled}
            width={width || 'fit-content'}
            variant={`primary${disabled ? 'Disabled' : ''}`}
            p={isBig ? '17px 72px' : '9px 54px'}
            borderWidth="1px"
            borderStyle="solid"
        >
            <Text variant={isBig ? 'title' : 'body'} color="white">
                {children}
            </Text>
        </PlainButton>
    );
};

export default PrimaryButton;
