import { getProfile } from 'queries';
import React from 'react';
import { useQuery } from 'react-query';
import { matchPath, useHistory } from 'react-router';

const CheckAuth: React.FC = ({ children }) => {
    const history = useHistory();

    const isNotSignInPage: boolean = !matchPath(location.pathname, '/sign-in');

    const { data } = useQuery('user', getProfile, {
        enabled: isNotSignInPage,
        staleTime: 0,
        onSuccess: (data) => {
            if (!data) {
                history.push('/sign-in');
            }
        },
        onError: () => {
            history.push('/sign-in');
        },
    });

    return <>{(!isNotSignInPage || data) && children}</>;
};

export default CheckAuth;
