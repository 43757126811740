import { IAnswer, IQuestion } from 'api-model';
import { deleteAnswer, deleteQuestion } from 'mutations';
import React from 'react';
import { useMutation } from 'react-query';
import {
    DefaultButton,
    Flex,
    Modal,
    PrimaryButton,
    Text,
} from 'src/components';

import { queryClient } from '../../main';

const DeleteModal: React.FC<{
    testId: number;
    item: (IQuestion | IAnswer) & { type: string };
    onClose: () => void;
    isOpened: boolean;
    questionId?: number;
}> = ({ testId, onClose, isOpened, item, questionId }) => {
    const deleteQuestionMutation = useMutation(
        (itemId: number) => deleteQuestion(testId, itemId),
        {
            onSuccess: () => {
                onClose();
                queryClient.invalidateQueries('test-items');
            },
        },
    );

    const deleteAnswerMutation = useMutation(
        (itemId: number) => deleteAnswer(testId, questionId!, itemId),
        {
            onSuccess: () => {
                onClose();
                queryClient.invalidateQueries('test-items');
            },
        },
    );
    const handleDelete = () =>
        item.type === 'answer'
            ? deleteAnswerMutation.mutate(item.id!)
            : deleteQuestionMutation.mutate(item.id!);

    return (
        <Modal
            label={`Удаление ${item.type === 'question' ? 'вопрос' : 'ответ'} ${
                item.id
            }`}
            onClose={onClose}
            isOpened={isOpened}
        >
            {item.type === 'question' &&
            (item as IQuestion).answers!.length > 0 ? (
                <Text variant="body">
                    Для удаления вопроса необходимо удалить все ответы.
                </Text>
            ) : (
                <Text variant="body">
                    Вы уверены, что хотите удалить{' '}
                    {item.type === 'question' ? 'вопрос' : 'ответ'} {item.id} ?
                </Text>
            )}
            <Flex mt="40px">
                <PrimaryButton
                    disabled={
                        item.type === 'question'
                            ? (item as IQuestion).answers!.length > 0
                            : false
                    }
                    onClick={handleDelete}
                    mr="20px"
                    isBig
                >
                    Delete
                </PrimaryButton>
                <DefaultButton onClick={onClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </Modal>
    );
};

export default DeleteModal;
