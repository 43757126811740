import {
    DenseTable,
    Div,
    Flex,
    PrimaryButton,
    TableActions,
    Text,
} from 'components';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getSponsors } from 'src/api/queries';

import EditSponsorModal from './EditSponsorModal';

const Sponsors = () => {
    const [idForModal, setIdForModal] = useState<number | undefined>(undefined);
    const [isModalOpened, toggleModal] = useState(false);
    const columns = useMemo(() => {
        return [
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: 'title en',
                id: 'titleEn',
                Cell: (data: any) =>
                    data.row.original.translations[0]?.title || '',
            },
            {
                Header: 'title ru',
                id: 'titleRu',
                Cell: (data: any) =>
                    data.row.original.translations[1]?.title || '',
            },
            {
                Header: () => null,
                id: 'actions',
                Cell: (data: any) => {
                    return (
                        <TableActions
                            actions={[
                                {
                                    callBack: () => {
                                        handleEdit(data.row.values.id);
                                    },
                                    label: 'Edit',
                                },
                            ]}
                        />
                    );
                },
            },
        ];
    }, []);

    const [page, setPage] = useState(1);

    const { data: sponsors, isLoading } = useQuery(
        ['sponsors', page],
        () => getSponsors(page),
        {
            keepPreviousData: true,
        },
    );

    const pageCount =
        sponsors?.pagination &&
        Math.ceil(sponsors.pagination.total / sponsors.pagination.size);

    const handleEdit = (id: number) => {
        setIdForModal(id);
        toggleModal(!isModalOpened);
    };
    const handleToggleEditModal = () => {
        setIdForModal(undefined);
        toggleModal(!isModalOpened);
    };

    return sponsors ? (
        <Div>
            <Flex
                boxSizing="border-box"
                size="100%"
                backgroundColor="white"
                p="20px"
                justifyContent="center"
                flexDirection="column"
                alignItems="end"
            >
                <PrimaryButton onClick={handleToggleEditModal}>
                    Add Sponsor
                </PrimaryButton>
            </Flex>
            {isLoading ? (
                <Text>Loading...</Text>
            ) : (
                <DenseTable
                    setPage={setPage}
                    columns={columns}
                    controlledPageCount={pageCount}
                    data={sponsors.items}
                />
            )}
            <EditSponsorModal
                sponsorId={idForModal}
                isOpened={isModalOpened}
                onClose={handleToggleEditModal}
            />
        </Div>
    ) : (
        <></>
    );
};

export default Sponsors;
