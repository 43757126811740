import { IAuthCredentials } from 'auth-model';
import { ICourse } from 'src/model/types/api/Courses';
import { setTokens } from 'utils';
import { IUser } from 'user-model';
import axiosInstance from 'src/api/axios';

import { IMedia } from 'src/model/types/api/Media';
import {
    IEditFormat,
    IEditLocation,
    IFormat,
    ILocation,
    IEditSponsor,
    ISponsor,
    IPerson,
    IPersonItem,
    IEvent,
    ICourseContent,
} from 'src/model/types/api';

export const getProgrammes = async () => {
    const { data } = await axiosInstance.get('programmes?pagination[size]=1000');

    return data;
};

export const getProfile = async (): Promise<IUser> => {
    const { data } = await axiosInstance.get('users/current', {
        withoutLocale: true,
    });

    return data;
};

export const getCourseStatistics = async (
    courseId?: number,
    from?: string,
    to?: string): Promise<any> => {
        const { data } = await axiosInstance.get(`/courses-stats`,{ params: {
            'filter[courseId]': courseId,
            'from': from,
            'to': to,
        } });

        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        element.setAttribute('download', 'statistics.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
};
export const getCourses = async (page?: number, size?: number): Promise<ICourse> => {
    const query = page ? (size ? `?pagination[page]=${page}&pagination[size]=${size}` : `?pagination[page]=${page}`) : '?pagination[size]=1000';
    const { data } = await axiosInstance.get(`/courses${query}`);

    return data
};
export const getCourse = async (courseId?: number):Promise<any> => {
    const { data } = await axiosInstance.get(`/courses/${courseId}`);

    return data
};
export const getCourseItems = async (courseId: number, page?: number, size?: number):Promise<ICourseContent> => {
    const { data } = await axiosInstance.get(`/course-items/`,
        { params: {
            'filter[courseId]': courseId,
            'pagination[page]': page,
            'pagination[size]': size,
        } });

    return data
};

export const getCourseItemsSlugs = async ():Promise<any> => {
    const { data } = await axiosInstance.get(`/course-items/slugs`);

    return data
};
export const getItem = async (itemId?: number):Promise<any> => {
    const { data } = await axiosInstance.get(`/course-items/${itemId}`);

    return data
};

export const getTestQuestions = async (id: number):Promise<any> => {
    const { data } = await axiosInstance.get(`/course-tests/${id}/questions`);

    return data
};

// /course-tests/{id}/questions
// test/:id
// /:id/questions
export const getCourseMaterialsList = async (materialId?: number):Promise<any> => {
    const { data } = await axiosInstance.get(`/course-materials/${materialId}/materials`);

    return data
};
export const getMaterials = async ():Promise<any> => {
    const { data } = await axiosInstance.get(`/materials?pagination[size]=100`,
    );

    return data.items
};

export const getAnnouncements = async (): Promise<any[]> => {
    const { data } = await axiosInstance.get('/announcements');

    return data.items
};
export const getAnnouncementsQueried = async (language: string): Promise<any[]> => {
    const { data } = await axiosInstance.get('/announcements/banners', {
        headers: {
            'x-language': language
        }
    });

    return data.items
};

export const getAnnouncement = async (announcementId: string): Promise<any> => {
    const { data } = await axiosInstance.get(`/announcements/${announcementId}`);

    return data
};

export const signIn = async (credentials: IAuthCredentials) => {
    try {
        const { data } = await axiosInstance.post('auth/login',{...credentials});
        setTokens(data)
        return data;
    }
    catch (error) {
        throw new Error(error);
    }
};

export const getFormats = async (page?: number):Promise<IFormat> => {
    const { data } = await axiosInstance.get(`/ru/formats${page
        ? `?pagination[page]=${page}`
        : '?pagination[size]=1000'}`);

    return data
};

export const getFormat = async (formatId?: number):Promise<IEditFormat> => {
    const { data } = await axiosInstance.get(`/ru/formats/${formatId}`);

    return data
};

export const getPersons = async (page?: number): Promise<IPerson> => {
    const { data } = await axiosInstance.get(`/persons${page
        ? `?pagination[page]=${page}`
        : '?pagination[size]=1000'}`);

    return data
};

export const getPerson = async (personId?: number):Promise<IPersonItem> => {
    const { data } = await axiosInstance.get(`/persons/${personId}`);

    return data
};

export const getMedia = async ():Promise<IMedia[]> => {
    const { data } = await axiosInstance.get('/media-library/items');

    return data.items
};

export const getTopics = async (query = '') => {
    const { data } = await axiosInstance(`topics${query && `?${query}`}`);

    return data;
};

export const getLocations = async (page?: number):Promise<ILocation> => {
    const { data } = await axiosInstance.get(`/ru/locations${page ? `?pagination[page]=${page}` : ''}`);

    return data
};
export const getLocation = async (locationId?: number):Promise<IEditLocation> => {
    const { data } = await axiosInstance.get(`/ru/locations/${locationId}`);

    return data
};

export const getSponsors = async (page?: number):Promise<ISponsor> => {
    const { data } = await axiosInstance.get(`/sponsors${page
        ? `?pagination[page]=${page}`
        : '?pagination[size]=1000'}`);

    return data;
};
export const getSponsor = async (sponsorId?: number):Promise<IEditSponsor> => {
    const { data } = await axiosInstance.get(`/sponsors/${sponsorId}`);

    return data
};

export const getEvents = async (): Promise<IEvent[]> => {
    const { data } = await axiosInstance.get('/events');

    return data.items;
};
export const getEvent = async (eventId?: number):Promise<IEvent> => {
    const { data } = await axiosInstance.get(`/events/${eventId}`);

    return data
};
export const getRoles = async (): Promise<any[]> => {
    const { data } = await axiosInstance.get(`/roles?pagination[size]=1000`);

    return data.items;
};
