import 'react-sortable-tree/style.css';

import styled from '@emotion/styled';
import {
    Alert,
    DefaultButton,
    Div,
    Flex,
    IconClose,
    PlainInput,
    PrimaryButton,
    Text,
} from 'components';
import { updateAnswersOrder, updateQuestionsOrder } from 'mutations';
import { getTestQuestions } from 'queries';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import { getTranslation } from 'src/utils';

import { queryClient } from '../../main';
import AnswerForm from './AnswerForm';
import DeleteModal from './DeleteModal';
import QuestionForm from './QuestionForm';

const TreeWrapper = styled.div({
    marginLeft: '-45px',
    padding: '20px',
    '.rstcustom__rowContents': {
        width: '100%',
        whiteSpace: 'normal',
    },
    '.rstcustom__rowSubtitle': {
        fontSize: '100%',
        lineHeight: 1,
    },
    '.rstcustom__nodeContent': {
        padding: 0,
        width: 'fill-available',
    },
});

const TestContent = (): JSX.Element => {
    const [questionId, setQuestionId] = useState(undefined);
    const [editingNode, setEditingNode] = useState(undefined);

    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const history = useHistory();

    const [contentItemType, setContentItemType] = useState<string | undefined>(
        undefined,
    );

    const { testId }: { testId: any } = useParams();

    const { data: testItems } = useQuery(
        ['test-items', testId],
        () => getTestQuestions(testId!),
        { enabled: !!testId },
    );

    const [isDeleteModalOpened, toggleDeleteModal] = useState(false);

    const [treeData, setTreeData] = useState<any>([]);

    useEffect(() => {
        const contentWithKeys =
            testItems?.items.map((question: any) => ({
                ...question,
                title: getTranslation(question, 'ru')?.title || '',
                type: 'question',
                expanded: true,
                children: question.answers.map((answer: any) => ({
                    ...answer,
                    title: getTranslation(answer, 'ru')?.title || '',
                    subtitle: answer.isCorrect && 'правильный ответ',
                    type: 'answer',
                })),
            })) || [];

        setTreeData(contentWithKeys);
    }, [testItems]);

    const itemOrder = useMutation(
        (item: any) => updateQuestionsOrder(testId, item),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('test-items');
            },
            // TODO: create error hook
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );
    const answerOrder = useMutation(
        (item: any) => updateAnswersOrder(testId, item.questionId, item),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('test-items');
            },
            // TODO: create error hook
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );

    const handleEditContentItem = (type, node?, questionId?) => {
        setEditingNode(node);
        setContentItemType(type);
        setQuestionId(questionId);
    };
    const handleDeleteClick = (node) => {
        setEditingNode(node);
        toggleDeleteModal(true);
    };

    const handleClose = () => {
        setContentItemType(undefined);
        isDeleteModalOpened && toggleDeleteModal(false);
    };

    return testItems ? (
        <Div>
            {isError && <Alert message={errorMessage} />}
            <Flex
                p="20px"
                backgroundColor="white"
                justifyContent="space-between"
            >
                <PlainInput variant="plain" placeholder="Search" />
                <PrimaryButton
                    onClick={() => handleEditContentItem('question')}
                >
                    Add Question
                </PrimaryButton>
            </Flex>
            <Flex width="100%" flex="1" justifyContent="center">
                <Text
                    variant="h4"
                    fontSize="16px"
                    textTransform="uppercase"
                    mt="20px"
                    ml="auto"
                >
                    {testId} test items
                </Text>
                <Flex
                    ml="auto"
                    mr="10px"
                    onClick={() => history.push('/courses')}
                    p="20px"
                    cursor="pointer"
                >
                    <IconClose stroke={'black'} />
                </Flex>
            </Flex>
            <TreeWrapper>
                <SortableTree
                    treeData={treeData}
                    isVirtualized={false}
                    theme={FileExplorerTheme}
                    onChange={(treeData) => {
                        setTreeData(treeData);
                    }}
                    generateNodeProps={(rowInfo) => ({
                        buttons: [
                            rowInfo.node.type === 'question' && (
                                <DefaultButton
                                    px="10px"
                                    mr="10px"
                                    key={rowInfo.node.id}
                                    onClick={() =>
                                        handleEditContentItem(
                                            'answer',
                                            undefined,
                                            rowInfo.node.id,
                                        )
                                    }
                                >
                                    <Text whiteSpace="nowrap">Add Answer</Text>
                                </DefaultButton>
                            ),
                            <DefaultButton
                                px="10px"
                                mr="10px"
                                key={rowInfo.node.id}
                                onClick={() =>
                                    handleEditContentItem(
                                        rowInfo.node.type,
                                        rowInfo.node,
                                        rowInfo.node.type === 'answer'
                                            ? rowInfo.parentNode.id
                                            : rowInfo.node.id,
                                    )
                                }
                            >
                                Edit
                            </DefaultButton>,
                            <DefaultButton
                                // TODO: color theme for btns
                                px="10px"
                                key={rowInfo.node.id}
                                onClick={() => handleDeleteClick(rowInfo.node)}
                            >
                                Delete
                            </DefaultButton>,
                        ],
                    })}
                    canDrop={({ node, prevParent, nextParent }) => {
                        if (
                            (node?.type === 'question' &&
                                nextParent?.type === 'question') ||
                            (node?.type === 'question' &&
                                nextParent?.type === 'answer') ||
                            (node?.type === 'answer' &&
                                prevParent?.id !== nextParent?.id) ||
                            (node?.type === 'answer' &&
                                nextParent?.type === 'answer')
                        ) {
                            return false;
                        }

                        return true;
                    }}
                    canNodeHaveChildren={(node) =>
                        node.type === 'answer' ? false : true
                    }
                    onMoveNode={({ node, nextParentNode, treeData }) =>
                        node?.type === 'answer'
                            ? answerOrder.mutate({
                                  questionId: nextParentNode?.id,
                                  order: nextParentNode?.children?.map(
                                      (child, index) => ({
                                          id: child.id,
                                          number: index,
                                      }),
                                  ),
                              })
                            : itemOrder.mutate({
                                  order: treeData.map((child, index) => ({
                                      id: child.id,
                                      number: index,
                                  })),
                              })
                    }
                />
            </TreeWrapper>
            {contentItemType === 'question' && (
                <QuestionForm
                    testId={testId}
                    question={editingNode}
                    onClose={handleClose}
                    isOpened
                />
            )}
            {contentItemType === 'answer' && questionId && (
                <AnswerForm
                    testId={testId}
                    questionId={questionId!}
                    answer={editingNode}
                    onClose={handleClose}
                    isOpened
                />
            )}
            {editingNode && (
                <DeleteModal
                    testId={testId}
                    item={editingNode!}
                    questionId={questionId}
                    onClose={handleClose}
                    isOpened={isDeleteModalOpened}
                />
            )}
        </Div>
    ) : (
        <></>
    );
};

export default TestContent;
