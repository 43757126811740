import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const ArrowRight: React.VoidFunctionComponent<CustomSvgProps> = (props) => (
    <Svg
        {...props}
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 1L11 11L1 21"
            stroke="#BCC3CA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

ArrowRight.defaultProps = {
    width: '12px',
    height: '22px',
};

export default ArrowRight;
