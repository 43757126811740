import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const ArrowLeft: React.VoidFunctionComponent<CustomSvgProps> = (props) => (
    <Svg
        {...props}
        viewBox="0 0 12 22"
        width="12px"
        height="22px"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11 21L1 11L11 1"
            stroke="#BCC3CA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Svg>
);

export default ArrowLeft;
