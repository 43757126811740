import {
    DenseTable,
    Div,
    Flex,
    Img,
    LanguageToggle,
    PrimaryButton,
    TableActions,
    Text,
} from 'components';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as Showdown from 'showdown';
import { updatePerson, uploadPersonPhoto } from 'src/api/mutations';
import { getPersons } from 'src/api/queries';
import { filterByKeys } from 'src/utils';

const converter = new Showdown.Converter({});

import { queryClient } from '../../main';
import { IPersonItem } from '../../model/types/api/Person';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import { Description, FileInputBase, Label } from './styled';

const Persons = () => {
    const [idForModal, setIdForModal] = useState<number | undefined>(undefined);
    const [isModalOpened, toggleModal] = useState(false);
    const [isDeleteModalOpened, toggleDeleteModal] = useState(false);
    const [chosenLang, setChosenLang] = useState('en');
    const [page, setPage] = useState(1);
    const { data: persons, isLoading } = useQuery(
        ['persons', page],
        () => getPersons(page),
        {
            keepPreviousData: true,
        },
    );
    const [fileInputData, setFileInputData] = useState<
        (string & FileList) | undefined
    >();

    const pageCount =
        persons?.pagination &&
        Math.ceil(persons.pagination.total / persons.pagination.size);

    const toggleChosenLang = (lang: string) => {
        setChosenLang(lang);
    };

    const photoMutation = useMutation((fileInputData: FileList) =>
        uploadPersonPhoto(fileInputData),
    );

    const personMutation = useMutation(
        (Person: IPersonItem) => updatePerson(Person),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('persons');
            },
        },
    );

    const uploadImage = (files: FileList, data: IPersonItem) => {
        setFileInputData(files as (string & FileList) | undefined);
        photoMutation.mutate(files, {
            onSuccess: (value) => {
                data.photo = value;
                personMutation.mutate(data);
            },
        });
    };

    const columns = useMemo(() => {
        return [
            {
                Header: 'photo',
                id: 'photo',
                Cell: (data: any) => {
                    return (
                        <Flex>
                            <Img
                                src={data.row.original.photo}
                                imgHeight="44px"
                                imgWidth="44px"
                                objectFit="cover"
                                mr="15px"
                            />
                            <Flex
                                alignItems="center"
                                height="inherit"
                                maxHeight="100%"
                            >
                                <Label htmlFor={data.row.original.id}>
                                    <FileInputBase
                                        type="file"
                                        value={fileInputData}
                                        onChange={(e) =>
                                            e.target.files &&
                                            uploadImage(
                                                e.target.files,
                                                data.row.original,
                                            )
                                        }
                                        id={data.row.original.id}
                                    />
                                    <Text
                                        variant="body"
                                        color="solidBlue"
                                        cursor="pointer"
                                    >
                                        {data.row.original.photo
                                            ? 'Change'
                                            : 'Upload'}
                                    </Text>
                                </Label>
                            </Flex>
                        </Flex>
                    );
                },
                width: 195,
            },
            {
                Header: 'name',
                id: 'name',
                Cell: (data: any) =>
                    data.row.original.translations[0]?.title || '',
                width: 235,
            },
            {
                Header: 'role',
                id: 'role',
                Cell: (data: any) =>
                    data.row.original.translations[0]?.occupation || '',
                width: 235,
            },
            {
                Header: 'description',
                id: 'description',
                Cell: (data: any) => (
                    <Description>
                        {`${converter.makeMarkdown(
                            data.row.original.translations[0]?.description.substring(
                                0,
                                50,
                            ),
                        )}${
                            data.row.original.translations[0]?.description
                                .length >= 50
                                ? '...'
                                : ''
                        }` || ''}
                        <Text>
                            {converter.makeMarkdown(
                                data.row.original.translations[0]?.description,
                            )}
                        </Text>
                    </Description>
                ),
                width: 470,
            },
            {
                Header: () => null,
                id: 'actions',
                Cell: (data: any) => {
                    return (
                        <TableActions
                            actions={[
                                {
                                    callBack: () => {
                                        handleEdit(data.row.original.id);
                                    },
                                    label: 'Edit',
                                },
                                {
                                    callBack: () =>
                                        handleDelete(data.row.original.id),
                                    label: 'Delete',
                                },
                            ]}
                        />
                    );
                },
                width: 80,
            },
        ];
    }, []);

    const filteredPersons = persons?.items
        ? filterByKeys(persons?.items, ['id', 'photo', 'translations'], true)
        : [];

    filteredPersons.map((person) => {
        person.translations = new Array(1).fill(
            person.translations?.find(
                (translation) => translation.language === chosenLang,
            ),
        );
    });

    const handleEdit = (id: number) => {
        setIdForModal(id);
        toggleModal(!isModalOpened);
    };
    const handleDelete = (id: number | undefined) => {
        setIdForModal(id);
        toggleDeleteModal(!isModalOpened);
    };
    const handleToggleEditModal = () => {
        setIdForModal(undefined);
        toggleModal(!isModalOpened);
    };
    const handleToggleDeleteModal = () => {
        setIdForModal(undefined);
        toggleDeleteModal(!isDeleteModalOpened);
    };

    return (
        <Div>
            <Flex
                boxSizing="border-box"
                size="100%"
                backgroundColor="white"
                p="20px"
                justifyContent="center"
                flexDirection="column"
                alignItems="end"
            >
                <PrimaryButton onClick={handleToggleEditModal}>
                    Add Person
                </PrimaryButton>
            </Flex>
            {toggleChosenLang && (
                <Flex justifyContent="flex-end" p="20px 20px 0 0">
                    <LanguageToggle
                        chosenLang={chosenLang}
                        toggleChosenLang={toggleChosenLang}
                    />
                </Flex>
            )}
            {isLoading ? (
                <Text>Loading...</Text>
            ) : (
                <DenseTable
                    columns={columns}
                    data={filteredPersons}
                    setPage={setPage}
                    controlledPageCount={pageCount}
                />
            )}
            <EditModal
                personId={idForModal}
                isOpened={isModalOpened}
                onClose={handleToggleEditModal}
                lang={chosenLang}
            />
            <DeleteModal
                personId={idForModal}
                isOpened={isDeleteModalOpened}
                onClose={handleToggleDeleteModal}
            />
        </Div>
    );
};

export default Persons;
