import React, { useEffect, useState } from 'react';
import { LayoutProps, PositionProps, SpaceProps } from 'styled-system';

import { ArrowDownIcon, ArrowUpIcon, Div, Flex, IconCheck, Text } from '../';

type MultiSelectInputProps = {
    value?: string[];
    id?: string;
    name?: string;
    suggestions: (string | number)[];
    placeholder: string;
    onChange: (value: (string | number)[] | undefined) => void;
} & SpaceProps &
    LayoutProps &
    PositionProps;

const MultiSelectInput: React.FC<MultiSelectInputProps> = (props) => {
    const [values, setValue] = useState<(string | number)[] | undefined>(
        props.value || undefined,
    );
    const [isOpen, setOpened] = useState(false);

    function handleSuggestionClick(suggestion: string | number) {
        if (values?.includes(suggestion)) {
            setValue(values.filter((_value) => _value !== suggestion));
        } else {
            setValue(values ? [...values, suggestion] : [suggestion]);
        }
    }

    useEffect(() => {
        if (values !== undefined) {
            const event: any = {
                target: { name: undefined, value: undefined },
            };

            event.target.name = props.name;
            event.target.value = values;
            props.onChange(event);
        }
    }, [values]);

    useEffect(() => {
        if (props.value !== undefined) {
            setValue(props.value);
        }
    }, [props.value]);

    return (
        <Div
            position={'relative'}
            padding={'9px 10px'}
            backgroundColor={'transparent'}
            width={'fit-content'}
            minWidth={'230px'}
            boxSizing={'border-box'}
            border={'#DCDDE2 1px solid'}
            {...(isOpen && {
                zIndex: 999,
                backgroundColor: 'menuGrey',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
            })}
        >
            <Flex
                minHeight={'32px'}
                boxSizing={'border-box'}
                padding={'9px 10px'}
                backgroundColor={'white'}
                alignItems="center"
                justifyContent="space-between"
                cursor="pointer"
                onClick={() => setOpened(!isOpen)}
            >
                <Text variant="body">
                    {values && values.length > 0
                        ? values.toString().replace(/,/g, ', ')
                        : props.placeholder}
                </Text>
                {isOpen ? (
                    <ArrowUpIcon width="15px" height="10px" />
                ) : (
                    <ArrowDownIcon width="15px" height="10px" />
                )}
            </Flex>
            {isOpen && (
                <Div
                    position={'absolute'}
                    width={'100%'}
                    marginLeft={'-10px'}
                    padding={'10px'}
                    zIndex={999}
                    backgroundColor={'menuGrey'}
                    boxSizing={'border-box'}
                    borderBottomLeftRadius={'4px'}
                    borderBottomRightRadius={'4px'}
                >
                    {props.suggestions.map((_suggestion, index) => (
                        <Flex
                            paddingTop="10px"
                            paddingBottom="10px"
                            key={index}
                            cursor="pointer"
                            onClick={() => handleSuggestionClick(_suggestion)}
                        >
                            <Flex
                                width={'12px'}
                                height={'12px'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                borderRadius={'10px'}
                                boxSizing={'border-box'}
                                marginRight={'10px'}
                                backgroundColor={'white'}
                                {...(!!values?.includes(_suggestion) && {
                                    border: 'none',
                                    backgroundColor: 'solidBlue',
                                })}
                            >
                                <IconCheck width="8px" height="6px" />
                            </Flex>
                            <Text variant="body">{_suggestion}</Text>
                        </Flex>
                    ))}
                </Div>
            )}
        </Div>
    );
};

export default MultiSelectInput;
