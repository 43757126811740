/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useFormik } from 'formik';
import React, { useState } from 'react';
import ReactMde from 'react-mde';
import { useMutation, useQuery } from 'react-query';
import * as Showdown from 'showdown';
import {
    createPerson,
    updatePerson,
    uploadPersonPhoto,
} from 'src/api/mutations';
import { getPerson } from 'src/api/queries';
import {
    DefaultButton,
    Div,
    FileInput,
    Flex,
    Input,
    LanguageToggle,
    Modal,
    PrimaryButton,
    Text,
} from 'src/components';
import { IPersonItem, IPersonTranslation } from 'src/model/types/api';
import { IFormik } from 'src/model/types/Formik';
import * as Yup from 'yup';

import { queryClient } from '../../main';
import { Form } from './styled';

interface IPersonModal {
    onClose: () => void;
    isOpened: boolean;
    personId: number | undefined;
    lang: string;
}

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

const EditModal: React.FC<IPersonModal> = ({
    onClose,
    isOpened,
    personId,
    lang,
}) => {
    const handleClose = () => {
        formik.resetForm();
        onClose();
    };
    const { data: person } = useQuery(
        ['persons', personId],
        () => getPerson(personId!),
        { enabled: !!personId },
    );
    const [chosenLang, setChosenLang] = useState(lang);

    const toggleChosenLang = (lang: string) => {
        setChosenLang(lang);
    };

    const [isFileInputLoading, setFileInputLoading] = useState(false);
    const [fileInputProgress, setFileInputProgress] = useState(0);
    const [fileInputData, setFileInputData] = useState<
        (string & FileList) | undefined
    >();

    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
        'write',
    );

    function handleFileInputChange(file: FileList) {
        setFileInputLoading(true);
        setFileInputProgress(100);
        setFileInputData(file as (string & FileList) | undefined);
    }

    function handleFileInputCancel() {
        setFileInputLoading(false);
        setFileInputProgress(0);
        setFileInputData(undefined);
    }

    const createPersonMutation = useMutation(
        (Person: IPersonItem) => createPerson(Person),
        {
            onSuccess: () => {
                handleClose();
                formik.resetForm();
                queryClient.invalidateQueries('persons');
            },
        },
    );
    const personMutation = useMutation(
        (Person: IPersonItem) => updatePerson(Person),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('persons');
            },
        },
    );
    const photoMutation = useMutation((fileInputData: FileList) =>
        uploadPersonPhoto(fileInputData),
    );
    const getTranslation = (data: IPersonItem, lang: string) => {
        return data?.translations.find(
            (translation: IPersonTranslation) => translation.language === lang,
        );
    };
    const formik: IFormik = useFormik({
        initialValues: {
            photo:
                person?.photo ||
                'https://cdn-arch-strelka.gcdn.co/public_store/uploads/online/expert/46/photo_image/main-658179db565250cf7dacb8fccc6e9a9a.jpg',
            translations: {
                en: {
                    title: person ? getTranslation(person, 'en')?.title : null,
                    occupation: person
                        ? getTranslation(person, 'en')?.occupation
                        : null,
                    description: person
                        ? converter.makeMarkdown(
                              getTranslation(person, 'en')?.description,
                          )
                        : '',
                },
                ru: {
                    title: person ? getTranslation(person, 'ru')?.title : null,
                    occupation: person
                        ? getTranslation(person, 'ru')?.occupation
                        : null,
                    description: person
                        ? converter.makeMarkdown(
                              getTranslation(person, 'ru')?.description,
                          )
                        : '',
                },
            },
        },
        enableReinitialize: true,
        validateOnMount: !personId,
        validationSchema: Yup.object().shape({
            translations: Yup.object().shape({
                en: Yup.object().shape({
                    title: Yup.string().required(),
                    occupation: Yup.string().required(),
                    description: Yup.string(),
                }),
                ru: Yup.object().shape({
                    title: Yup.string().required(),
                    occupation: Yup.string().required(),
                    description: Yup.string(),
                }),
            }),
        }),
        onSubmit: (data) => {
            const resultPerson: IPersonItem = {
                id: personId,
                photo: data.photo!,
                translations: [
                    {
                        language: 'en',
                        title: data.translations.en.title!,
                        occupation: data.translations.en.occupation!,
                        description: converter.makeHtml(
                            data.translations.en.description!,
                        ),
                    },
                    {
                        language: 'ru',
                        title: data.translations.ru.title!,
                        occupation: data.translations.ru.occupation!,
                        description: converter.makeHtml(
                            data.translations.ru.description!,
                        ),
                    },
                ],
            };

            if (fileInputData) {
                photoMutation.mutate(fileInputData, {
                    onSuccess: (value) => {
                        resultPerson.photo = value;
                        submitPerson(resultPerson);
                    },
                });
            } else {
                submitPerson(resultPerson);
            }
        },
    });

    const submitPerson = (person: IPersonItem) => {
        if (person.id) {
            personMutation.mutate(person);
        } else {
            createPersonMutation.mutate(person);
        }
    };

    const { isValid, handleBlur, handleSubmit, handleChange, values } = formik;

    return (
        <Modal
            label={
                personId && person
                    ? getTranslation(person, chosenLang)?.title ||
                      person.translations[0].title
                    : 'Create person'
            }
            onClose={handleClose}
            isOpened={isOpened}
        >
            <Flex justifyContent="center" p="20px" alignItems="center">
                <Text variant="body" color="#7F8A98" mr="10px">
                    Edit in
                </Text>
                <LanguageToggle
                    chosenLang={chosenLang}
                    toggleChosenLang={toggleChosenLang}
                />
            </Flex>
            <Form onSubmit={handleSubmit}>
                <Div mb="20px">
                    <Input
                        label="Name"
                        name={`translations.${chosenLang}.title`}
                        id={`translations.${chosenLang}.title`}
                        placeholder="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.translations?.[chosenLang]?.title || ''}
                        error={values?.translations?.[chosenLang]?.title === ''}
                    />
                </Div>
                <Div mb="20px">
                    <Input
                        label="Occupation"
                        name={`translations.${chosenLang}.occupation`}
                        id={`translations.${chosenLang}.occupation`}
                        placeholder="occupation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                            values?.translations?.[chosenLang]?.occupation || ''
                        }
                        error={
                            values?.translations?.[chosenLang]?.occupation ===
                            ''
                        }
                    />
                </Div>
                <Div mb="20px">
                    <Text variant="body" mt="20px" mb="10px">
                        {lang === 'en' ? 'Description' : 'Описание'}
                    </Text>
                    <ReactMde
                        value={
                            values?.translations?.[chosenLang]?.description ||
                            ''
                        }
                        onChange={handleChange(
                            `translations.${chosenLang}.description`,
                        )}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        toolbarCommands={[['bold', 'italic', 'link']]}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(converter.makeHtml(markdown))
                        }
                    />
                </Div>
                <Div mb="20px">
                    <FileInput
                        id="tutorPhoto"
                        value={fileInputData}
                        title="Photo"
                        progress={fileInputProgress}
                        isLoading={isFileInputLoading}
                        onChange={handleFileInputChange}
                        onCancel={handleFileInputCancel}
                    />
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        Save
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </Form>
        </Modal>
    );
};

export default EditModal;
