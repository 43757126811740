import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import * as CSS from 'csstype';
import {
    color,
    ColorProps,
    compose,
    layout,
    LayoutProps,
    position,
    PositionProps,
    space,
    SpaceProps,
} from 'styled-system';

export type CustomSvgProps = {
    width?: CSS.Property.Width;
    display?: CSS.Property.Display;
    height?: CSS.Property.Height;
    fill?: CSS.Property.Fill;
    fillOpacity?: CSS.Property.FillOpacity;
    stroke?: CSS.Property.Stroke;
};

export type SvgProps = SpaceProps &
    ColorProps &
    LayoutProps &
    SpaceProps &
    PositionProps;

const Svg = styled('svg', { shouldForwardProp })<SvgProps>(
    compose(layout, space, color, position),
    ({ fill }) => ({ fill: fill || 'currentcolor' }),
);

export default Svg;
