import React, { ReactElement, useRef, useState } from 'react';
import { useOnClickOutside } from 'src/utils/hooks';

import { ArrowDownIcon, ArrowUpIcon, Div, Flex, Li, Text, Ul } from '..';

type DropdownInputProps = {
    value?: string | number;
    id?: string;
    name?: string;
    label?: string;
    valueTitle?: string;
    placeholder: string;
    minWidth?: string;
    onChange: (event: React.MouseEvent) => void;
};

const areEqualValues = (a: unknown, b: unknown) => {
    if (typeof b === 'object' && b !== null) {
        return a === b;
    }

    return String(a) === String(b);
};

const Option: React.FC<{ onClick: () => void; selected: boolean }> = ({
    children,
    onClick,
    selected,
}) => {
    return (
        <Li
            display="flex"
            paddingBottom="10px"
            paddingTop="10px"
            cursor="pointer"
            onClick={onClick}
        >
            <Div
                width="12px"
                height="12px"
                borderRadius="10px"
                boxSizing="border-box"
                marginRight="10px"
                backgroundColor="white"
                {...(selected && {
                    border: 'none',
                    backgroundColor: 'solidBlue',
                })}
            />
            <Text variant="body">{children}</Text>
        </Li>
    );
};

const Select: React.FC<DropdownInputProps> & {
    Option: React.FC<any>;
} = (props) => {
    const [isOpen, setOpened] = useState(false);
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    const toggleOpen = () => {
        setOpened(!isOpen);
    };

    useOnClickOutside(ref, () => setOpened(false));

    const childrenArray = React.Children.toArray(props.children);
    const handleItemClick = (child: ReactElement) => (event: any) => {
        const newValue = child.props.value;

        if (child.props.onClick) {
            child.props.onClick(event);
        }
        // TODO: make controllable

        // if (value !== newValue) {
        // setValueState(newValue);

        // Redefine target to allow name and value to be read.
        // This allows seamless integration with the most popular form libraries.
        // https://github.com/mui-org/material-ui/issues/13485#issuecomment-676048492
        // Clone the event to not override `target` of the original event.
        const nativeEvent = event.nativeEvent || event;
        const clonedEvent = new nativeEvent.constructor(
            nativeEvent.type,
            nativeEvent,
        );

        Object.defineProperty(clonedEvent, 'target', {
            writable: true,
            value: { value: newValue, name: props.name },
        });
        props.onChange(clonedEvent);
    };
    const items = childrenArray.map((child) => {
        if (!React.isValidElement(child)) {
            return null;
        }

        const isSelected = areEqualValues(props.value, child.props.value);

        return React.cloneElement(child, {
            'aria-selected': isSelected || undefined,
            onClick: handleItemClick(child),
            onKeyUp: (event: KeyboardEvent) => {
                if (event.key === ' ') {
                    event.preventDefault();
                }

                if (child.props.onKeyUp) {
                    child.props.onKeyUp(event);
                }
            },
            role: 'option',
            selected: isSelected,
            value: undefined, // The value is most likely not a valid HTML attribute.
            'data-value': child.props.value, // Instead, we provide it as a data attribute.
        });
    });

    return (
        <Div ref={ref}>
            {props.label && (
                <Text mt="30px" mb="10px" variant="body">
                    {props.label}
                </Text>
            )}
            <Div
                position="relative"
                padding="9px 10px"
                backgroundColor="transparent"
                width={'fit-content'}
                minWidth={props.minWidth ? props.minWidth : '230px'}
                boxSizing="border-box"
                border={'#DCDDE2 1px solid'}
                {...(isOpen && {
                    zIndex: 999,
                    backgroundColor: 'menuGrey',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
                })}
            >
                <Flex
                    minHeight="32px"
                    boxSizing="border-box"
                    padding="9px 10px"
                    backgroundColor="white"
                    alignItems="center"
                    justifyContent="space-between"
                    cursor="pointer"
                    onClick={toggleOpen}
                >
                    <Text variant="body">
                        {props.valueTitle || props.value || props.placeholder}
                    </Text>
                    {isOpen ? (
                        <ArrowUpIcon width="15px" height="10px" />
                    ) : (
                        <ArrowDownIcon width="15px" height="10px" />
                    )}
                </Flex>
                {isOpen && (
                    <Ul
                        position="absolute"
                        width="100%"
                        marginLeft="-10px"
                        padding="10px"
                        zIndex={999}
                        backgroundColor="menuGrey"
                        boxSizing="border-box"
                        borderBottomLeftRadius="4px"
                        borderBottomRightRadius="4px"
                    >
                        {items}
                    </Ul>
                )}
            </Div>
        </Div>
    );
};

Select.Option = Option;

export default Select;
