import React from 'react';
import { useMutation } from 'react-query';
import { deleteLocation } from 'src/api/mutations';
import {
    DefaultButton,
    Flex,
    Modal,
    PrimaryButton,
    Text,
} from 'src/components';

import { queryClient } from '../../main';

const DeleteLocationModal: React.FC<{
    locationId: number | undefined;
    onClose: () => void;
    isOpened: boolean;
}> = ({ locationId, onClose, isOpened }) => {
    const deleteLocationMutation = useMutation(
        (locationId: number) => deleteLocation(locationId),
        {
            onSuccess: () => {
                onClose();
                queryClient.invalidateQueries('locations');
            },
        },
    );
    const handleDelete = () => {
        locationId && deleteLocationMutation.mutate(locationId);
    };

    return (
        <Modal
            label={`Удаление локации ${locationId}`}
            onClose={onClose}
            isOpened={isOpened}
        >
            <Text variant="body">
                Вы уверены, что хотите удалить локацию {locationId} ?
            </Text>
            <Flex mt="40px">
                <PrimaryButton onClick={handleDelete} mr="20px" isBig>
                    Delete
                </PrimaryButton>
                <DefaultButton onClick={onClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </Modal>
    );
};

export default DeleteLocationModal;
