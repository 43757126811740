/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ICourseMaterialList, IMaterial } from 'api-model';
import {
    Alert,
    DefaultButton,
    Div,
    FileInput,
    Flex,
    Input,
    Modal,
    MultiSelectInput,
    PrimaryButton,
    Tabs,
    Text,
} from 'components';
import { useFormik } from 'formik';
import { IFormik } from 'formik-model';
import { createItem, createMaterial, updateItem, uploadFile } from 'mutations';
import { getCourseItemsSlugs, getItem, getMaterials } from 'queries';
import React, { useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useMutation, useQuery } from 'react-query';
import slugify from 'slugify';
import { getTranslation } from 'utils';
import * as Yup from 'yup';

import { queryClient } from '../../main';

interface IItemFormProps {
    onClose: () => void;
    courseId: number;
    materialId: number | undefined;
    isOpened: boolean;
}

const MaterialsList = ({
    item,
    courseId,
    materialId,
    submitItem,
    handleClose,
    isSaved,
    setSaved,
}) => {
    const { data: materials, isLoading } = useQuery(['materials'], () =>
        getMaterials(),
    );
    const { data: slugs, isLoading: isSlugsLoading } = useQuery(['slugs'], () =>
        getCourseItemsSlugs(),
    );
    const formik: IFormik = useFormik({
        initialValues: {
            title: item && getTranslation(item, 'ru')?.title,
            description: item && getTranslation(item, 'ru')?.description,
            slug: item?.slug,
            materials: item?.materials?.map(
                (material) => getTranslation(material, 'ru')?.title,
            ),
            number: item?.number,
            weight: item?.weight,
            duration: item?.duration,
        },

        enableReinitialize: true,
        validateOnMount: !courseId,
        validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            description: Yup.string(),
            slug: Yup.string()
                .test('slug', 'Slug already exist', (value) =>
                    value
                        ? !slugs
                              ?.filter(
                                  (slug) => slug !== formik.initialValues.slug,
                              )
                              .includes(value)
                        : true,
                )
                .min(1)
                .max(255)
                .required(),
            number: Yup.number().required('must be a number'),
            duration: Yup.number(),
            weight: Yup.number(),
            translations: Yup.object().shape({}),
            materials: Yup.array(),
        }),
        onSubmit: (data) => {
            const item: ICourseMaterialList = {
                id: materialId!,
                courseId: courseId!,
                slug: data.slug!,
                materials: data.materials?.map((material) => ({
                    id: materials.find(
                        (item) =>
                            getTranslation(item, 'ru')?.title === material,
                    )?.id,
                })),
                number: data.number!,
                weight: data.weight!,
                duration: data.duration!,
                type: 'material',
                translations: [
                    {
                        language: 'ru',
                        title: data.title,
                        description: data.description!,
                    },
                ],
            };

            submitItem(item);
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const handleChangeWithSlug = (lesson) => {
        setFieldValue('slug', slugify(lesson.target.value.toLowerCase()));
        handleChange(lesson);
    };

    if (isLoading || isSlugsLoading) {
        return (
            <Div
                backgroundColor="white"
                borderRadius="2px"
                boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                gridColumn="1/3"
                p="20px"
            >
                <Text variant="body" mt="10px">
                    Loading...
                </Text>
            </Div>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <Div
                backgroundColor="white"
                borderRadius="2px"
                boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                gridColumn="1/3"
                pb="20px"
            >
                <Div px="20px">
                    <Input
                        label={'Заголовок'}
                        name={`title`}
                        id={`title`}
                        placeholder={'Заголовок'}
                        onChange={handleChangeWithSlug}
                        onBlur={handleBlur}
                        value={values.title || ''}
                        error={!!errors.title}
                        width="100%"
                    />
                    {errors.title && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.title}
                        </Text>
                    )}
                </Div>
                <Div px="20px">
                    <Input
                        label={'slug'}
                        name={`slug`}
                        id={`slug`}
                        placeholder={'slug'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.slug || ''}
                        error={!!errors.slug}
                        width="100%"
                    />
                    {errors.slug && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.slug}
                        </Text>
                    )}
                </Div>
                <Div px="20px">
                    <Text variant="body" mt="20px" mb="10px">
                        {'Description'}
                    </Text>
                    <ReactMde
                        value={values.description || ''}
                        onChange={handleChange(`description`)}
                        selectedTab={'write'}
                        // onTabChange={setSelectedTab} TODO: create textarea
                        toolbarCommands={[[]]}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(markdown)
                        }
                    />
                    {errors.lead && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.lead}
                        </Text>
                    )}
                </Div>
                <Div px="20px" mb="20px">
                    <Input
                        label={'Длительность в минутах'}
                        name={`duration`}
                        id={`duration`}
                        placeholder={'Длительность в минутах'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duration || ''}
                    />
                </Div>
                <Div px="20px" mb="20px">
                    <Input
                        label={'Вес'}
                        name={`weight`}
                        id={`weight`}
                        placeholder={'Вес'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.weight || ''}
                    />
                </Div>
                <Div px="20px" mb="20px">
                    <Input
                        label={'Номер'}
                        name={`number`}
                        id={`number`}
                        placeholder={'Номер'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.number || ''}
                        error={!!errors.number}
                    />
                    {errors?.number && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.number}
                        </Text>
                    )}
                </Div>
                <Div px="20px" mb="20px">
                    <Text variant="body" mb="10px">
                        Materials
                    </Text>
                    <MultiSelectInput
                        id="materials"
                        name="materials"
                        value={values.materials}
                        suggestions={materials?.map(
                            (material) => getTranslation(material, 'ru')?.title,
                        )}
                        placeholder="Select materials"
                        onChange={handleChange}
                    />
                </Div>
            </Div>
            <Flex mt="40px">
                <PrimaryButton
                    disabled={!isValid}
                    type="submit"
                    mr="20px"
                    isBig
                >
                    {isSaved ? 'Saved' : 'Save'}
                </PrimaryButton>
                <DefaultButton onClick={handleClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </form>
    );
};

const CreateMaterial = ({
    submitItem,
    handleClose,
    isSaved,
    setError,
    setErrorMessage,
    setSaved,
}) => {
    const formik: IFormik = useFormik({
        initialValues: {
            title: undefined,
            materialType: undefined,
            cover: undefined,
            url: undefined,
            size: undefined,
            contentType: undefined,
        },

        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().required(),
            materialType: Yup.string().required(),
            cover: Yup.string().required(),
            url: Yup.string().required(),
            size: Yup.string().required(),
            contentType: Yup.string().required('file is required'),
            translations: Yup.object().shape({}),
        }),
        onSubmit: (data) => {
            const item: IMaterial = {
                url: data.url!,
                cover: data.cover!,
                size: data.size!,
                materialType: data.materialType!,
                contentType: data.contentType!,
                translations: [
                    {
                        language: 'ru',
                        title: data.title!,
                    },
                ],
            };

            submitItem(item);
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        values,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const fileMutation = useMutation((fileInputData: FileList) =>
        uploadFile(fileInputData),
    );

    const [isCoverInputLoading, setCoverInputLoading] = useState(false);
    const [coverInputProgress, setCoverInputProgress] = useState(0);

    const [isFileInputLoading, setFileInputLoading] = useState(false);
    const [fileInputProgress, setFileInputProgress] = useState(0);

    function handleCoverInputChange(file: FileList) {
        setCoverInputLoading(true);
        setCoverInputProgress(100);

        fileMutation.mutate(file, {
            onSuccess: (value) => {
                formik.setFieldValue('cover', value);
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        });
    }

    function handleFileInputChange(file: FileList) {
        setFileInputLoading(true);
        setFileInputProgress(100);

        fileMutation.mutate(file, {
            onSuccess: (value) => {
                formik.setFieldValue('url', value);
                formik.setFieldValue('contentType', file[0].type);
                formik.setFieldValue('size', `${file[0].size}`);
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        });
    }

    function handleCoverInputCancel() {
        setCoverInputLoading(false);
        setCoverInputProgress(0);
        formik.setFieldValue('cover', undefined);
    }

    function handleFileInputCancel() {
        setFileInputLoading(false);
        setFileInputProgress(0);
        formik.setFieldValue('url', undefined);
        formik.setFieldValue('contentType', undefined);
        formik.setFieldValue('size', undefined);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Div
                backgroundColor="white"
                borderRadius="2px"
                boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                gridColumn="1/3"
                pb="20px"
            >
                <Div px="20px">
                    <Input
                        label={'Заголовок'}
                        name={`title`}
                        id={`title`}
                        placeholder={'Заголовок'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title || ''}
                        error={!!errors.title}
                        width="100%"
                    />
                    {errors.title && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.title}
                        </Text>
                    )}
                </Div>
                <Div px="20px">
                    <Input
                        label={'Тип материала'}
                        name={`materialType`}
                        id={`materialType`}
                        placeholder={'Тип материала'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.materialType || ''}
                        error={!!errors.materialType}
                        width="100%"
                    />
                    {errors.materialType && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.materialType}
                        </Text>
                    )}
                </Div>
                <Div width="50%" px="20px" mb="20px">
                    <FileInput
                        id="cover"
                        title="Cover"
                        progress={coverInputProgress}
                        isLoading={isCoverInputLoading}
                        onChange={handleCoverInputChange}
                        onCancel={handleCoverInputCancel}
                        preview={values?.cover}
                    />
                    {errors?.cover && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.cover}
                        </Text>
                    )}
                </Div>
                <Div width="50%" px="20px" mb="20px">
                    <FileInput
                        id="file"
                        title="File"
                        progress={fileInputProgress}
                        isLoading={isFileInputLoading}
                        onChange={handleFileInputChange}
                        onCancel={handleFileInputCancel}
                    />
                    {errors?.contentType && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.contentType}
                        </Text>
                    )}
                </Div>
            </Div>
            <Flex mt="40px">
                <PrimaryButton
                    disabled={!isValid}
                    type="submit"
                    mr="20px"
                    isBig
                >
                    {isSaved ? 'Saved' : 'Save'}
                </PrimaryButton>
                <DefaultButton onClick={handleClose} isBig>
                    Cancel
                </DefaultButton>
            </Flex>
        </form>
    );
};

const MaterialsForm = ({
    onClose,
    courseId,
    materialId,
    isOpened,
}: IItemFormProps): JSX.Element => {
    const { data: item } = useQuery(
        ['item', materialId],
        () => getItem(materialId!),
        {
            enabled: !!materialId,
        },
    );

    const [isSaved, setSaved] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedTab, setSelectedTab] = useState('list');

    const handleClose = () => {
        setSaved(false);
        onClose();
    };
    const handleMutationError = (error) => {
        setError(true);
        setErrorMessage(Object.values(error.response.data).join(' - '));
        setTimeout(() => {
            setError(false);
            setErrorMessage('');
        }, 5000);
    };

    const createListMutation = useMutation(
        (item: ICourseMaterialList) => createItem(item),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error) => {
                handleMutationError(error);
            },
        },
    );
    const updateListMutation = useMutation(
        (item: ICourseMaterialList) => updateItem(item),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error) => {
                handleMutationError(error);
            },
        },
    );
    const createMaterialMutation = useMutation(
        (item: IMaterial) => createMaterial(item),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('course-items');
            },
            onError: (error) => {
                handleMutationError(error);
            },
        },
    );

    const submitItem = (item) => {
        if (selectedTab === 'list') {
            if (materialId) {
                updateListMutation.mutate(item);
            } else {
                createListMutation.mutate(item);
            }
        } else {
            createMaterialMutation.mutate(item);
        }
    };

    return (
        <Modal
            label={'Добавить материалы курса'}
            onClose={handleClose}
            isOpened={isOpened}
            backgroundColor="#EFF2F3"
        >
            {isError && <Alert message={errorMessage} />}
            <Tabs
                tabs={[{ name: 'list' }, { name: 'new' }]}
                handleTabClick={(tab) => {
                    setSelectedTab(tab);
                }}
                selectedTab={selectedTab}
            />
            {selectedTab === 'list' ? (
                <MaterialsList
                    item={item}
                    courseId={courseId}
                    materialId={materialId}
                    submitItem={submitItem}
                    isSaved={isSaved}
                    handleClose={handleClose}
                    setSaved={setSaved}
                />
            ) : (
                <CreateMaterial
                    submitItem={submitItem}
                    isSaved={isSaved}
                    handleClose={handleClose}
                    setError={setError}
                    setErrorMessage={setErrorMessage}
                    setSaved={setSaved}
                />
            )}
        </Modal>
    );
};

export default MaterialsForm;
