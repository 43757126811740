const axios = require('axios');
import { getTokens, setTokens } from 'src/utils';
import { AxiosInstanceWithLocale, AxiosRequestConfigWithLocale,  } from 'src/global';
import { AxiosResponse } from 'axios';

declare global {
    interface Window {
        _env_: Record<string, any>;
    }
}

const apiUrl = 'https://api.strelka.com/api/v1/admin';

let isRefreshing = false;
let failedQueue: any[] = [];

const axiosInstance: AxiosInstanceWithLocale = axios.create({
    baseURL: apiUrl,
    withoutLocale:false,
});

const processQueue = (error: any, token: string | null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfigWithLocale) {
    const { accessToken } = getTokens();

    config.headers.Authorization = `Bearer ${accessToken}`;

    if (!config.withoutLocale) {
        config.baseURL = config.baseURL;
    }

    return config;
});

axiosInstance.interceptors.response.use(
    function (response: any) {
        return response;
    },
    async (error: any) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            const { refreshToken, accessToken } = getTokens();
            if (accessToken !== null &&
                refreshToken !== null) {

                if (isRefreshing) {
                    try {
                        const token = await new Promise(function (resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        });
                        originalRequest.headers['Authorization'] = `Bearer ${token}`;
                        return axios(originalRequest);
                    } catch (err) {
                        return err;
                    }
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(function (resolve, reject) {
                    axios
                        .post(`${apiUrl}/auth/refresh`,{
                            refreshToken,
                        })
                        .then((response:AxiosResponse) => {
                            const {data: freshTokens} = response
                            setTokens(freshTokens);
                            axios.defaults.headers.common['Authorization'] = `Bearer ${freshTokens.accessToken}`;
                            originalRequest.headers['Authorization'] = `Bearer ${freshTokens.accessToken}`;
                            processQueue(null, freshTokens.accessToken);
                            resolve(axios(originalRequest));
                        })
                        .catch((err: any) => {
                            processQueue(err, null);
                            reject(err);
                        })
                        .then(() => {
                            isRefreshing = false;
                        });
                });
            }
        }

        return Promise.reject(error);
    },
);


export default axiosInstance
