import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import * as CSS from 'csstype';
import {
    background,
    BackgroundProps,
    border,
    BorderProps,
    color,
    ColorProps,
    compose,
    layout,
    LayoutProps,
    position,
    PositionProps,
    ResponsiveValue,
    shadow,
    ShadowProps,
    space,
    SpaceProps,
    system,
    typography,
    TypographyProps,
} from 'styled-system';

type CustomProps = {
    borderCollapse?: ResponsiveValue<CSS.Property.BorderCollapse>;
    borderSpacing?: ResponsiveValue<CSS.Property.BorderSpacing>;
};

export type CommonProps = SpaceProps &
    ColorProps &
    LayoutProps &
    BackgroundProps &
    BorderProps &
    PositionProps &
    ShadowProps &
    TypographyProps &
    CustomProps;

export const Table = styled('table', { shouldForwardProp })<CommonProps>(
    compose(typography, layout, space, color, position, background, border),
    system({
        borderCollapse: {
            property: 'borderCollapse',
        },
        borderSpacing: {
            property: 'borderSpacing',
        },
    }),
);

export const Thead = styled('thead', { shouldForwardProp })<CommonProps>(
    compose(typography, layout, space, color, position, background, border),
);

export const Tbody = styled('tbody', { shouldForwardProp })<CommonProps>(
    compose(typography, layout, space, color, position, background, border),
);

export const Tr = styled('tr', { shouldForwardProp })<CommonProps>(
    compose(
        typography,
        layout,
        space,
        color,
        position,
        background,
        shadow,
        border,
    ),
);

export const Th = styled('th', { shouldForwardProp })<CommonProps>(
    compose(typography, layout, space, color, position, background, border),
);

export const Td = styled('td', { shouldForwardProp })<CommonProps>(
    compose(typography, layout, space, color, position, background, border),
    {
        verticalAlign: 'middle',
    },
);
