/* eslint-disable react/jsx-key */
import React from 'react';
import { TableHeaderProps, useTable } from 'react-table';

import Div from '../Div';
import Flex from '../Flex';
import Text from '../Text';
import { Table, Tbody, Td, Th, Thead, Tr } from './PlainTableComponents';

// interface ITable<T extends Record<string, unknown>> {
//     columns: Column<T>[];
//     data: T[];
// }

const WideTable = ({ columns, data }: any) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <Flex fontFamily="Universe, Arial" overflow="auto">
            <Flex flexDirection="column" flex="1">
                <Div px="10px">
                    <Table
                        borderCollapse="separate"
                        borderSpacing={
                            (rows.length !== 0 && '0px 10px') || 'initial'
                        }
                        size="100%"
                        {...getTableProps()}
                    >
                        <Thead>
                            {headerGroups.map((headerGroup) => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <Th
                                            {...column.getHeaderProps([
                                                {
                                                    width: column.width,
                                                    minWidth: column.minWidth,
                                                } as Partial<TableHeaderProps>,
                                            ])}
                                        >
                                            <Text
                                                my="20px"
                                                variant="body"
                                                textTransform="uppercase"
                                            >
                                                {column.render('Header')}
                                            </Text>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {rows.length !== 0 && (
                                <>
                                    <Tr height="40px" />
                                    {rows.map((row) => {
                                        prepareRow(row);

                                        return (
                                            <Tr
                                                boxShadow=" 0px 0px 6px rgba(0, 0, 0, 0.1);"
                                                borderRadius="2px"
                                                backgroundColor="white"
                                                textAlign="center"
                                                {...row.getRowProps()}
                                            >
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <Td
                                                            mb="10px"
                                                            py="40px"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                'Cell',
                                                            )}
                                                        </Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </>
                            )}
                        </Tbody>
                    </Table>
                </Div>
                {rows.length === 0 && (
                    <Text
                        width="100%"
                        backgroundColor="white"
                        textAlign="center"
                        variant="body"
                    >
                        Data is empty...
                    </Text>
                )}
            </Flex>
        </Flex>
    );
};

export default WideTable;
