import styled from '@emotion/styled';
import React, { useState /* useEffect*/ } from 'react';

import { Div, Text } from '.';
import Flex from './Flex';

const Dots = styled(Flex)(() => ({
    '&:hover': {
        div: {
            backgroundColor: '#006AFF',
        },
    },
}));
const Menu = styled(Div)({
    'p:hover': {
        cursor: 'pointer',
        backgroundColor: '#006AFF',
        color: 'white',
    },
});

export type Action = {
    title: string;
    onClick: () => void;
};
type DotsProps = {
    actions: Action[];
};

const Dot = (props: { isOpen: boolean }) => (
    <Div
        width={'6px'}
        height={'6px'}
        marginRight={'4px'}
        borderRadius={'10px'}
        backgroundColor={'dotsGrey'}
        {...(props.isOpen && {
            backgroundColor: 'solidBlue',
        })}
    />
);

const DotsMenu = (props: DotsProps) => {
    const [isOpen, setOpened] = useState(false);

    function handleWrapperClick(e: React.SyntheticEvent) {
        e.stopPropagation();
    }

    // useEffect(() => {

    // 	function closeModal() {
    // 		setOpened(false)
    // 	}
    // 	return function cleanup() {
    // 		console.log('unmounting')
    // 	}
    // }, [])

    return (
        <Div
            width="fit-content"
            position="relative"
            onClick={handleWrapperClick}
        >
            <Dots
                cursor={'pointer'}
                boxSizing={'border-box'}
                padding={'5px 0'}
                onClick={() => setOpened(!isOpen)}
            >
                <Dot isOpen={isOpen} />
                <Dot isOpen={isOpen} />
                <Dot isOpen={isOpen} />
            </Dots>
            {isOpen && (
                <Menu
                    onClick={handleWrapperClick}
                    marginTop={'2px'}
                    marginLeft={'-5px'}
                    minWidth={'130px'}
                    overflow={'hidden'}
                    zIndex={999}
                    position={'absolute'}
                    backgroundColor={'white'}
                    boxShadow={'0px 0px 6px rgba(0, 0, 0, 0.1)'}
                    borderRadius={'2px'}
                >
                    {props.actions.map((action, index) => (
                        <Text
                            variant="body"
                            padding="8px 10px"
                            key={index}
                            {...(index !== 0 && {
                                borderTop: '1px solid',
                                borderColor: 'lightGrey',
                            })}
                            onClick={() => {
                                action.onClick();
                                setOpened(false);
                            }}
                        >
                            {action.title}
                        </Text>
                    ))}
                </Menu>
            )}
        </Div>
    );
};

export default DotsMenu;
