import React from 'react';

import Flex from './Flex';
import Text from './Text';

interface IAlert {
    type?: string;
    message: string;
}

const Alert: React.VoidFunctionComponent<IAlert> = ({ message }) => {
    return (
        <Flex
            position="fixed"
            backgroundColor="red"
            top="0"
            left="0"
            justifyContent="center"
            height="64px"
            width="100%"
            alignItems="center"
            flexDirection="row"
            zIndex={100}
        >
            <Text variant="title" color="white">
                {message}
            </Text>
        </Flex>
    );
};

export default Alert;
