import React from 'react';

import Input from './Input';

const DatePicker = () => {
    return (
        <Input
            type="datetime-local"
            value="2018-06-12T19:30"
            min="2018-06-07T00:00"
            max="2018-06-14T00:00"
        />
    );
};

export default DatePicker;
