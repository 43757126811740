import styled from '@emotion/styled';
import React from 'react';

import {
    DefaultButton,
    Div,
    DotsMenu,
    DownloadIcon,
    Flex,
    PlainLink,
    Text,
} from '.';
import { Action } from './DotsMenu';

const Preview = styled(Div)<{ withHover?: boolean }>(({ withHover }) => ({
    ...(withHover && {
        '&:hover': {
            backgroundColor: '#006AFF !important',
            background: 'none',
            cursor: 'pointer',
            p: {
                opacity: '0',
            },
            svg: {
                display: 'block',
                position: 'absolute',
                top: 'calc(50% - 5px)',
                left: 'calc(50% - 4px)',
            },
        },
    }),
}));

const FileIcon = (
    props: UploadedFile & { short?: boolean; isNotFirst?: boolean },
) => {
    return (
        <Flex
            flexDirection="row"
            alignItems="center"
            className="filePreview"
            paddingRight="20px"
            {...(props.isNotFirst && {
                borderLeft: '1px solid',
                borderColor: 'lightGrey',
                paddingLeft: '20px',
            })}
        >
            {props.short ? (
                <PlainLink href={props.url} target="_blank" rel="noreferrer">
                    <Preview
                        position={'relative'}
                        backgroundSize={'cover'}
                        padding={'25px 8px 1px'}
                        boxSizing={'border-box'}
                        withHover={props.short}
                        marginRight={props.short ? '10px' : '20px'}
                        background={
                            props.preview
                                ? `url(${props.preview})`
                                : 'filePreviewIconColor'
                        }
                        backgroundColor={'filePreviewIconColor'}
                    >
                        <Text
                            opacity={props.preview ? 0 : 1}
                            variant="body"
                            fontSize="10px !important"
                            color="red"
                            lineHeight="18px"
                            textTransform="uppercase"
                        >
                            {props.title.split('.').pop()}
                        </Text>
                        {props.short && (
                            <DownloadIcon
                                display="none"
                                width="14px"
                                height="14px"
                            />
                        )}
                    </Preview>
                </PlainLink>
            ) : (
                <Preview
                    position={'relative'}
                    backgroundSize={'cover'}
                    padding={'25px 8px 1px'}
                    boxSizing={'border-box'}
                    withHover={props.short}
                    marginRight={props.short ? '10px' : '20px'}
                    background={
                        props.preview
                            ? `url(${props.preview})`
                            : 'filePreviewIconColor'
                    }
                    backgroundColor="filePreviewIconColor"
                >
                    <Text
                        opacity={props.preview ? 0 : 1}
                        variant="body"
                        fontSize="10px !important"
                        color="red"
                        lineHeight="18px"
                        textTransform="uppercase"
                    >
                        {props.title.split('.').pop()}
                    </Text>
                    {props.short && (
                        <DownloadIcon
                            display="none"
                            width="14px"
                            height="14px"
                        />
                    )}
                </Preview>
            )}
            <Div>
                <Text variant="body" color="solidBlue" marginBottom="3px">
                    {props.title}
                </Text>
                <Text
                    variant="body"
                    color="textCaptionGrey"
                >{`Upload ${props.uploadedAt.toLocaleDateString(
                    'ru-RU',
                )}`}</Text>
            </Div>
        </Flex>
    );
};

type UploadedFile = {
    title: string;
    url: string;
    uploadedAt: Date;
    preview?: string;
};
type FilePreviewProps = {
    files: UploadedFile | UploadedFile[];
    title: string;
    actions: Action[];
};

const FilePreview = (props: FilePreviewProps) => {
    return (
        <Div>
            <Text
                color="textCaptionGrey"
                margin="30px auto 10px"
                variant="body"
            >
                {props.title}
            </Text>
            <Flex
                width={'100%'}
                padding={'20px'}
                borderTop={'1px solid'}
                borderBottom={'1px solid'}
                borderColor="lightGrey"
                boxSizing={'border-box'}
                justifyContent={
                    Array.isArray(props.files) ? 'center' : 'space-between'
                }
                alignItems="center"
            >
                {Array.isArray(props.files) ? (
                    props.files.map((file, index) => (
                        <FileIcon
                            short
                            isNotFirst={index !== 0}
                            key={index}
                            {...file}
                        />
                    ))
                ) : (
                    <FileIcon {...props.files} />
                )}
                {!Array.isArray(props.files) && (
                    <Flex alignItems="center">
                        <PlainLink
                            href={props.files.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <DefaultButton>Download</DefaultButton>
                        </PlainLink>
                        <PlainLink
                            href={props.files.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <DefaultButton margin="0 40px 0 20px">
                                Watch
                            </DefaultButton>
                        </PlainLink>
                        <DotsMenu actions={props.actions} />
                    </Flex>
                )}
            </Flex>
        </Div>
    );
};

export default FilePreview;
