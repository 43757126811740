import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import * as CSS from 'csstype';
import {
    background,
    BackgroundProps,
    border,
    BorderProps,
    color,
    ColorProps,
    compose,
    FlexboxProps,
    grid,
    GridProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    ResponsiveValue,
    ShadowProps,
    space,
    SpaceProps,
    system,
    typography,
    TypographyProps,
} from 'styled-system';

type CustomProps = {
    boxSizing?: ResponsiveValue<CSS.Property.BoxSizing>;
    cursor?: ResponsiveValue<CSS.Property.Cursor>;
    transform?: ResponsiveValue<CSS.Property.Transform>;
};

export type SpanProps = SpaceProps &
    ColorProps &
    LayoutProps &
    FlexboxProps &
    GridProps &
    BackgroundProps &
    BorderProps &
    PositionProps &
    ShadowProps &
    TypographyProps &
    CustomProps;

const Span = styled('span', { shouldForwardProp })<SpanProps>(
    compose(
        typography,
        layout,
        space,
        color,
        grid,
        position,
        background,
        border,
    ),
    system({
        boxSizing: {
            property: 'boxSizing',
        },
        cursor: {
            property: 'cursor',
        },
        transform: {
            property: 'transform',
        },
    }),
);

export default Span;
