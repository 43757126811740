import { ThemeProvider } from '@emotion/react';
import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { SideBar } from './components';
import {
    Auth,
    Banners,
    CheckAuth,
    CourseContent,
    Courses,
    Events,
    Formats,
    Locations,
    NotFound,
    Page,
    Persons,
    Sponsors,
    Statistics,
    TestContent,
} from './pages';
import theme from './theme';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <CheckAuth>
                    <Switch>
                        <Route exact path="/sign-in">
                            <Auth />
                        </Route>
                        <SideBar
                            items={[
                                {
                                    name: 'home',
                                    label: 'home',
                                    link: '/',
                                },
                                {
                                    name: 'components list',
                                    label: 'components list',
                                    link: '/page',
                                },
                                {
                                    name: 'formats',
                                    label: 'formats',
                                    link: '/formats',
                                },
                                {
                                    name: 'persons',
                                    label: 'persons',
                                    link: '/persons',
                                },
                                {
                                    name: 'locations',
                                    label: 'locations',
                                    link: '/locations',
                                },
                                {
                                    name: 'sponsors',
                                    label: 'sponsors',
                                    link: '/sponsors',
                                },
                                {
                                    name: 'courses',
                                    label: 'courses',
                                    link: '/courses',
                                },
                                {
                                    name: 'banners',
                                    label: 'banners',
                                    link: '/banners',
                                },
                                {
                                    name: 'events',
                                    label: 'events',
                                    link: '/events',
                                },
                                {
                                    name: 'statistics',
                                    label: 'statistics',
                                    link: '/statistics',
                                },
                            ]}
                        >
                            <Route exact path="/page">
                                <Page />
                            </Route>
                            <Route exact path="/formats">
                                <Formats />
                            </Route>
                            <Route exact path="/persons">
                                <Persons />
                            </Route>
                            <Route exact path="/locations">
                                <Locations />
                            </Route>
                            <Route exact path="/sponsors">
                                <Sponsors />
                            </Route>
                            <Route exact path="/courses">
                                <Courses />
                            </Route>
                            <Route exact path="/courses/:courseId">
                                <Courses />
                            </Route>
                            <Route exact path="/courses/:courseId/content">
                                <CourseContent />
                            </Route>
                            <Route
                                exact
                                path="/courses/:courseId/content/:testId"
                            >
                                <TestContent />
                            </Route>
                            <Route exact path="/events">
                                <Events />
                            </Route>
                            <Route exact path="/banners">
                                <Banners />
                            </Route>
                            <Route exact path="/banners/:bannerId">
                                <Banners />
                            </Route>
                            <Route exact path="/statistics">
                                <Statistics />
                            </Route>
                        </SideBar>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </CheckAuth>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
