import './assets/fonts.css';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';
require('dayjs/locale/ru');
dayjs.locale('ru');

dayjs.extend(isToday);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 9999,
            refetchOnWindowFocus: false,
        },
    },
});

ReactDOM.render(
    <>
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </>,
    document.getElementById('app'),
);
