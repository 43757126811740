import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const Close: React.VoidFunctionComponent<CustomSvgProps> = (props) => {
    return (
        <Svg
            {...props}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 1L1 15"
                stroke={props.stroke || '#DEDEDE'}
                strokeWidth="2"
            />
            <path
                d="M1 1L15 15"
                stroke={props.stroke || '#DEDEDE'}
                strokeWidth="2"
            />
        </Svg>
    );
};

Svg.defaultProps = {
    width: '16px',
    height: '16px',
};

export default Close;
