import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const ArrowUp: React.VoidFunctionComponent<CustomSvgProps> = (props) => (
    <Svg
        {...props}
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11 6L6 1L1 6" stroke="#647282" strokeLinejoin="round" />
    </Svg>
);

export default ArrowUp;
