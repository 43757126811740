/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-mde/lib/styles/css/react-mde-all.css';

import { IAnswer } from 'api-model';
import {
    Alert,
    CheckBox,
    DefaultButton,
    Div,
    Flex,
    Input,
    Modal,
    PrimaryButton,
    Text,
} from 'components';
import { useFormik } from 'formik';
import { IFormik } from 'formik-model';
import { createAnswer, updateAnswer } from 'mutations';
import React, { useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useMutation } from 'react-query';
import * as Showdown from 'showdown';
import slugify from 'slugify';
import { getTranslation } from 'utils';
import * as Yup from 'yup';

import { queryClient } from '../../main';

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

interface IAnswerFormProps {
    onClose: () => void;
    testId: number;
    questionId: number;
    answer: any;
    isOpened: boolean;
}

const AnswerForm = ({
    onClose,
    isOpened,
    answer,
    testId,
    questionId,
}: IAnswerFormProps): JSX.Element => {
    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
        'write',
    );

    const [isSaved, setSaved] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => {
        formik.resetForm();
        setSaved(false);
        onClose();
    };

    const createAnswerMutation = useMutation(
        (answer: IAnswer) => createAnswer(answer, testId, questionId),
        {
            onSuccess: () => {
                handleClose();
                queryClient.invalidateQueries('test-items');
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );
    const answerMutation = useMutation(
        (answer: IAnswer) =>
            updateAnswer(answer, testId, questionId, answer.id!),
        {
            onSuccess: () => {
                setSaved(true);
                handleClose();
                queryClient.invalidateQueries('test-items');
            },
            onError: (error: any) => {
                setError(true);
                setErrorMessage(Object.values(error.response.data).join(' - '));
                setTimeout(() => {
                    setError(false);
                    setErrorMessage('');
                }, 5000);
            },
        },
    );

    const formik: IFormik = useFormik({
        initialValues: {
            id: answer?.id,
            isCorrect: answer?.isCorrect,
            title: answer && getTranslation(answer, 'ru')?.title,
            description: answer
                ? converter.makeMarkdown(
                      getTranslation(answer, 'ru')?.description || '',
                  )
                : '',
        },

        enableReinitialize: true,
        validateOnMount: !testId,
        validationSchema: Yup.object().shape({
            isCorrect: Yup.boolean(),
            title: Yup.string().required(),
            description: Yup.string().required(),
            translations: Yup.object().shape({}),
        }),
        onSubmit: (data) => {
            const answer: IAnswer = {
                id: data.id,
                isCorrect: data.isCorrect!,
                questionId: questionId,
                translations: [
                    {
                        language: 'ru',
                        title: data.title,
                        description: converter.makeHtml(data.description),
                    },
                ],
            };

            submitAnswer(answer);
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const submitAnswer = (answer) => {
        if (answer.id) {
            answerMutation.mutate(answer);
        } else {
            createAnswerMutation.mutate(answer);
        }
    };

    const handleChangeWithSlug = (answer) => {
        setFieldValue('slug', slugify(answer.target.value.toLowerCase()));
        handleChange(answer);
    };

    return (
        <Modal
            label={answer ? 'Редактировать ответ' : 'Создать новый ответ'}
            onClose={handleClose}
            isOpened={isOpened}
            backgroundColor="#EFF2F3"
        >
            <form onSubmit={handleSubmit}>
                {isError && <Alert message={errorMessage} />}
                <Div
                    backgroundColor="white"
                    borderRadius="2px"
                    boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                    gridColumn="1/3"
                    pb="20px"
                >
                    <Div px="20px">
                        <CheckBox
                            label={'Правильный ответ?'}
                            name={`isCorrect`}
                            id={`isCorrect`}
                            checked={values.isCorrect}
                            onChange={handleChange}
                        />
                    </Div>
                    <Div px="20px">
                        <Input
                            label={'Заголовок'}
                            name={`title`}
                            id={`title`}
                            placeholder={'Заголовок'}
                            onChange={handleChangeWithSlug}
                            onBlur={handleBlur}
                            value={values.title || ''}
                            error={!!errors.title}
                            width="100%"
                        />
                        {errors.title && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.title}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px">
                        <Text variant="body" mt="20px" mb="10px">
                            {'Описание'}
                        </Text>
                        <ReactMde
                            value={values.description || ''}
                            onChange={handleChange(`description`)}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            toolbarCommands={[['bold', 'italic', 'link']]}
                            generateMarkdownPreview={(markdown) =>
                                Promise.resolve(converter.makeHtml(markdown))
                            }
                        />
                        {errors.description && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.description}
                            </Text>
                        )}
                    </Div>
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        {isSaved ? 'Saved' : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </form>
        </Modal>
    );
};

export default AnswerForm;
