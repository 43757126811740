import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const Check: React.VoidFunctionComponent<CustomSvgProps> = (props) => (
    <Svg
        {...props}
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 5.33333L5 10L12 1.33333L10.6 0L5 7.33333L1.4 4L0 5.33333Z"
            fill="white"
        />
    </Svg>
);

Svg.defaultProps = {
    width: '12px',
    height: '10px',
};

export default Check;
