import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Link } from 'react-router-dom';
import {
    background,
    BackgroundProps,
    border,
    BorderProps,
    color,
    ColorProps,
    compose,
    layout,
    LayoutProps,
    position,
    PositionProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from 'styled-system';

export type RouterLinkProps = SpaceProps &
    ColorProps &
    LayoutProps &
    BackgroundProps &
    BorderProps &
    PositionProps &
    TypographyProps;

const RouterLink = styled(Link, { shouldForwardProp })<RouterLinkProps>(
    compose(typography, layout, space, color, position, background, border),
    { textDecoration: 'none' },
);

export default RouterLink;
