import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const style = {
    cursor: 'move',
};

export interface ICardProps {
    id: any;
    item: any;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    Component: any;
    componentProps: any;
}

interface IDragItem {
    index: number;
    id: string;
    type: string;
}

const Item: React.FC<ICardProps> = ({
    id,
    item,
    index,
    moveCard,
    Component,
    componentProps,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: IDragItem, monitor: any) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY =
                (clientOffset as any).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (
        <div
            ref={ref}
            style={{ ...style, opacity }}
            data-handler-id={handlerId}
        >
            <Component
                banner={item}
                {...componentProps}
                onEdit={() => componentProps.onEdit(item.announcementId)}
            />
        </div>
    );
};

export { Item };
