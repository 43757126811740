import styled from '@emotion/styled';
import * as CSS from 'csstype';
import {
    border,
    BorderProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    ResponsiveValue,
    space,
    SpaceProps,
    system,
} from 'styled-system';

type CustomProps = {
    objectFit?: ResponsiveValue<CSS.Property.ObjectFit>;
    imgWidth?: ResponsiveValue<CSS.Property.Width<string | 0 | number>>;
    imgHeight?: ResponsiveValue<CSS.Property.Height<string | 0 | number>>;
};

type ImgProps = SpaceProps &
    LayoutProps &
    BorderProps &
    PositionProps &
    CustomProps;

const Img = styled('img')<ImgProps>(
    layout,
    space,
    position,
    border,
    system({
        imgWidth: {
            property: 'width',
        },
        imgHeight: {
            property: 'height',
        },
        objectFit: {
            property: 'objectFit',
        },
    }),
);

export default Img;
