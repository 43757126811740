import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import { ITableActions } from 'src/model/types/TableActions';
import { useOnClickOutside } from 'src/utils/hooks';

import Flex from './Flex';
import Span from './Span';
import Text from './Text';

const StyledFlex = styled(Flex)({
    '&:hover': {
        backgroundColor: '#006AFF',
        color: 'white',
    },
});

const TableActions: React.FC<ITableActions> = ({ actions }) => {
    const [isOpened, toggleOpen] = useState(false);
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    const handleToggle = () => toggleOpen(!isOpened);

    useOnClickOutside(ref, () => toggleOpen(false));

    const renderActions = () =>
        actions.map(({ callBack, label }) => {
            return (
                <StyledFlex
                    backgroundColor="white"
                    color="black"
                    cursor="pointer"
                    key={label}
                    onClick={callBack}
                >
                    <Text m="22px 14px" variant="body">
                        {label}
                    </Text>
                </StyledFlex>
            );
        });

    return (
        <Flex onClick={handleToggle} width="100%" justifyContent="center">
            <Flex ref={ref} cursor="pointer" position="relative" py="6px">
                <Span
                    display="inline-block"
                    size="6px"
                    backgroundColor={isOpened ? 'solidBlue' : '#D7DBDF'}
                    borderRadius="12px"
                />
                <Span
                    display="inline-block"
                    size="6px"
                    mx="6px"
                    backgroundColor={isOpened ? 'solidBlue' : '#D7DBDF'}
                    borderRadius="12px"
                />
                <Span
                    display="inline-block"
                    size="6px"
                    backgroundColor={isOpened ? 'solidBlue' : '#D7DBDF'}
                    borderRadius="12px"
                />
                {isOpened && (
                    <Flex
                        boxShadow=" 0px 0px 6px rgba(0, 0, 0, 0.1)"
                        minWidth="260px"
                        zIndex={2}
                        position="absolute"
                        top="10px"
                        left="-230px"
                        flexDirection="column"
                        backgroundColor="white"
                    >
                        {renderActions()}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default TableActions;
