import React from 'react';

import Svg, { CustomSvgProps } from '../Svg';

const Search: React.VoidFunctionComponent<CustomSvgProps> = (props) => {
    return (
        <Svg {...props} viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
                <path
                    d="M14.8584 16.0002L10.668 11.0479"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <circle
                    cx="6.5"
                    cy="6.5"
                    r="5.5"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                />
            </g>
        </Svg>
    );
};

Svg.defaultProps = {
    width: '16px',
    height: '17px',
    fill: 'none',
    fillOpacity: 'current-color',
};

export default Search;
