import { Flex, Text } from 'components';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { signOut } from 'src/api/mutations';
import { getProfile } from 'src/api/queries';

import Div from '../Div';

const BreadCrumbs = () => {
    const history = useHistory();
    const { data: user } = useQuery('user', getProfile);
    const signOutMutation = useMutation(() => signOut(), {
        onSuccess: () => {
            history.push('/sign-in');
        },
    });
    const handleLogout = () => {
        signOutMutation.mutate();
    };

    return (
        <Div backgroundColor="black">
            <Flex
                flexDirection="row-reverse"
                alignItems="center"
                px="18px"
                height="54px"
            >
                <Text
                    cursor="pointer"
                    onClick={handleLogout}
                    variant="body"
                    color="darkGrey"
                >
                    {user?.email}
                </Text>
            </Flex>
        </Div>
    );
};

export default BreadCrumbs;
