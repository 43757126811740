/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-mde/lib/styles/css/react-mde-all.css';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useMutation, useQuery } from 'react-query';
import * as Showdown from 'showdown';
import slugify from 'slugify';
import { createEvent, updateEvent, uploadEventCover } from 'src/api/mutations';
import {
    getEvent,
    getFormats,
    getLocations,
    getPersons,
    getProgrammes,
    getRoles,
    getSponsors,
    getTopics,
} from 'src/api/queries';
import {
    AuthorsInput,
    CheckBox,
    DefaultButton,
    Div,
    FileInput,
    Flex,
    Img,
    Input,
    LanguageToggle,
    Modal,
    PlainButton,
    PrimaryButton,
    Select,
    SponsorsInput,
    Text,
} from 'src/components';
import { IEditEvent } from 'src/model/types/api';
import { IFormik } from 'src/model/types/Formik';
import { getTranslation } from 'src/utils';
import * as Yup from 'yup';

import { queryClient } from '../../main';
import { Block } from './blocks';
import { Form } from './styled';

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

const languagesList = {
    en: [
        'Russian with translation into English',
        'English with translation into Russian',
        'Russian without translation',
        'English without translation',
    ],
    ru: [
        'Русский с переводом на английский',
        'Английский с переводом на русский',
        'Русский без перевода',
        'Английский без перевода',
    ],
};
const languagesOfEvents = ['en', 'ru'];

interface IEditEventModal {
    onClose: () => void;
    isOpened: boolean;
    eventId: number | undefined;
    lang: string;
    slugs: string[] | undefined;
}

function getBlockFields(block) {
    switch (block) {
        case 'about':
            return {
                results: '',
                audience: '',
                whatYouWillLearn: '',
            };
        case 'timetable':
            return {
                pdfLink: '',
                timetable: [
                    {
                        dateStart: null,
                        dateEnd: null,
                        description: converter.makeHtml(''),
                    },
                ],
            };
        case 'conditions':
            return {
                age: 0,
                skills: '',
                deadline: null,
                description: '',
            };
        default:
            return {};
    }
}

function handleBlocksStructure(block, type) {
    switch (block.blockType) {
        case 'about':
            return {
                ...block,
                results:
                    type === 'submit'
                        ? converter.makeHtml(block.results)
                        : converter.makeMarkdown(block.results),
                whatYouWillLearn:
                    type === 'submit'
                        ? converter.makeHtml(block.whatYouWillLearn)
                        : converter.makeMarkdown(block.whatYouWillLearn),
                audience:
                    type === 'submit'
                        ? converter.makeHtml(block.audience)
                        : converter.makeMarkdown(block.audience),
            };
        case 'timetable':
            return {
                ...block,
                timetable: block.timetable.map((_timetable) => ({
                    dateStart:
                        type === 'submit'
                            ? dayjs(_timetable.dateStart).utc(true).format()
                            : dayjs(_timetable.dateStart)
                                  .utc(false)
                                  .format('YYYY-MM-DDTHH:mm'),
                    dateEnd:
                        type === 'submit'
                            ? dayjs(_timetable.dateEnd).utc(true).format()
                            : dayjs(_timetable.dateEnd)
                                  .utc(false)
                                  .format('YYYY-MM-DDTHH:mm'),
                    description:
                        type === 'submit'
                            ? converter.makeHtml(_timetable.description)
                            : converter.makeMarkdown(_timetable.description),
                })),
            };
        case 'conditions':
            return {
                ...block,
                description:
                    type === 'submit'
                        ? converter.makeHtml(block.description)
                        : converter.makeMarkdown(block.description),
                skills:
                    type === 'submit'
                        ? block.skills.length > 0
                            ? block.skills.split(', ')
                            : undefined
                        : block.skills
                        ? block.skills.join(', ')
                        : '',
            };
        default:
            return null;
    }
}

const EditEventModal: React.FC<IEditEventModal> = ({
    onClose,
    isOpened,
    eventId,
    lang,
    slugs,
}) => {
    const { data: event } = useQuery(
        ['events', eventId],
        () => getEvent(eventId!),
        { enabled: !!eventId },
    );

    const { data: formats } = useQuery('formats', async () => {
        const data = await getFormats();

        return data;
    });

    const { data: topics } = useQuery('topics', () =>
        getTopics('pagination[size]=1000'),
    );
    const { data: programmes } = useQuery('programmes', getProgrammes);
    const { data: locations } = useQuery('locations', async () => {
        const data = await getLocations();

        return data;
    });
    const { data: sponsors } = useQuery('sponsors', async () => {
        const data = await getSponsors();

        return data;
    });
    const { data: persons } = useQuery('persons', async () => {
        const data = await getPersons();

        return data;
    });
    const { data: roles } = useQuery('roles', getRoles);

    const [isSaved, setSaved] = useState(false);

    const [isFileInputLoading, setFileInputLoading] = useState(false);
    const [fileInputProgress, setFileInputProgress] = useState(0);
    const [fileInputData, setFileInputData] = useState<
        (string & FileList) | undefined
    >();

    function handleFileInputChange(file: FileList) {
        setFileInputLoading(true);
        setFileInputProgress(100);
        setFileInputData(file as (string & FileList) | undefined);
    }

    function handleFileInputCancel() {
        setFileInputLoading(false);
        setFileInputProgress(0);
        setFileInputData(undefined);
    }

    const coverMutation = useMutation((fileInputData: FileList) =>
        uploadEventCover(fileInputData),
    );

    const [chosenLang, setChosenLang] = useState(lang);

    const toggleChosenLang = (lang: string) => {
        setChosenLang(lang);
    };

    const handleClose = () => {
        formik.resetForm();
        setSaved(false);
        onClose();
    };

    const createEventMutation = useMutation(
        (event: IEditEvent) => createEvent(event),
        {
            onSuccess: () => {
                handleClose();
                formik.resetForm();
                queryClient.invalidateQueries('events');
            },
        },
    );
    const eventMutation = useMutation(
        (event: IEditEvent) => updateEvent(event),
        {
            onSuccess: () => {
                setSaved(true);
                queryClient.invalidateQueries('events');
            },
        },
    );

    const formik: IFormik = useFormik({
        initialValues: {
            id: eventId!,
            slug: event?.slug,
            dateStart: event?.dateStart
                ? dayjs(event?.dateStart).utc(false).format('YYYY-MM-DDTHH:mm')
                : undefined,
            dateEnd: event?.dateEnd
                ? dayjs(event?.dateEnd).utc(false).format('YYYY-MM-DDTHH:mm')
                : undefined,
            formatId: event?.format?.id,
            topicId: event?.topic?.id,
            programmeId: event?.programme?.id,
            locationId: event?.location?.id,
            sponsorIds: event?.sponsors?.map((sponsor) => sponsor.id),
            persons: event?.persons.map((person) => ({
                id: person.person.id,
                roleId: person.role.id,
            })),
            actionLink: event?.actionLink,
            price: event?.price,
            customProgrammeImage: event?.customProgrammeImage,
            customBackground: event?.customBackground,
            translations: {
                ...languagesOfEvents.reduce(
                    (acc, currentLang) => ({
                        ...acc,
                        [currentLang]: {
                            title:
                                event &&
                                getTranslation(event, currentLang)?.title,
                            lead:
                                event &&
                                getTranslation(event, currentLang)?.lead,
                            description: event
                                ? converter.makeMarkdown(
                                      getTranslation(event, currentLang)
                                          ?.description || '',
                                  )
                                : '',
                            action:
                                event &&
                                getTranslation(event, currentLang)?.action,
                            eventLanguage:
                                event &&
                                getTranslation(event, currentLang)
                                    ?.eventLanguage,
                            isActive: Boolean(
                                event &&
                                    getTranslation(event, currentLang)
                                        ?.isActive,
                            ),
                            blocks:
                                (event &&
                                    getTranslation(
                                        event,
                                        currentLang,
                                    )?.blocks.map((_block) =>
                                        handleBlocksStructure(_block, 'init'),
                                    )) ||
                                [],
                            customProgrammeDescription:
                                event &&
                                getTranslation(event, currentLang)
                                    ?.customProgrammeDescription,
                        },
                    }),
                    {},
                ),
            },
        },

        enableReinitialize: true,
        validateOnMount: !eventId,
        validationSchema: Yup.object().shape({
            slug: Yup.string()
                .test('slug', 'Slug already exist', (value) =>
                    value
                        ? !slugs
                              ?.filter(
                                  (slug) => slug !== formik.initialValues.slug,
                              )
                              .includes(value)
                        : true,
                )
                .min(1)
                .max(255)
                .required(),
            dateStart: Yup.date().required('Date start is required field'),
            dateEnd: Yup.date().nullable(),
            formatId: Yup.number()
                .min(1)
                .integer()
                .required('Format is a required field'),
            topicId: Yup.number()
                .min(1)
                .integer()
                .required('Topic is a required field'),
            programmeId: Yup.number()
                .min(1)
                .integer()
                .required('Programme is a required field'),
            locationId: Yup.number()
                .min(1)
                .integer()
                .required('Location is a required field'),
            sponsorIds: Yup.array().of(Yup.number().integer()),
            persons: Yup.array(),
            actionLink: Yup.string().url().required(),
            price: Yup.number().integer(),
            customProgrammeImage: Yup.string().nullable(),
            customBackground: Yup.string().nullable(),
            translations: Yup.object().shape({
                ...languagesOfEvents.reduce(
                    (acc, currentLang) => ({
                        ...acc,
                        [currentLang]: Yup.object().shape({
                            title: Yup.string().when(
                                [
                                    'lead',
                                    'description',
                                    'action',
                                    'eventLanguage',
                                ],

                                {
                                    is: (
                                        lead,
                                        description,
                                        action,
                                        eventLanguage,
                                    ) =>
                                        lead ||
                                        description ||
                                        action ||
                                        eventLanguage !== undefined,
                                    then: Yup.string().required(
                                        'Title is required field',
                                    ),
                                },
                            ),
                            lead: Yup.string(),
                            description: Yup.string(),
                            action: Yup.string(),
                            eventLanguage: Yup.string(),
                            isActive: Yup.boolean(),
                            blocks: Yup.array(),
                            customProgrammeDescription: Yup.string(),
                        }),
                    }),
                    {},
                ),
            }),
        }),
        onSubmit: (data) => {
            const event: IEditEvent = {
                id: eventId!,
                slug: data.slug!,
                dateStart: data.dateStart!,
                dateEnd: data.dateEnd!,
                formatId: data.formatId!,
                topicId: data.topicId!,
                programmeId: data.programmeId!,
                locationId: data.locationId!,
                sponsorIds: data.sponsorIds! || [],
                persons: data.persons! || [],
                actionLink: data.actionLink!,
                price: data.price!,
                customProgrammeImage: data.customProgrammeImage!,
                customBackground: data.customBackground!,
                translations: [
                    ...languagesOfEvents
                        .map(
                            (_lang) =>
                                data.translations[_lang].title &&
                                data.translations[_lang].lead &&
                                data.translations[_lang].description &&
                                data.translations[_lang].action &&
                                data.translations[_lang].eventLanguage && {
                                    language: `${_lang}`,
                                    title: data.translations[_lang].title!,
                                    lead: data.translations[_lang].lead!,
                                    description: converter.makeHtml(
                                        data.translations[_lang].description!,
                                    ),
                                    action: data.translations[_lang].action!,
                                    eventLanguage: data.translations[_lang]
                                        .eventLanguage!,
                                    isActive: data.translations[_lang]
                                        .isActive!,
                                    blocks: data.translations[
                                        _lang
                                    ].blocks.map((_block) =>
                                        handleBlocksStructure(_block, 'submit'),
                                    ),
                                    customProgrammeDescription: data
                                        .translations[_lang]
                                        .customProgrammeDescription!,
                                },
                        )
                        .filter(Boolean),
                ],
            };

            if (fileInputData) {
                coverMutation.mutate(fileInputData, {
                    onSuccess: (value) => {
                        event.customProgrammeImage = value;
                        submitEvent(event);
                    },
                });
            } else {
                submitEvent(event);
            }
        },
    });
    const {
        isValid,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
    } = formik;

    useEffect(() => {
        formik.dirty && setSaved(false);
    }, [formik.dirty]);

    const submitEvent = (event: IEditEvent) => {
        if (event.id) {
            eventMutation.mutate(event);
        } else {
            createEventMutation.mutate(event);
        }
    };

    const handleAddBlock = (block, lang) => {
        const syntheticEvent: any = {
            target: { name: `translations.${lang}.blocks`, value: block },
        };

        if (
            values?.translations[lang].blocks.some(
                (__block) => __block.blockType === block,
            )
        ) {
            syntheticEvent.target.value = values?.translations[
                lang
            ].blocks.filter((_block) => _block.blockType !== block);
            handleChange(syntheticEvent);
        } else {
            syntheticEvent.target.value = [
                ...values?.translations[lang].blocks,
                { blockType: block, ...getBlockFields(block) },
            ];
            handleChange(syntheticEvent);
        }
    };

    const handleAddTimetable = (lang) => {
        const syntheticEvent: any = {
            target: {
                name: `translations.${lang}.blocks[${values?.translations[
                    lang
                ].blocks.findIndex(
                    (_block) => _block.blockType === 'timetable',
                )}].timetable`,
                value: [],
            },
        };

        syntheticEvent.target.value = [
            ...values?.translations[lang].blocks
                .find((_block) => _block.blockType === 'timetable')
                .timetable.map((_timetable) => _timetable),
            {
                dateStart: null,
                dateEnd: null,
                description: '',
            },
        ];

        handleChange(syntheticEvent);
    };
    const handleDeleteTimetable = (timetableIndex, lang) => {
        const syntheticEvent: any = {
            target: {
                name: `translations.${lang}.blocks[${values?.translations[
                    lang
                ].blocks.findIndex(
                    (_block) => _block.blockType === 'timetable',
                )}].timetable`,
                value: [
                    ...values?.translations[lang].blocks
                        .find((_block) => _block.blockType === 'timetable')
                        .timetable.filter(
                            (_, index) => index !== timetableIndex,
                        ),
                ],
            },
        };

        handleChange(syntheticEvent);
    };

    const handleChangeWithSlug = (event) => {
        setFieldValue('slug', slugify(event.target.value.toLowerCase()));
        handleChange(event);
    };

    const customBackgrounds = programmes?.items
        .filter(
            (programme) =>
                programme.id === values.programmeId &&
                programme.backgrounds.length > 0,
        )
        .flatMap((programme) =>
            programme.backgrounds.map((background) => background.imageUrl),
        );

    return (
        <Modal
            label={eventId ? `Событие ${eventId}` : 'Создать новый событие'}
            onClose={handleClose}
            isOpened={isOpened}
            backgroundColor="#EFF2F3"
        >
            <Form onSubmit={handleSubmit}>
                <LanguageDependentBlock
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleAddBlock={handleAddBlock}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleChangeWithSlug={handleChangeWithSlug}
                    handleAddTimetable={handleAddTimetable}
                    handleDeleteTimetable={handleDeleteTimetable}
                    lang={'ru'}
                />
                <LanguageDependentBlock
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleAddBlock={handleAddBlock}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleChangeWithSlug={handleChangeWithSlug}
                    handleAddTimetable={handleAddTimetable}
                    handleDeleteTimetable={handleDeleteTimetable}
                    lang={'en'}
                />
                <Div
                    backgroundColor="white"
                    borderRadius="2px"
                    boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                    gridColumn="1/3"
                    mt="20px"
                    pb="20px"
                >
                    <Div p="14px 20px" backgroundColor="#E7ECEE">
                        <Text color="#647282" variant="h4">
                            common fields
                        </Text>
                    </Div>
                    <Flex justifyContent="flex-end" p="20px 20px 0 0">
                        <LanguageToggle
                            chosenLang={chosenLang}
                            toggleChosenLang={toggleChosenLang}
                        />
                    </Flex>
                    <Div width="50%" px="20px">
                        <Input
                            label="Slug"
                            name="slug"
                            id="slug"
                            placeholder="Slug"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.slug}
                            error={!!errors.slug}
                        />
                        {errors.slug && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.slug}
                            </Text>
                        )}
                    </Div>
                    <Div width="50%" px="20px">
                        <Input
                            type="datetime-local"
                            label="Date start"
                            name="dateStart"
                            id="dateStart"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.dateStart && !!touched.dateStart}
                            value={
                                values?.dateStart
                                    ? dayjs(values?.dateStart).format(
                                          'YYYY-MM-DDTHH:mm',
                                      )
                                    : ''
                            }
                        />
                        {touched?.dateStart && errors?.dateStart && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.dateStart}
                            </Text>
                        )}
                    </Div>
                    <Div width="50%" px="20px">
                        <Input
                            type="datetime-local"
                            label="Date end"
                            name="dateEnd"
                            id="dateEnd"
                            onChange={handleChange}
                            value={
                                values?.dateEnd
                                    ? dayjs(values?.dateEnd).format(
                                          'YYYY-MM-DDTHH:mm',
                                      )
                                    : ''
                            }
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Select
                            id="formatId"
                            name="formatId"
                            label="Format"
                            placeholder="Select format"
                            value={values?.formatId}
                            valueTitle={
                                formats?.items?.find(
                                    (format) => values?.formatId === format.id,
                                )?.title
                            }
                            onChange={handleChange}
                        >
                            {formats?.items?.map((format) => (
                                <Select.Option
                                    key={format.id}
                                    value={format.id}
                                >
                                    {format.name}
                                </Select.Option>
                            ))}
                        </Select>
                        {errors?.formatId && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.formatId}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Select
                            id="topicId"
                            name="topicId"
                            label="Topic"
                            placeholder="Select topic"
                            value={values?.topicId}
                            valueTitle={
                                getTranslation(
                                    topics?.items.find(
                                        (topic) => values?.topicId === topic.id,
                                    ),
                                    chosenLang,
                                )?.title
                            }
                            onChange={handleChange}
                        >
                            {topics?.items.map((topic) => (
                                <Select.Option key={topic.id} value={topic.id}>
                                    {topic.translations.length
                                        ? getTranslation(topic, 'en')?.title
                                        : 'no data'}
                                </Select.Option>
                            ))}
                        </Select>
                        {errors?.topicId && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.topicId}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Select
                            id="programmeId"
                            name="programmeId"
                            label="Programme"
                            placeholder="Select programme"
                            value={values?.programmeId}
                            valueTitle={
                                programmes?.items.find(
                                    (programme) =>
                                        values?.programmeId === programme.id,
                                )?.title
                            }
                            onChange={handleChange}
                        >
                            {programmes?.items.map((programme) => (
                                <Select.Option
                                    key={programme.id}
                                    value={programme.id}
                                >
                                    {programme.title}
                                </Select.Option>
                            ))}
                        </Select>
                        {errors?.programmeId && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.programmeId}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Select
                            id="locationId"
                            name="locationId"
                            label="Location"
                            placeholder="Select location"
                            value={values?.locationId}
                            valueTitle={
                                locations?.items?.find(
                                    (location) =>
                                        values?.locationId === location.id,
                                )?.title
                            }
                            onChange={handleChange}
                        >
                            {locations?.items?.map((location) => (
                                <Select.Option
                                    key={location.id}
                                    value={location.id}
                                >
                                    {location.name}
                                </Select.Option>
                            ))}
                        </Select>
                        {errors?.locationId && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.locationId}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Select
                            id="customBackground"
                            name="customBackground"
                            label="Custom background"
                            placeholder="Select custom background"
                            value={values?.customBackground}
                            onChange={handleChange}
                        >
                            <Select.Option value={undefined}>
                                <Text variant="body">
                                    Стандартная обложка программы
                                </Text>
                            </Select.Option>
                            {customBackgrounds?.map((background, index) => (
                                <Select.Option key={index} value={background}>
                                    <Img maxWidth="100px" src={background} />
                                </Select.Option>
                            ))}
                        </Select>
                        {errors?.customBackground && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.customBackground}
                            </Text>
                        )}
                    </Div>
                    <Div px="20px" mb="20px">
                        <Text variant="body" mb="10px">
                            Sposnors
                        </Text>
                        <SponsorsInput
                            id="sponsorIds"
                            name="sponsorIds"
                            value={values?.sponsorIds}
                            suggestions={sponsors?.items || []}
                            placeholder="Select sponsors"
                            onChange={handleChange}
                        />
                    </Div>
                    <Div px="20px" mb="20px">
                        <Text variant="body" mb="10px">
                            Persons
                        </Text>
                        <AuthorsInput
                            id="persons"
                            name="persons"
                            value={values?.persons}
                            suggestions={persons?.items || []}
                            roles={roles || []}
                            placeholder="Select person"
                            onChange={handleChange}
                            lang={chosenLang}
                        />
                    </Div>
                    <Div width="50%" px="20px">
                        <Input
                            label="Price"
                            name="price"
                            id="price"
                            placeholder="Price"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.price}
                            error={!!errors.price}
                        />
                    </Div>
                    <Div width="50%" px="20px">
                        <Input
                            label="Youtube Video Id"
                            name="youtubeVideoId"
                            id="youtubeVideoId"
                            placeholder="Youtube Video Id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.youtubeVideoId || ''}
                            error={!!errors.youtubeVideoId}
                        />
                    </Div>
                    <Div width="50%" px="20px" mb="20px">
                        <Input
                            label="Action Link"
                            name="actionLink"
                            id="actionLink"
                            placeholder="Action Link"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.actionLink || ''}
                            error={!!errors.actionLink && !!touched.actionLink}
                        />
                        {touched.actionLink && errors.actionLink && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.actionLink}
                            </Text>
                        )}
                    </Div>
                    <Div width="50%" px="20px" mb="20px">
                        <FileInput
                            id="customProgrammeImage"
                            value={fileInputData}
                            title="Custom Programme Image"
                            progress={fileInputProgress}
                            isLoading={isFileInputLoading}
                            onChange={handleFileInputChange}
                            onCancel={handleFileInputCancel}
                        />
                    </Div>
                </Div>
                <Flex mt="40px">
                    <PrimaryButton
                        disabled={!isValid}
                        type="submit"
                        mr="20px"
                        isBig
                    >
                        {isSaved ? 'Saved' : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={handleClose} isBig>
                        Cancel
                    </DefaultButton>
                </Flex>
            </Form>
        </Modal>
    );
};

export default EditEventModal;

const LanguageDependentBlock = ({
    touched,
    errors,
    values,
    handleAddBlock,
    handleBlur,
    handleChange,
    handleChangeWithSlug,
    lang,
    handleAddTimetable,
    handleDeleteTimetable,
}) => {
    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
        'write',
    );

    return (
        <>
            <Div
                backgroundColor="white"
                boxShadow="0px 0px 6px rgba(0, 0, 0, 0.1)"
                borderRadius="2px"
                minWidth="500px"
            >
                <Div p="14px 20px" backgroundColor="#E7ECEE">
                    <Text color="#647282" variant="h4">
                        {lang === 'en' ? 'english' : 'русский'}
                    </Text>
                </Div>
                <Div px="20px">
                    <Input
                        label={lang === 'en' ? 'Title' : 'Заголовок'}
                        name={`translations.${lang}.title`}
                        id={`translations.${lang}.title`}
                        placeholder={lang === 'en' ? 'Title' : 'Заголовок'}
                        onChange={
                            lang === 'en' ? handleChange : handleChangeWithSlug
                        }
                        onBlur={handleBlur}
                        value={values.translations?.[lang]?.title || ''}
                        error={
                            !!errors?.translations?.[lang]?.title &&
                            !!touched?.translations?.[lang]?.title
                        }
                    />
                    {touched?.translations?.[lang]?.title &&
                        errors?.translations?.[lang]?.title && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.translations[lang].title}
                            </Text>
                        )}
                </Div>
                <Div px="20px">
                    <Text variant="body" mt="20px" mb="10px">
                        {lang === 'en' ? 'Lead' : 'Лид'}
                    </Text>
                    <ReactMde
                        value={values?.translations?.[lang]?.lead || ''}
                        onChange={handleChange(`translations.${lang}.lead`)}
                        toolbarCommands={[]}
                    />
                    {touched?.translations?.[lang]?.lead &&
                        errors?.translations?.[lang]?.lead && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.translations[lang].lead}
                            </Text>
                        )}
                </Div>
                <Div px="20px">
                    <Text variant="body" mt="20px" mb="10px">
                        {lang === 'en' ? 'Description' : 'Описание'}
                    </Text>
                    <ReactMde
                        value={values?.translations?.[lang]?.description || ''}
                        onChange={handleChange(
                            `translations.${lang}.description`,
                        )}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        toolbarCommands={[['bold', 'italic', 'link']]}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(converter.makeHtml(markdown))
                        }
                    />
                    {touched?.translations?.[lang]?.description &&
                        errors?.translations?.[lang]?.description && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.translations[lang].description}
                            </Text>
                        )}
                </Div>
                <Div px="20px">
                    <Input
                        label={lang === 'en' ? 'Button text' : 'Текст кнопки'}
                        name={`translations.${lang}.action`}
                        id={`translations.${lang}.action`}
                        placeholder={
                            lang === 'en' ? 'Button text' : 'Текст кнопки'
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.translations?.[lang]?.action || ''}
                        error={
                            !!errors?.translations?.[lang]?.action &&
                            !!touched?.translations?.[lang]?.action
                        }
                    />
                    {touched?.translations?.[lang]?.action &&
                        errors?.translations?.[lang]?.action && (
                            <Text variant="body" color="red" mt="10px">
                                {errors.translations[lang].action}
                            </Text>
                        )}
                </Div>
                <Div px="20px">
                    <Select
                        label={
                            lang === 'en' ? 'Event language' : 'Язык события'
                        }
                        placeholder={
                            lang === 'en' ? 'Select language' : 'Выберите язык'
                        }
                        name={`translations.${lang}.eventLanguage`}
                        id={`translations.${lang}.eventLanguage`}
                        value={
                            values?.translations?.[lang]?.eventLanguage || ''
                        }
                        onChange={handleChange}
                    >
                        {languagesList?.[lang]?.map((language, index) => (
                            <Select.Option key={index} value={language}>
                                {language}
                            </Select.Option>
                        ))}
                    </Select>
                    {errors.translations?.[lang]?.eventLanguage && (
                        <Text variant="body" color="red" mt="10px">
                            {errors.translations?.[lang]?.eventLanguage}
                        </Text>
                    )}
                </Div>
                <Div px="20px">
                    <Input
                        label={
                            lang === 'en'
                                ? 'Custom Programme Description'
                                : 'Специальное описание программы'
                        }
                        name={`translations.${lang}.customProgrammeDescription`}
                        id={`translations.${lang}.customProgrammeDescription`}
                        placeholder={
                            lang === 'en'
                                ? 'Custom Programme Description'
                                : 'Специальное описание программы'
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                            values?.translations?.[lang]
                                ?.customProgrammeDescription || ''
                        }
                        error={
                            !!errors?.translations?.[lang]
                                ?.customProgrammeDescription
                        }
                    />
                </Div>
                <Div my="20px" px="20px">
                    <CheckBox
                        label={lang === 'en' ? 'Is active? ' : 'Активно? '}
                        name={`translations.${lang}.isActive`}
                        id={`translations.${lang}.isActive`}
                        checked={values?.translations?.[lang]?.isActive}
                        onChange={handleChange}
                    />
                </Div>
                <Div mb="20px" px="20px">
                    <Text variant="body">Add block</Text>
                    <Flex mt="10px">
                        <DefaultButton
                            mr="10px"
                            p="9px 20px"
                            type="button"
                            onClick={() => handleAddBlock('about', lang)}
                        >
                            About
                        </DefaultButton>
                        <DefaultButton
                            mr="10px"
                            p="9px 20px"
                            type="button"
                            onClick={() => handleAddBlock('timetable', lang)}
                        >
                            Schedule
                        </DefaultButton>
                        <DefaultButton
                            onClick={() => handleAddBlock('conditions', lang)}
                            p="9px 20px"
                            type="button"
                        >
                            Conditions
                        </DefaultButton>
                    </Flex>
                </Div>
                <Div mb="20px">
                    {values?.translations?.[lang]?.blocks?.map(
                        (_block: any, index: number) => {
                            return (
                                <Block title={_block.blockType} key={index}>
                                    {_block.blockType === 'about' && (
                                        <>
                                            <Div>
                                                <Text
                                                    variant="body"
                                                    mt="20px"
                                                    mb="10px"
                                                >
                                                    {lang === 'en'
                                                        ? 'Results'
                                                        : 'Результат'}
                                                </Text>
                                                <ReactMde
                                                    value={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.results || ''
                                                    }
                                                    onChange={handleChange(
                                                        `translations.${lang}.blocks[${index}].results`,
                                                    )}
                                                    selectedTab={selectedTab}
                                                    onTabChange={setSelectedTab}
                                                    toolbarCommands={[
                                                        [
                                                            'bold',
                                                            'italic',
                                                            'link',
                                                        ],
                                                    ]}
                                                    generateMarkdownPreview={(
                                                        markdown,
                                                    ) =>
                                                        Promise.resolve(
                                                            converter.makeHtml(
                                                                markdown,
                                                            ),
                                                        )
                                                    }
                                                />
                                            </Div>
                                            <Div>
                                                <Text
                                                    variant="body"
                                                    mt="20px"
                                                    mb="10px"
                                                >
                                                    {lang === 'en'
                                                        ? 'for whom this workshop'
                                                        : 'для кого этот воркшоп'}
                                                </Text>
                                                <ReactMde
                                                    value={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.audience || ''
                                                    }
                                                    onChange={handleChange(
                                                        `translations.${lang}.blocks[${index}].audience`,
                                                    )}
                                                    selectedTab={selectedTab}
                                                    onTabChange={setSelectedTab}
                                                    toolbarCommands={[
                                                        [
                                                            'bold',
                                                            'italic',
                                                            'link',
                                                        ],
                                                    ]}
                                                    generateMarkdownPreview={(
                                                        markdown,
                                                    ) =>
                                                        Promise.resolve(
                                                            converter.makeHtml(
                                                                markdown,
                                                            ),
                                                        )
                                                    }
                                                />
                                            </Div>
                                            <Div>
                                                <Text
                                                    variant="body"
                                                    mt="20px"
                                                    mb="10px"
                                                >
                                                    {lang === 'en'
                                                        ? 'what you will learn'
                                                        : 'чему вы научитесь'}
                                                </Text>
                                                <ReactMde
                                                    value={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.whatYouWillLearn ||
                                                        ''
                                                    }
                                                    onChange={handleChange(
                                                        `translations.${lang}.blocks[${index}].whatYouWillLearn`,
                                                    )}
                                                    selectedTab={selectedTab}
                                                    onTabChange={setSelectedTab}
                                                    toolbarCommands={[
                                                        [
                                                            'bold',
                                                            'italic',
                                                            'link',
                                                        ],
                                                    ]}
                                                    generateMarkdownPreview={(
                                                        markdown,
                                                    ) =>
                                                        Promise.resolve(
                                                            converter.makeHtml(
                                                                markdown,
                                                            ),
                                                        )
                                                    }
                                                />
                                            </Div>
                                        </>
                                    )}
                                    {_block.blockType === 'conditions' && (
                                        <>
                                            <Input
                                                label="Age"
                                                name={`translations.${lang}.blocks[${index}].age`}
                                                id={`translations.${lang}.blocks[${index}].age`}
                                                placeholder="Age"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]?.age ||
                                                    ''
                                                }
                                                error={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]?.age ===
                                                    ''
                                                }
                                            />
                                            <Input
                                                label="Skills"
                                                name={`translations.${lang}.blocks[${index}].skills`}
                                                id={`translations.${lang}.blocks[${index}].skills`}
                                                placeholder="Skills"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]
                                                        ?.skills || ''
                                                }
                                                error={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]
                                                        ?.skills === ''
                                                }
                                            />
                                            <Input
                                                label="Deadline"
                                                type="datetime-local"
                                                name={`translations.${lang}.blocks[${index}].deadline`}
                                                id={`translations.${lang}.blocks[${index}].deadline`}
                                                placeholder="Deadline"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]
                                                        ?.deadline || ''
                                                }
                                                error={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]
                                                        ?.deadline === ''
                                                }
                                            />
                                            <Div>
                                                <Text
                                                    variant="body"
                                                    mt="20px"
                                                    mb="10px"
                                                >
                                                    {lang === 'en'
                                                        ? 'Description'
                                                        : 'Описание'}
                                                </Text>
                                                <ReactMde
                                                    value={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.description || ''
                                                    }
                                                    onChange={handleChange(
                                                        `translations.${lang}.blocks[${index}].description`,
                                                    )}
                                                    selectedTab={selectedTab}
                                                    onTabChange={setSelectedTab}
                                                    toolbarCommands={[
                                                        [
                                                            'bold',
                                                            'italic',
                                                            'link',
                                                        ],
                                                    ]}
                                                    generateMarkdownPreview={(
                                                        markdown,
                                                    ) =>
                                                        Promise.resolve(
                                                            converter.makeHtml(
                                                                markdown,
                                                            ),
                                                        )
                                                    }
                                                />
                                            </Div>
                                            {/* <Input
                                                label="Description"
                                                name={`translations.${lang}.blocks[${index}].description`}
                                                id={`translations.${lang}.blocks[${index}].description`}
                                                placeholder="Description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]
                                                        ?.description || ''
                                                }
                                                error={
                                                    values?.translations?.[lang]
                                                        ?.blocks[index]
                                                        ?.description === ''
                                                }
                                            /> */}
                                        </>
                                    )}
                                    {_block.blockType === 'timetable' &&
                                        _block.timetable.map((_, _index) => (
                                            <Fragment key={_index}>
                                                <Input
                                                    label="From"
                                                    type="datetime-local"
                                                    name={`translations.${lang}.blocks[${index}].timetable[${_index}].dateStart`}
                                                    id={`translations.${lang}.blocks[${index}].timetable[${_index}].dateStart`}
                                                    placeholder="From"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.timetable[_index]
                                                            ?.dateStart || ''
                                                    }
                                                    error={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.timetable[_index]
                                                            ?.dateStart === ''
                                                    }
                                                />
                                                <Input
                                                    label="To"
                                                    type="datetime-local"
                                                    name={`translations.${lang}.blocks[${index}].timetable[${_index}].dateEnd`}
                                                    id={`translations.${lang}.blocks[${index}].timetable[${_index}].dateEnd`}
                                                    placeholder="To"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.timetable[_index]
                                                            ?.dateEnd || ''
                                                    }
                                                    error={
                                                        values?.translations?.[
                                                            lang
                                                        ]?.blocks[index]
                                                            ?.timetable[_index]
                                                            ?.dateEnd === ''
                                                    }
                                                />
                                                <Div>
                                                    <Text
                                                        variant="body"
                                                        mt="20px"
                                                        mb="10px"
                                                    >
                                                        {lang === 'en'
                                                            ? 'Description'
                                                            : 'Описание'}
                                                    </Text>
                                                    <ReactMde
                                                        value={
                                                            values
                                                                ?.translations?.[
                                                                lang
                                                            ]?.blocks[index]
                                                                ?.timetable[
                                                                _index
                                                            ]?.description || ''
                                                        }
                                                        onChange={handleChange(
                                                            `translations.${lang}.blocks[${index}].timetable[${_index}].description`,
                                                        )}
                                                        selectedTab={
                                                            selectedTab
                                                        }
                                                        onTabChange={
                                                            setSelectedTab
                                                        }
                                                        toolbarCommands={[
                                                            [
                                                                'bold',
                                                                'italic',
                                                                'link',
                                                            ],
                                                        ]}
                                                        generateMarkdownPreview={(
                                                            markdown,
                                                        ) =>
                                                            Promise.resolve(
                                                                converter.makeHtml(
                                                                    markdown,
                                                                ),
                                                            )
                                                        }
                                                    />
                                                </Div>
                                                <Flex
                                                    mt="40px"
                                                    py="10px"
                                                    borderBottom="1px solid #DCDDE2"
                                                    justifyContent="space-between"
                                                >
                                                    <PlainButton
                                                        variant={'body'}
                                                        color="solidBlue"
                                                        cursor="pointer"
                                                        border="none"
                                                        background="transparent"
                                                        onClick={() => {
                                                            handleAddTimetable(
                                                                lang,
                                                            );
                                                        }}
                                                    >
                                                        {'Add'}
                                                    </PlainButton>
                                                    <PlainButton
                                                        variant={'body'}
                                                        color="red"
                                                        cursor="pointer"
                                                        border="none"
                                                        background="transparent"
                                                        onClick={() => {
                                                            handleDeleteTimetable(
                                                                _index,
                                                                lang,
                                                            );
                                                        }}
                                                    >
                                                        {'Delete'}
                                                    </PlainButton>
                                                </Flex>
                                            </Fragment>
                                        ))}
                                </Block>
                            );
                        },
                    )}
                </Div>
            </Div>
        </>
    );
};
